import { gql } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

import {
  RoomReservationFragment,
  RoomTypeReservationFragment,
} from './RoomLayout.fragments'

export default Object.freeze({
  CREATE_ROOM_RESERVATION: gql`
    ${accommodationFragments.accommodationGroup}

    mutation AccommodationRoomTypeReservationReserveRoomMutation(
      $input: AccommodationRoomTypeReservationReserveRoomInput!
    ) {
      accommodationRoomTypeReservationReserveRoom(input: $input) {
        ... on AccommodationRoomReservationPayloadOk {
          accommodation {
            accommodationGroups {
              ...AccommodationGroup
            }
            id
          }
        }
        ... on RoomReservationOverlappingError {
          message
        }
        ... on RoomReservationCapacityRestricted {
          message
        }
      }
    }
  `,
  DELETE_ROOM_RESERVATION: gql`
    ${accommodationFragments.accommodationGroup}

    mutation AccommodationRoomReservationDeleteMutation(
      $input: AccommodationRoomReservationDeleteInput!
    ) {
      accommodationRoomReservationDelete(input: $input) {
        id
        deleted
        accommodationGroup {
          ...AccommodationGroup
        }
      }
    }
  `,
  DELETE_ROOM_TYPE_RESERVATION: gql`
    ${accommodationFragments.accommodationGroup}

    mutation AccommodationRoomTypeReservationDeleteMutation(
      $input: AccommodationRoomTypeReservationDeleteInput!
    ) {
      accommodationRoomTypeReservationDelete(input: $input) {
        id
        deleted
        accommodationGroup {
          ...AccommodationGroup
        }
      }
    }
  `,
  SET_ROOM_RESERVATION_BED_QUANTITY: gql`
    ${RoomReservationFragment}

    mutation AccommodationRoomReservationSetBedQuantityMutation(
      $input: AccommodationRoomReservationSetBedQuantityInput!
    ) {
      accommodationRoomReservationSetBedQuantity(input: $input) {
        ... on AccommodationRoomReservationPayloadOk {
          roomReservation {
            ...RoomLayoutRoomReservation
          }
        }
      }
    }
  `,
  SET_ROOM_RESERVATION_DATES: gql`
    mutation AccommodationRoomReservationSetDatesMutation(
      $input: AccommodationRoomReservationSetDatesInput!
    ) {
      accommodationRoomReservationSetDates(input: $input) {
        ... on AccommodationRoomReservationPayloadOk {
          roomReservation {
            id
          }
        }
        ... on RoomReservationOverlappingError {
          message
        }
        ... on RoomReservationCapacityRestricted {
          message
        }
      }
    }
  `,
  SET_ROOM_RESERVATION_NEEDS: gql`
    ${RoomReservationFragment}

    mutation AccommodationRoomReservationSetNeedsMutation(
      $input: AccommodationRoomReservationSetNeedsInput!
    ) {
      accommodationRoomReservationSetNeeds(input: $input) {
        ... on AccommodationRoomReservationPayloadOk {
          roomReservation {
            ...RoomLayoutRoomReservation
          }
        }
      }
    }
  `,
  SET_ROOM_RESERVATION_ROOM: gql`
    mutation AccommodationRoomReservationSetRoomMutation(
      $input: AccommodationRoomReservationSetRoomInput!
    ) {
      accommodationRoomReservationSetRoom(input: $input) {
        ... on AccommodationRoomReservationPayloadOk {
          roomReservation {
            id
            request {
              beds
              checkIn {
                date
                type
              }
              checkOut {
                date
                type
              }
              info
              extraBeds
              features {
                icon
                id
                name
                shortName
              }
              info
              room {
                floor
                id
                internalInfo
                number
                beds
                extraBeds
                roomType {
                  id
                  accommodationLevel {
                    id
                    name
                    shortName
                  }
                  category {
                    id
                    name
                  }
                  name
                }
                status {
                  cleaningStatus
                }
              }
            }
          }
        }
        ... on RoomReservationOverlappingError {
          message
        }
      }
    }
  `,
  SET_ROOM_STATUS: gql`
    mutation RoomSetStatus($input: RoomSetStatusInput!) {
      roomSetStatus(input: $input) {
        id
      }
    }
  `,
  SET_ROOM_TYPE_RESERVATION_FULFILLED: gql`
    ${RoomTypeReservationFragment}

    mutation AccommodationRoomTypeReservationSetFulfilledMutation(
      $input: AccommodationRoomTypeReservationSetFulfilledByRoomsInput!
    ) {
      accommodationRoomTypeReservationSetFulfilledByRooms(input: $input) {
        roomTypeReservation {
          ...RoomLayoutRoomTypeReservation
        }
      }
    }
  `,
  SET_ROOM_TYPE_RESERVATION_NEEDS: gql`
    ${RoomTypeReservationFragment}

    mutation AccommodationRoomTypeReservationSetNeedsMutation(
      $input: AccommodationRoomTypeReservationSetNeedsInput!
    ) {
      accommodationRoomTypeReservationSetNeeds(input: $input) {
        roomTypeReservation {
          ...RoomLayoutRoomTypeReservation
        }
      }
    }
  `,
})
