import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableSelect } from '@/components/DataTable'
import { translate, useLanguageContext } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import type { ProductRule } from '@/modules/Products/types'

import { ConditionRuleType as CRT, QuantityUnit } from '~generated-types'

type Props = {
  quantityUnit: QuantityUnit
  rule: ProductRule
}

export const ConditionSelector = ({
  quantityUnit,
  rule: { condition, id },
}: Props) => {
  const { language } = useLanguageContext()
  const { readOnly, updateProductItemRule } = useCatalogProductManagerContext()

  const translationPath = 'Products:ProductManager.pricing.rule.condition'

  const RULES = [
    {
      label: translate(`${translationPath}.${CRT.AgeCategory}`, language),
      value: CRT.AgeCategory,
    },
    {
      label: translate(
        `${translationPath}.${CRT.Quantity}-${quantityUnit}`,
        language
      ),
      value: CRT.Quantity,
    },
    {
      label: translate(`${translationPath}.${CRT.Weekday}`, language),
      value: CRT.Weekday,
    },
    {
      label: translate(`${translationPath}.${CRT.Date}`, language),
      value: CRT.Date,
    },
    {
      label: translate(`${translationPath}.${CRT.Boundary}`, language),
      value: CRT.Boundary,
    },
  ]

  const handleUpdateRuleCondition = (ruleType: CRT) =>
    updateProductItemRule({ condition: { ruleType }, productRuleId: id })

  return (
    <Select
      disabled={readOnly}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        handleUpdateRuleCondition(e.target.value as CRT)
      }}
      value={condition.type}
    >
      {RULES.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

/////////

const Select = styled(DataTableSelect)`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      margin-right: ${theme.spacing.gu(1.5)}rem;
      padding: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(0.5)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
        opacity: 1;
      `}
    }
  }
`
