import { Response } from '../apiCall/types'
import { elasticApiSearch } from './elasticApiSearch'

export const elasticApiGet = <T>(
  index: string,
  documentType: string,
  documentId: string
): Promise<Response<T | null | undefined>> =>
  elasticApiSearch<T>(index, {
    query: {
      match: {
        id: documentId,
      },
    },
  }).then(({ data, ok, ...rest }) => ({
    ...rest,
    data: data[0],
    ok,
  }))

export default elasticApiGet
