import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  ServiceSetDatesMutation,
  ServiceSetDatesMutationVariables,
} from '~generated-types'

export type { ServiceSetDatesMutation, ServiceSetDatesMutationVariables }

export const SERVICE_SET_DATES = gql`
  ${participantFragments.participantService}

  mutation ServiceSetDates($input: ServiceSetDatesInput!) {
    serviceSetDates(input: $input) {
      service {
        ...ParticipantService
      }
    }
  }
`

export function useServiceSetDates() {
  return useMutation<ServiceSetDatesMutation, ServiceSetDatesMutationVariables>(
    SERVICE_SET_DATES
  )
}

export default useServiceSetDates
