import { gql } from '@apollo/client'

export default gql`
  fragment ReservationStatus on ReservationStatus {
    reservedCapacity {
      rooms
      beds
      extraBeds
    }
    consumptionBreakdown {
      type
      consumption
      floatingConsumption
    }
  }
`
