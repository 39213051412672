export const getContentColors = () => ({
  accommodation: '#2dbcff',
  attachments: '#4fad23',
  checks: '#3d983f',
  enrollments: '#dde325',
  guest_communication: '#BDAC35',
  meals: '#42ce00',
  orders: '#d8c100',
  overview: '#00a3ee',
  participants: '#68c2a5',
  products: '#7d8abe',
  publish: '#35dbbd',
  reservations: '#7f7fff',
  tasks: '#ff6969',
  vst: '#55e3e2',
})
