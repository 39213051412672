import { gql } from '@apollo/client'

import { productFragments } from '@/modules/Products/fragments'

export default gql`
  ${productFragments.productPurchase}

  fragment ServicePurchase on ServicePurchase {
    id
    purchaseProduct {
      ...ProductPurchase
    }
  }
`
