import { gql } from '@apollo/client'

export default gql`
  subscription ParticipantsRoomsSubscription(
    $filter: AccommodationReservationFilterInput!
  ) {
    accommodationReservation(filter: $filter) {
      salesId
      accommodationGroupId
      event {
        ... on RoomEvent {
          id
        }
      }
    }
  }
`
