import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FetchState, FetchStates } from '@/common/types'
import { useDialogService } from '@/components/DialogService'
import { DateRange } from '@/components/TimeControls'
import { useUpdateRoomMutation } from '@/modules/Accommodation/mutations'
import { T } from '@/modules/Language'
// Contexts
import { translate, useLanguageContext } from '@/modules/Language'
import {
  RoomFeature,
  RoomLayoutMutations,
  RoomLayoutQueries,
} from '@/modules/Reservations/components/RoomLayout'
import { generateCompareFn } from '@/utils/arrays'

import type {
  AccommodationGroupSettingsInput,
  AccommodationRoomReservationSetNeedsInput,
  AccommodationRoomReservationSetRoomInput,
  AccommodationRoomReservationSetRoomMutationMutation,
  AccommodationRoomReservationSetRoomMutationMutationVariables,
  AccommodationRoomTargetInput,
  AccommodationRoomTypeTargetInput,
  AccommodationTargetAddSalesMutation,
  AccommodationTargetAddSalesMutationVariables,
  AccommodationTargetCreateInput,
  AccommodationTargetDeleteInput,
  AccommodationTargetRemoveSalesMutation,
  AccommodationTargetRemoveSalesMutationVariables,
  AccommodationTargetSalesInput,
  AccommodationTargetUpdateInput,
  AccommodationTargetUpdateMutation,
  AccommodationTargetUpdateMutationVariables,
  AccommodationUpdateAllInput,
  CheckInType,
  CheckOutType,
  CreateRoomReservationMutation,
  CreateRoomTypeReservationMutation,
  RoomReservationDeleteMutation,
  RoomReservationSetBedQuantityMutation,
  RoomReservationSetDatesMutation,
  RoomReservationSetNeedsMutation,
  RoomTypeReservationDeleteMutation,
  RoomTypeReservationSetBedQuantityMutation,
  RoomTypeReservationSetDatesMutation,
  RoomTypeReservationSetNeedsMutation,
  RoomTypeReservationSetRoomQuantityMutation,
  SalesType,
} from '~generated-types'

import {
  useAccommodationDateOffset,
  useAccommodationTargetCreate,
  useAccommodationTargetDelete,
  useAccommodationTargetRoom,
  useAccommodationTargetRoomType,
} from '../SalesReservationList/mutations'
import {
  useRoomReservationDelete,
  useRoomReservationSetBedQuantity,
  useRoomReservationSetDates,
  useRoomReservationSetNeeds,
  useRoomTypeReservationDelete,
  useRoomTypeReservationSetBedQuantity,
  useRoomTypeReservationSetDates,
  useRoomTypeReservationSetNeeds,
  useRoomTypeReservationSetRoomQuantity,
} from '../SalesReservationManager/mutations'
// Mutations & queries
import {
  CREATE_ROOM_RESERVATION,
  CREATE_ROOM_TYPE_RESERVATION,
  REMOVE_GROUP,
  SET_GROUP_NAME,
} from '../SalesReservationManager/SalesReservationManager.mutations'
import { EventReservationMutations } from './ReservationList.mutations'
import { AccommodationQueries } from './ReservationList.queries'
import AccommodationSubscriptions from './ReservationList.subscriptions'
import {
  AddPendingRoomReservationInput,
  AddPendingRoomTypeReservationInput,
  PendingRoomReservation,
  PendingRoomTypeReservation,
  RoomTypeReservation,
  SalesAccommodationAvailability,
  SalesAccommodationAvailabilityVariables,
  SalesAccommodationGroup,
} from './SalesReservationList.types'

type ContextType = {
  // Data
  processing: boolean
  refreshTicker: number
  reservationGroups: SalesAccommodationGroup[]
  roomFeatures: RoomFeature[]
  salesId: string
  saleType: string
  state: string

  // Methods
  addPendingRoomReservation: (input: AddPendingRoomReservationInput) => void
  addPendingRoomTypeReservation: (
    input: AddPendingRoomTypeReservationInput
  ) => void
  addReservationGroup: (name: string | null | undefined) => Promise<void>
  changeGroupSettings: (input: {
    groupId: string
    consumptionType?:
      | AccommodationGroupSettingsInput['consumptionType']
      | string
    consumptionDurationType?:
      | AccommodationGroupSettingsInput['consumptionDurationType']
      | string
  }) => Promise<void>
  createReservation: (id: string, groupId: string) => Promise<any>
  handleAddTarget: (input: AccommodationTargetCreateInput) => Promise<void>
  handleMassUpdate: (input: AccommodationUpdateAllInput) => Promise<any>
  handleRemoveTarget: (input: AccommodationTargetDeleteInput) => Promise<void>
  handleSetRoomReservationRoom: (
    input: AccommodationRoomReservationSetRoomInput,
    roomNumber: string
  ) => Promise<void>
  handleTargetAddSales: (input: AccommodationTargetSalesInput) => Promise<any>
  handleTargetRemoveSales: (
    input: AccommodationTargetSalesInput
  ) => Promise<any>
  handleTargetRoom: (input: AccommodationRoomTargetInput) => Promise<void>
  handleTargetRoomType: (
    input: AccommodationRoomTypeTargetInput
  ) => Promise<void>
  handleUpdateDates: (input: {
    reservationId: string
    checkInType: CheckInType
    checkOutType: CheckOutType
    range: DateRange
  }) => Promise<any>
  handleUpdateRoom: (
    id: string,
    internalInfo: string
  ) => Promise<void | undefined>
  handleUpdateRoomQuantity: (input: {
    reservationId: string
    rooms: number
  }) => Promise<any>
  handleUpdateTarget: (input: AccommodationTargetUpdateInput) => Promise<any>
  removeGroup: (id: string) => Promise<void>
  removeReservation: (id: string) => void
  setFulfilledByRooms: (input: {
    reservationId: string
    fulfilledByRooms: boolean
  }) => Promise<void>
  setGroupName: (groupId: string, name: string) => Promise<void>
  setNeeds: (arg0: {
    reservationId: string
    featureIds: AccommodationRoomReservationSetNeedsInput['featureIds']
    note: AccommodationRoomReservationSetNeedsInput['info']
  }) => Promise<any>
  updateBedQuantity: (input: {
    reservationId: string
    beds: number
    extraBeds: number
  }) => Promise<any>
  updated: boolean
}

const ReservationListContext = createContext<ContextType>({
  addPendingRoomReservation: () => undefined,
  addPendingRoomTypeReservation: () => undefined,
  addReservationGroup: () => Promise.reject(),
  changeGroupSettings: () => Promise.reject(),
  createReservation: () => Promise.reject(),
  handleAddTarget: () => Promise.reject(),
  handleMassUpdate: () => Promise.reject(),
  handleRemoveTarget: () => Promise.reject(),
  handleSetRoomReservationRoom: () => Promise.reject(),
  handleTargetAddSales: () => Promise.reject(),
  handleTargetRemoveSales: () => Promise.reject(),
  handleTargetRoom: () => Promise.reject(),
  handleTargetRoomType: () => Promise.reject(),
  handleUpdateDates: () => Promise.reject(),
  handleUpdateRoom: () => Promise.reject(),
  handleUpdateRoomQuantity: () => Promise.reject(),
  handleUpdateTarget: () => Promise.reject(),
  processing: false,
  refreshTicker: 0,
  removeGroup: () => Promise.reject(),
  removeReservation: () => undefined,
  reservationGroups: [],
  roomFeatures: [],
  salesId: '',
  saleType: '',
  setFulfilledByRooms: () => Promise.reject(),
  setGroupName: () => Promise.reject(),
  setNeeds: () => Promise.reject(),
  state: '',
  updateBedQuantity: () => Promise.reject(),
  updated: false,
})

type Props = {
  children: ReactNode
  salesId: string
  eventId: string | null
}

export const ReservationListContextProvider = ({
  children,
  salesId,
  eventId,
}: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { confirm, warn } = useDialogService()
  const { language } = useLanguageContext()

  const [onSetRoomBedQuantity] = useRoomReservationSetBedQuantity()
  const [onSetRoomTypeBedQuantity] = useRoomTypeReservationSetBedQuantity()
  const [setRoomQuantity] = useRoomTypeReservationSetRoomQuantity()

  const [onSetRoomNeeds] = useRoomReservationSetNeeds()
  const [onSetRoomTypeNeeds] = useRoomTypeReservationSetNeeds()

  const [setRoomDates] = useRoomReservationSetDates()
  const [setRoomTypeDates] = useRoomTypeReservationSetDates()
  const [massUpdate] = useAccommodationDateOffset()

  const [addTarget] = useAccommodationTargetCreate()
  const [removeTarget] = useAccommodationTargetDelete()

  const [removeRoomReservation] = useRoomReservationDelete()
  const [removeRoomTypeReservation] = useRoomTypeReservationDelete()

  const [targetRoom] = useAccommodationTargetRoom()
  const [targetRoomType] = useAccommodationTargetRoomType()

  const [updateRoom] = useUpdateRoomMutation()

  const [onAddGroup] = useMutation(
    EventReservationMutations.ADD_ACCOMMODATION_GROUP
  )
  const [onSetGroupSettigs] = useMutation(
    EventReservationMutations.SET_ACCOMMODATION_GROUP_SETTINGS
  )
  const [setFulfilled] = useMutation(
    RoomLayoutMutations.SET_ROOM_TYPE_RESERVATION_FULFILLED
  )
  const [setRoomReservationRoom] = useMutation<
    AccommodationRoomReservationSetRoomMutationMutation,
    AccommodationRoomReservationSetRoomMutationMutationVariables
  >(RoomLayoutMutations.SET_ROOM_RESERVATION_ROOM)
  const [createRoomReservation, createRoomReservationOptions] = useMutation(
    CREATE_ROOM_RESERVATION
  )
  const [createRoomTypeReservation, createRoomTypeReservationOptions] =
    useMutation(CREATE_ROOM_TYPE_RESERVATION)
  const [onSetGroupName] = useMutation(SET_GROUP_NAME)
  const [onRemoveGroup] = useMutation(REMOVE_GROUP)
  const [updateTarget] = useMutation<
    AccommodationTargetUpdateMutation,
    AccommodationTargetUpdateMutationVariables
  >(EventReservationMutations.UPDATE_ACCOMMODATION_TARGET)
  const [targetAddSales] = useMutation<
    AccommodationTargetAddSalesMutation,
    AccommodationTargetAddSalesMutationVariables
  >(EventReservationMutations.ACCOMMODATION_TARGET_ADD_SALES)
  const [targetRemoveSales] = useMutation<
    AccommodationTargetRemoveSalesMutation,
    AccommodationTargetRemoveSalesMutationVariables
  >(EventReservationMutations.ACCOMMODATION_TARGET_REMOVE_SALES)

  const [reservationGroups, setReservationGroups] = useState<
    SalesAccommodationGroup[]
  >([])
  const [roomFeatures, setRoomFeatures] = useState<RoomFeature[]>([])
  const [saleType, setSaleType] = useState<SalesType | string>('')
  const [state, setState] = useState<FetchState>(FetchStates.LOADING)
  const [refreshTicker, setRefreshTicker] = useState<number>(0)
  const [updated, setUpdated] = useState<boolean>(false)
  const [updatedInCurrentSession, setUpdatedInCurrentSession] =
    useState<boolean>(false)

  const processing =
    createRoomReservationOptions.loading ||
    createRoomTypeReservationOptions.loading

  useQuery<
    SalesAccommodationAvailability,
    SalesAccommodationAvailabilityVariables
  >(AccommodationQueries.SALE_RESERVATIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ sales }) => {
      setSaleType(sales.type)
      setState(
        sales.accommodation.accommodationGroups.length
          ? FetchStates.IDLE
          : FetchStates.EMPTY
      )

      setReservationGroups(
        [...sales.accommodation.accommodationGroups].sort(
          generateCompareFn('sortOrder')
        )
      )
    },
    onError: () => setState(FetchStates.ERROR),
    skip: state === FetchStates.IDLE || state === FetchStates.EMPTY,
    variables: { id: salesId },
  })

  const features = useQuery(RoomLayoutQueries.ROOM_FEATURES)

  useSubscription(AccommodationSubscriptions.ACCOMMODATION, {
    onData({ data: { data } }) {
      !updatedInCurrentSession && setUpdated(true)
      setTimeout(() => setUpdated(false), 500)

      if (!data) {
        return
      }

      const event = data.accommodationReservation.event
      const groupId = data.accommodationReservation.accommodationGroupId

      !updatedInCurrentSession &&
        setReservationGroups((current) =>
          event.__typename === 'AccommodationGroupEvent'
            ? event.type === 'CREATE'
              ? current
              : event.type === 'UPDATE'
                ? current.map((group) =>
                    group.id === groupId
                      ? {
                          ...group,
                          ...event.accommodationGroupData,
                        }
                      : group
                  )
                : event.type === 'DELETE'
                  ? current.filter(({ id }) => id !== groupId)
                  : current
            : event.__typename === 'RoomEvent'
              ? event.type === 'CREATE'
                ? current.map((group) =>
                    group.id === groupId
                      ? {
                          ...group,
                          roomReservations: [
                            ...group.roomReservations.filter(
                              (room) => room.id !== event.roomData.id
                            ),
                            event.roomData,
                          ],
                        }
                      : group
                  )
                : event.type === 'UPDATE'
                  ? current.map((group) =>
                      group.id === groupId
                        ? {
                            ...group,
                            roomReservations: group.roomReservations.map(
                              (roomReservation) =>
                                roomReservation.id === event.id
                                  ? event.roomData
                                  : roomReservation
                            ),
                          }
                        : group
                    )
                  : event.type === 'DELETE'
                    ? current.map((group) =>
                        group.id === groupId
                          ? {
                              ...group,
                              roomReservations: group.roomReservations.filter(
                                ({ id }) => id !== event.id
                              ),
                            }
                          : group
                      )
                    : current
              : event.__typename === 'RoomTypeEvent'
                ? event.type === 'CREATE'
                  ? current.map((group) =>
                      group.id === groupId
                        ? {
                            ...group,
                            roomTypeReservations: [
                              ...group.roomTypeReservations.filter(
                                (roomType) =>
                                  roomType.id !== event.roomTypeData.id
                              ),
                              event.roomTypeData,
                            ],
                          }
                        : group
                    )
                  : event.type === 'UPDATE'
                    ? current.map((group) =>
                        group.id === groupId
                          ? {
                              ...group,
                              roomTypeReservations:
                                group.roomTypeReservations.map(
                                  (roomTypeReservations) =>
                                    roomTypeReservations.id === event.id
                                      ? event.roomTypeData
                                      : roomTypeReservations
                                ),
                            }
                          : group
                      )
                    : event.type === 'DELETE'
                      ? current.map((group) =>
                          group.id === groupId
                            ? {
                                ...group,
                                roomTypeReservations:
                                  group.roomTypeReservations.filter(
                                    ({ id }) => id !== event.id
                                  ),
                              }
                            : group
                        )
                      : current
                : event.__typename === 'TargetEvent'
                  ? event.type === 'CREATE'
                    ? current.map((group) =>
                        group.id === groupId
                          ? {
                              ...group,
                              targets: [
                                ...group.targets.filter(
                                  (target) => target.id !== event.targetData.id
                                ),
                                event.targetData,
                              ],
                            }
                          : group
                      )
                    : event.type === 'UPDATE'
                      ? current.map((group) =>
                          group.id === groupId
                            ? {
                                ...group,
                                targets: [
                                  ...group.targets.filter(
                                    (target) =>
                                      target.id !== event.targetData.id
                                  ),
                                  event.targetData,
                                ],
                              }
                            : group
                        )
                      : event.type === 'DELETE'
                        ? current.map((group) =>
                            group.id === groupId
                              ? {
                                  ...group,
                                  targets: [
                                    ...group.targets.filter(
                                      (target) => target.id !== event.id
                                    ),
                                  ],
                                }
                              : group
                          )
                        : current
                  : current
        )

      setTimeout(() => setUpdatedInCurrentSession(false), 100)
    },
    variables: {
      filter: {
        salesId: eventId || salesId,
      },
    },
  })

  useEffect(() => {
    setRoomFeatures(
      features.data &&
        features.data.registry.roomFeatures.length &&
        !features.error
        ? features.data.registry.roomFeatures
        : []
    )
  }, [features])

  const refreshAvailabilities = () => setRefreshTicker(refreshTicker + 1)

  const getReservationDataById = (reservationId: string) =>
    reservationGroups
      .map(
        (group) =>
          group.roomTypeReservations.find(
            (reservation) => reservation.id === reservationId
          ) ||
          group.roomReservations.find(
            (reservation) => reservation.id === reservationId
          )
      )
      .reduce((a, b) => (b !== undefined ? b : a)) || null

  const addReservationGroup = (name: string | null | undefined) => {
    setUpdatedInCurrentSession(true)

    return onAddGroup({
      variables: {
        input: {
          name: name || '',
          salesId,
        },
      },
    })
      .then((response) => {
        const createdGroup =
          response.data?.accommodationGroupCreate.accommodationGroup
        createdGroup &&
          setReservationGroups((current) => [...current, createdGroup])
      })
      .catch(() => undefined)
  }

  const changeGroupSettings = ({
    groupId,
    consumptionType,
    consumptionDurationType,
  }: {
    groupId: string
    consumptionType?:
      | AccommodationGroupSettingsInput['consumptionType']
      | string
    consumptionDurationType?:
      | AccommodationGroupSettingsInput['consumptionDurationType']
      | string
  }) => {
    setUpdatedInCurrentSession(true)

    return onSetGroupSettigs({
      variables: {
        input: {
          id: groupId,
          settings: {
            consumptionDurationType,
            consumptionType,
          },
        },
      },
    })
      .then(
        ({ data }) =>
          data &&
          setReservationGroups((current) =>
            current.map((group) =>
              group.id === groupId
                ? {
                    ...group,
                    settings:
                      data?.accommodationGroupSetSettings.accommodationGroup
                        .settings,
                    status:
                      data?.accommodationGroupSetSettings.accommodationGroup
                        .status,
                  }
                : group
            )
          )
      )
      .catch(() => undefined)
  }

  const setNeeds = ({
    reservationId,
    featureIds,
    note,
  }: {
    reservationId: string
    featureIds: AccommodationRoomReservationSetNeedsInput['featureIds']
    note: AccommodationRoomReservationSetNeedsInput['info']
  }) => {
    setUpdatedInCurrentSession(true)

    const reservation = getReservationDataById(reservationId)

    if (!reservation) {
      return Promise.reject()
    }

    const mutationInput = {
      input: {
        featureIds,
        id: reservationId,
        info: note,
      },
    }

    const updateState = (
      res:
        | RoomReservationSetNeedsMutation['accommodationRoomReservationSetNeeds']
        | RoomTypeReservationSetNeedsMutation['accommodationRoomTypeReservationSetNeeds']
        | null
    ) =>
      res &&
      (res.__typename === 'AccommodationRoomReservationPayloadOk' ||
        res.__typename === 'AccommodationRoomTypeReservationPayload') &&
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomReservations:
            res.__typename === 'AccommodationRoomReservationPayloadOk'
              ? group.roomReservations.map((r) =>
                  r.id === reservationId ? res.roomReservation : r
                )
              : group.roomReservations,
          roomTypeReservations:
            res.__typename === 'AccommodationRoomTypeReservationPayload'
              ? group.roomTypeReservations.map((r) =>
                  r.id === reservationId ? res.roomTypeReservation : r
                )
              : group.roomTypeReservations,
          settings:
            res.accommodationGroup.id === group.id
              ? res.accommodationGroup.settings || group.settings || null
              : group.settings,
          status:
            res.accommodationGroup.id === group.id
              ? res.accommodationGroup.status || group.status || null
              : group.status,
        }))
      )

    return reservation.__typename === 'RoomReservation'
      ? onSetRoomNeeds({
          variables: {
            ...mutationInput,
          },
        })
          .then((res) =>
            updateState(res.data?.accommodationRoomReservationSetNeeds || null)
          )
          .catch(() => undefined)
      : reservation.__typename === 'RoomTypeReservation'
        ? onSetRoomTypeNeeds({
            variables: {
              ...mutationInput,
            },
          })
            .then((res) =>
              updateState(
                res.data?.accommodationRoomTypeReservationSetNeeds || null
              )
            )
            .catch(() => undefined)
        : Promise.reject(new Error('unsupported reservation type')).catch(
            (err) => console.warn(err)
          )
  }

  const updateBedQuantity = ({
    reservationId,
    beds,
    extraBeds,
  }: {
    reservationId: string
    beds: number
    extraBeds: number
  }) => {
    const reservation = getReservationDataById(reservationId)

    if (!reservation) {
      return Promise.reject()
    }

    if (
      !(
        reservation.__typename === 'RoomReservation' ||
        reservation.__typename === 'RoomTypeReservation'
      )
    ) {
      return Promise.reject()
    }

    const mutationInput = {
      input: {
        beds: beds ?? reservation.request.beds,
        extraBeds: extraBeds ?? reservation.request.extraBeds,
        id: reservationId,
      },
    }

    const updateState = (
      res:
        | RoomReservationSetBedQuantityMutation['accommodationRoomReservationSetBedQuantity']
        | RoomTypeReservationSetBedQuantityMutation['accommodationRoomTypeReservationSetBedQuantity']
        | null
    ) =>
      res &&
      (res.__typename === 'AccommodationRoomReservationPayloadOk' ||
        res.__typename === 'AccommodationRoomTypeReservationPayload') &&
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomReservations:
            res.__typename === 'AccommodationRoomReservationPayloadOk'
              ? group.roomReservations.map((r) =>
                  r.id === reservationId ? res.roomReservation : r
                )
              : group.roomReservations,
          roomTypeReservations:
            res.__typename === 'AccommodationRoomTypeReservationPayload'
              ? group.roomTypeReservations.map((r) =>
                  r.id === reservationId ? res.roomTypeReservation : r
                )
              : group.roomTypeReservations,
          settings:
            res.accommodationGroup.id === group.id
              ? res.accommodationGroup.id === group.id
                ? res.accommodationGroup.settings || group.settings || null
                : group.settings
              : group.settings,
          status:
            res.accommodationGroup.id === group.id
              ? res.accommodationGroup.id === group.id
                ? res.accommodationGroup.status || group.status || null
                : group.status
              : group.status,
        }))
      )

    return reservation.__typename === 'RoomReservation'
      ? onSetRoomBedQuantity({
          variables: {
            ...mutationInput,
          },
        })
          .then((res) =>
            updateState(
              res.data?.accommodationRoomReservationSetBedQuantity || null
            )
          )
          .catch(() => undefined)
      : reservation.__typename === 'RoomTypeReservation'
        ? onSetRoomTypeBedQuantity({
            variables: {
              ...mutationInput,
            },
          })
            .then((res) =>
              updateState(
                res.data?.accommodationRoomTypeReservationSetBedQuantity || null
              )
            )
            .catch(() => undefined)
        : Promise.reject(new Error('unsupported reservation type')).catch(
            (err) => console.warn(err)
          )
  }

  const setFulfilledByRooms = ({
    reservationId,
    fulfilledByRooms,
  }: {
    reservationId: string
    fulfilledByRooms: boolean
  }) => {
    const mutationInput = {
      input: {
        fulfilledByRooms,
        id: reservationId,
      },
    }

    const updateState = () =>
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomTypeReservations: group.roomTypeReservations.map((r) =>
            r.id === reservationId ? { ...r, fulfilledByRooms } : r
          ),
        }))
      )

    return setFulfilled({
      variables: {
        ...mutationInput,
      },
    })
      .then(() => updateState())
      .catch(() => undefined)
  }

  const handleMassUpdate = (input: AccommodationUpdateAllInput) => {
    return massUpdate({ variables: { input } }).then((res) => {
      const data = res.data?.accommodationUpdateAll

      if (data?.__typename === 'AccommodationUpdateAllError') {
        return { issues: data?.issues }
      }

      setReservationGroups((current) =>
        !data
          ? current
          : current.map((group) => ({
              ...group,
              roomReservations: group.roomReservations.map(
                (r) => data.roomReservations.find(({ id }) => r.id === id) || r
              ),
              roomTypeReservations: group.roomTypeReservations.map(
                (r) =>
                  data.roomTypeReservations.find(({ id }) => r.id === id) || r
              ),
            }))
      )
    })
  }

  const handleUpdateDates = ({
    reservationId,
    checkInType,
    checkOutType,
    range,
  }: {
    reservationId: string
    checkInType: CheckInType
    checkOutType: CheckOutType
    range: DateRange
  }) => {
    const reservation = getReservationDataById(reservationId)

    if (!reservation) {
      return Promise.reject()
    }

    const mutationInput =
      reservation.__typename === 'RoomReservation' ||
      reservation.__typename === 'RoomTypeReservation'
        ? {
            checkIn: {
              date: range.from.format('YYYY-MM-DD'),
              type: checkInType,
            },
            checkOut: {
              date: range.to.format('YYYY-MM-DD'),
              type: checkOutType,
            },
            id: reservationId,
          }
        : null

    const updateState = (
      res:
        | RoomReservationSetDatesMutation['accommodationRoomReservationSetDates']
        | RoomTypeReservationSetDatesMutation['accommodationRoomTypeReservationSetDates']
        | null
    ) =>
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomReservations: group.roomReservations.map((r) =>
            r.id === reservationId
              ? res
                ? res.__typename === 'AccommodationRoomReservationPayloadOk'
                  ? res.roomReservation
                  : r
                : r.__typename === 'PendingRoomReservation'
                  ? {
                      ...r,
                      checkInType,
                      checkOutType,
                      range,
                    }
                  : r
              : r
          ),
          roomTypeReservations: group.roomTypeReservations.map((r) =>
            r.id === reservationId
              ? res
                ? res.__typename === 'AccommodationRoomTypeReservationPayload'
                  ? res.roomTypeReservation
                  : r
                : r.__typename === 'PendingRoomTypeReservation'
                  ? {
                      ...r,
                      checkInType,
                      checkOutType,
                      range,
                    }
                  : r
              : r
          ),
          settings:
            (res?.__typename === 'AccommodationRoomReservationPayloadOk' ||
              res?.__typename === 'AccommodationRoomTypeReservationPayload') &&
            res?.accommodationGroup.id === group.id
              ? res?.accommodationGroup.settings
              : group.settings,
          status:
            (res?.__typename === 'AccommodationRoomReservationPayloadOk' ||
              res?.__typename === 'AccommodationRoomTypeReservationPayload') &&
            res?.accommodationGroup.id === group.id
              ? res?.accommodationGroup.status
              : group.status,
        }))
      )

    return reservation.__typename === 'PendingRoomReservation' ||
      reservation.__typename === 'PendingRoomTypeReservation'
      ? new Promise(() => updateState(null)).catch(() => undefined)
      : mutationInput && reservation.__typename === 'RoomReservation'
        ? setRoomDates({
            variables: {
              input: {
                allowOverlapping: false,
                ...mutationInput,
              },
            },
          })
            .then((res) => {
              const isOverlappingError =
                res.data?.accommodationRoomReservationSetDates.__typename ===
                'RoomReservationOverlappingError'

              const isCapacityRestricted =
                res.data?.accommodationRoomReservationSetDates.__typename ===
                'RoomReservationCapacityRestricted'

              if (!isOverlappingError && !isCapacityRestricted) {
                refreshAvailabilities()
              }

              return isOverlappingError
                ? confirm({
                    cancelLabel: <T>common:action.cancel</T>,
                    confirmLabel: <T>common:action.continue</T>,
                    description: translate(
                      'RoomLayout:calendar.overlapping.description',
                      language,
                      {
                        roomNumber: `#${reservation.request.room.number}`,
                      }
                    ),
                    title: <T>RoomLayout:calendar.overlapping.title</T>,
                  })
                    .then(() =>
                      setRoomDates({
                        variables: {
                          input: {
                            allowOverlapping: true,
                            ...mutationInput,
                          },
                        },
                      })
                    )
                    .then((res) => {
                      refreshAvailabilities()
                      updateState(
                        res.data?.accommodationRoomReservationSetDates || null
                      )
                    })
                    .catch(() => updateState(null))
                : isCapacityRestricted
                  ? warn({
                      cancelLabel: <T>common:action.cancel</T>,
                      description: (
                        <T>RoomLayout:calendar.restricted.description</T>
                      ),
                      title: <T>RoomLayout:calendar.restricted.title</T>,
                    }).catch(() => updateState(null))
                  : updateState(
                      res.data?.accommodationRoomReservationSetDates || null
                    )
            })
            .catch(() => undefined)
        : mutationInput && reservation.__typename === 'RoomTypeReservation'
          ? setRoomTypeDates({ variables: { input: { ...mutationInput } } })
              .then((res) => {
                refreshAvailabilities()
                updateState(
                  res.data?.accommodationRoomTypeReservationSetDates || null
                )
              })
              .catch(() => undefined)
          : Promise.reject(new Error('unsupported reservation type')).catch(
              (err) => console.warn(err)
            )
  }

  const handleUpdateRoomQuantity = ({
    reservationId,
    rooms,
  }: {
    reservationId: string
    rooms: number
  }) => {
    const reservation = getReservationDataById(reservationId)

    if (!reservation) {
      return Promise.reject()
    }

    const reservationState = (r: RoomTypeReservation) =>
      r.__typename === 'RoomTypeReservation'
        ? { ...r, request: { ...r.request, rooms } }
        : r.__typename === 'PendingRoomTypeReservation'
          ? { ...r, rooms }
          : r

    const updateState = (
      res:
        | RoomTypeReservationSetRoomQuantityMutation['accommodationRoomTypeReservationSetRoomQuantity']
        | null
    ) =>
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomTypeReservations: group.roomTypeReservations.map((r) =>
            r.id === reservationId ? reservationState(r) : r
          ),
          settings:
            res?.accommodationGroup.id === group.id
              ? res?.accommodationGroup.settings || group.settings || null
              : group.settings,
          status:
            res?.accommodationGroup.id === group.id
              ? res?.accommodationGroup.status || group.status || null
              : group.status,
        }))
      )

    return reservation.__typename === 'RoomTypeReservation'
      ? setRoomQuantity({
          variables: {
            input: {
              id: reservationId,
              rooms,
            },
          },
        })
          .then((res) => {
            refreshAvailabilities()
            updateState(
              res.data?.accommodationRoomTypeReservationSetRoomQuantity || null
            )
          })
          .catch(() => undefined)
      : reservation.__typename === 'PendingRoomTypeReservation'
        ? new Promise(() => updateState(null))
        : Promise.reject(new Error('unsupported reservation type')).catch(
            (err) => console.warn(err)
          )
  }

  const addPendingRoomReservation = ({
    defaultRange,
    groupId,
    roomId,
    roomNumber,
    roomTypeName,
    target,
  }: AddPendingRoomReservationInput) => {
    setReservationGroups((current: SalesAccommodationGroup[]) =>
      current.map((group) =>
        group && group.id === groupId
          ? {
              ...group,
              roomReservations: group.roomReservations && [
                ...group.roomReservations,
                {
                  __typename: 'PendingRoomReservation',
                  checkInType: CheckInTypes.STANDARD,
                  checkOutType: CheckOutTypes.STANDARD,
                  id: uuidv4(),
                  range: defaultRange || null,
                  roomId,
                  roomNumber,
                  roomTypeName,
                  salesId,
                  target,
                } as PendingRoomReservation,
              ],
            }
          : group
      )
    )
  }

  const addPendingRoomTypeReservation = ({
    roomTypeId,
    roomTypeName,
    defaultRange,
    groupId,
    target,
  }: AddPendingRoomTypeReservationInput) => {
    setReservationGroups((current: SalesAccommodationGroup[]) =>
      current.map((group) =>
        group && group.id === groupId
          ? {
              ...group,
              roomTypeReservations: group.roomTypeReservations && [
                ...group.roomTypeReservations,
                {
                  __typename: 'PendingRoomTypeReservation',
                  checkInType: CheckInTypes.STANDARD,
                  checkOutType: CheckOutTypes.STANDARD,
                  id: uuidv4(),
                  range: defaultRange || null,
                  rooms: 1,
                  roomTypeId,
                  roomTypeName,
                  salesId,
                  target,
                } as PendingRoomTypeReservation,
              ],
            }
          : group
      )
    )
  }

  const removeReservation = (id: string) => {
    const reservation = getReservationDataById(id)

    if (!reservation) {
      return Promise.reject()
    }

    const mutationInput = {
      input: { id },
    }

    const updateState = (
      res:
        | RoomReservationDeleteMutation['accommodationRoomReservationDelete']
        | RoomTypeReservationDeleteMutation['accommodationRoomTypeReservationDelete']
        | null
    ) =>
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomReservations: group.roomReservations.filter((r) => r.id !== id),
          roomTypeReservations: group.roomTypeReservations.filter(
            (r) => r.id !== id
          ),
          settings:
            res?.accommodationGroup?.id === group.id
              ? res?.accommodationGroup.settings || group.settings || null
              : group.settings,
          status:
            res?.accommodationGroup?.id === group.id
              ? res?.accommodationGroup.status || group.status || null
              : group.status,
        }))
      )

    reservation.__typename === 'PendingRoomReservation' ||
    reservation.__typename === 'PendingRoomTypeReservation'
      ? updateState(null)
      : reservation.__typename === 'RoomReservation'
        ? removeRoomReservation({ variables: { ...mutationInput } })
            .then((res) => {
              refreshAvailabilities()
              updateState(res.data?.accommodationRoomReservationDelete || null)
            })
            .catch(() => undefined)
        : reservation.__typename === 'RoomTypeReservation'
          ? removeRoomTypeReservation({ variables: { ...mutationInput } })
              .then((res) => {
                refreshAvailabilities()
                updateState(
                  res.data?.accommodationRoomTypeReservationDelete || null
                )
              })
              .catch(() => undefined)
          : Promise.reject(new Error('unsupported reservation type')).catch(
              (err) => console.warn(err)
            )
  }

  const createReservation = (id: string, groupId: string) => {
    const reservation = getReservationDataById(id)

    if (!reservation) {
      return Promise.reject()
    }

    if (
      !(
        reservation.__typename === 'PendingRoomReservation' ||
        reservation.__typename === 'PendingRoomTypeReservation'
      )
    ) {
      return Promise.reject()
    }

    const commonData = {
      accommodationId: groupId,
      checkIn: {
        date: reservation.range?.from.format('YYYY-MM-DD'),
        type: reservation.checkInType,
      },
      checkOut: {
        date: reservation.range?.to.format('YYYY-MM-DD'),
        type: reservation.checkOutType,
      },
      targetId: reservation.target?.id || null,
    }

    const typeData =
      reservation.__typename === 'PendingRoomReservation'
        ? { roomId: reservation.roomId }
        : {
            rooms: reservation.rooms,
            roomTypeId: reservation.roomTypeId,
          }

    const mutationInput = {
      ...commonData,
      ...typeData,
    }

    const updateState = (
      res:
        | CreateRoomReservationMutation['accommodationGroupCreateRoomReservation']
        | CreateRoomTypeReservationMutation['accommodationGroupCreateRoomTypeReservation']
        | null
    ) =>
      res &&
      (res.__typename === 'AccommodationRoomReservationPayloadOk' ||
        res.__typename === 'AccommodationRoomTypeReservationPayload') &&
      setReservationGroups((current) =>
        current.map((group) => ({
          ...group,
          roomReservations:
            res.__typename === 'AccommodationRoomReservationPayloadOk'
              ? group.roomReservations
                  .filter((r) => r.id !== res.roomReservation?.id)
                  .map((r) => (r.id === id ? res.roomReservation : r))
              : group.roomReservations,
          roomTypeReservations:
            res.__typename === 'AccommodationRoomTypeReservationPayload'
              ? group.roomTypeReservations
                  .filter((r) => r?.id !== res.roomTypeReservation.id)
                  .map((r) => (r.id === id ? res.roomTypeReservation : r))
              : group.roomTypeReservations,
          settings:
            res.accommodationGroup.id === group.id
              ? res.accommodationGroup.settings || group.settings || null
              : group.settings,
          status:
            res.accommodationGroup.id === group.id
              ? res.accommodationGroup.status || group.status || null
              : group.status,
        }))
      )

    return reservation.__typename === 'PendingRoomReservation'
      ? createRoomReservation({
          variables: {
            input: {
              allowOverlapping: false,
              ...mutationInput,
            },
          },
        })
          .then((res) => {
            const isOverlappingError =
              res.data?.accommodationGroupCreateRoomReservation.__typename ===
              'RoomReservationOverlappingError'

            const isCapacityRestricted =
              res.data?.accommodationGroupCreateRoomReservation.__typename ===
              'RoomReservationCapacityRestricted'

            if (!isOverlappingError && !isCapacityRestricted) {
              refreshAvailabilities()
            }

            return isOverlappingError
              ? confirm({
                  cancelLabel: <T>common:action.cancel</T>,
                  confirmLabel: <T>common:action.continue</T>,
                  description: translate(
                    'RoomLayout:calendar.overlapping.description',
                    language,
                    {
                      roomNumber: `#${reservation.roomNumber}`,
                    }
                  ),
                  title: <T>RoomLayout:calendar.overlapping.title</T>,
                })
                  .then(() =>
                    createRoomReservation({
                      variables: {
                        input: { allowOverlapping: true, ...mutationInput },
                      },
                    })
                  )
                  .then((res) => {
                    refreshAvailabilities()
                    updateState(
                      res.data?.accommodationGroupCreateRoomReservation
                    )
                  })
                  .catch(() => undefined)
              : isCapacityRestricted
                ? warn({
                    cancelLabel: <T>common:action.cancel</T>,
                    description: (
                      <T>RoomLayout:calendar.restricted.description</T>
                    ),
                    title: <T>RoomLayout:calendar.restricted.title</T>,
                  }).catch(() => updateState(null))
                : updateState(res.data?.accommodationGroupCreateRoomReservation)
          })
          .catch(() => undefined)
      : reservation.__typename === 'PendingRoomTypeReservation'
        ? createRoomTypeReservation({
            variables: { input: { ...mutationInput } },
          })
            .then((res) => {
              refreshAvailabilities()
              updateState(res.data?.accommodationGroupCreateRoomTypeReservation)
            })
            .catch(() => undefined)
        : Promise.reject(new Error('unsupported reservation type')).catch(
            (err) => console.warn(err)
          )
  }

  const setGroupName = (groupId: string, name: string) => {
    setUpdatedInCurrentSession(true)

    return onSetGroupName({
      variables: {
        input: {
          id: groupId,
          name,
        },
      },
    })
      .then(() =>
        setReservationGroups((current) =>
          current.map((group) =>
            group && group.id === groupId
              ? {
                  ...group,
                  name,
                }
              : group
          )
        )
      )
      .catch(() => undefined)
  }

  const removeGroup = (id: string) => {
    setUpdatedInCurrentSession(true)

    const onRemove = () =>
      onRemoveGroup({
        variables: {
          input: {
            id,
          },
        },
      })
        .then(() =>
          setReservationGroups((current) =>
            current.filter((group) => group?.id && group.id !== id)
          )
        )
        .catch(() => undefined)

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.remove</T>,
      description: <T>Accommodation:SalesReservationManager.confirmRemoval</T>,
    })
      .then(() => onRemove && onRemove())
      .catch(() => undefined)
  }

  const handleAddTarget = (input: AccommodationTargetCreateInput) => {
    setUpdatedInCurrentSession(true)

    return addTarget({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        data &&
          setReservationGroups((current) =>
            current.map((group) =>
              group.id === input.accommodationGroupId
                ? {
                    ...group,
                    targets: [
                      ...group?.targets,
                      data.accommodationTargetCreate.target,
                    ],
                  }
                : group
            )
          )
      })
      .catch(() => undefined)
  }

  const handleRemoveTarget = ({
    id,
    deleteReservations,
  }: AccommodationTargetDeleteInput) => {
    setUpdatedInCurrentSession(true)

    const group = reservationGroups.find((group) =>
      group.targets.find((target) => target.id === id)
    )

    const defaultGroupTarget =
      !!group && group.targets.find((target) => target.default)

    return removeTarget({
      variables: {
        input: {
          deleteReservations,
          id,
        },
      },
    })
      .then(({ data }) => {
        data &&
          defaultGroupTarget &&
          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              roomReservations: !deleteReservations
                ? group.roomReservations.map((r) =>
                    r.target?.id === id
                      ? { ...r, target: defaultGroupTarget }
                      : r
                  )
                : group.roomReservations.filter((r) => r.target?.id !== id),
              roomTypeReservations: !deleteReservations
                ? group.roomTypeReservations.map((r) =>
                    r.target?.id === id
                      ? { ...r, target: defaultGroupTarget }
                      : r
                  )
                : group.roomTypeReservations.filter((r) => r.target?.id !== id),
              targets: group.targets.filter(
                ({ id }) => id !== data.accommodationTargetDelete.id
              ),
            }))
          )
      })
      .catch(() => undefined)
  }

  const handleUpdateTarget = (input: AccommodationTargetUpdateInput) =>
    updateTarget({ variables: { input } })
      .then(
        ({ data }) =>
          data &&
          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              targets: group.targets.map((target) =>
                target.id === data.accommodationTargetUpdate.target.id
                  ? data.accommodationTargetUpdate.target
                  : target
              ),
            }))
          )
      )
      .catch(() => undefined)

  const handleTargetRoom = (input: AccommodationRoomTargetInput) =>
    targetRoom({ variables: { input } })
      .then(({ data }) => {
        data &&
          setReservationGroups((current) =>
            current.map((group) => {
              const payloadRoomType =
                data.accommodationRoomTarget.roomReservation.roomTypeReservation

              if (!payloadRoomType) {
                return group
              }

              return {
                ...group,
                roomReservations: group.roomReservations.map((r) =>
                  r.id === input.roomReservationId
                    ? data.accommodationRoomTarget.roomReservation
                    : r
                ),
                roomTypeReservations: [
                  ...group.roomTypeReservations.filter(
                    ({ id }) => id !== payloadRoomType?.id
                  ),
                  payloadRoomType,
                ].filter(Boolean),
                targets: group.targets.map((t) =>
                  t.id === input.targetId
                    ? data.accommodationRoomTarget.accommodationTarget
                    : t
                ),
              }
            })
          )
      })
      .catch(() => undefined)

  const handleTargetRoomType = (input: AccommodationRoomTypeTargetInput) =>
    targetRoomType({ variables: { input } })
      .then(({ data }) => {
        data &&
          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              roomTypeReservations: [
                ...group.roomTypeReservations.filter(
                  (roomTypeReservation) =>
                    roomTypeReservation.id !==
                    data.accommodationRoomTypeTarget.roomTypeReservation.id
                ),
                data.accommodationRoomTypeTarget.roomTypeReservation,
              ],
            }))
          )
      })
      .catch(() => undefined)

  const handleTargetAddSales = (input: AccommodationTargetSalesInput) => {
    setUpdatedInCurrentSession(true)

    return targetAddSales({
      variables: {
        input,
      },
    })
      .then(
        ({ data }) =>
          data &&
          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              targets: group.targets.map((target) =>
                target.id === input.id
                  ? data.accommodationTargetAddSales.target
                  : target
              ),
            }))
          )
      )
      .catch(() => undefined)
  }

  const handleTargetRemoveSales = (input: AccommodationTargetSalesInput) => {
    setUpdatedInCurrentSession(true)

    return targetRemoveSales({
      variables: {
        input,
      },
    })
      .then(
        ({ data }) =>
          data &&
          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              targets: group.targets.map((target) =>
                target.id === input.id
                  ? data.accommodationTargetRemoveSales.target
                  : target
              ),
            }))
          )
      )
      .catch(() => undefined)
  }

  const handleSetRoomReservationRoom = (
    input: AccommodationRoomReservationSetRoomInput,
    roomNumber: string
  ) =>
    setRoomReservationRoom({
      variables: {
        input: {
          allowOverlapping: false,
          ...input,
        },
      },
    })
      .then(({ data }) => {
        const payload = data?.accommodationRoomReservationSetRoom

        const updateState = (
          payload:
            | AccommodationRoomReservationSetRoomMutationMutation['accommodationRoomReservationSetRoom']
            | null
        ) => {
          if (payload?.__typename !== 'AccommodationRoomReservationPayloadOk') {
            return
          }

          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              roomReservations: group.roomReservations
                .filter((r) =>
                  r.__typename === 'PendingRoomReservation'
                    ? r.roomId !== input.roomId
                    : true
                )
                .map((r) =>
                  r.id === input.id
                    ? { ...r, request: payload.roomReservation.request }
                    : r
                ),
            }))
          )
        }

        const isOverlappingError =
          payload?.__typename === 'RoomReservationOverlappingError'

        const isCapacityRestricted =
          payload?.__typename === 'RoomReservationCapacityRestricted'

        if (!isOverlappingError && !isCapacityRestricted) {
          refreshAvailabilities()
        }

        return isOverlappingError
          ? confirm({
              cancelLabel: <T>common:action.cancel</T>,
              confirmLabel: <T>common:action.continue</T>,
              description: translate(
                'RoomLayout:calendar.overlapping.description',
                language,
                { roomNumber: `#${roomNumber}` }
              ),
              title: <T>RoomLayout:calendar.overlapping.title</T>,
            })
              .then(() =>
                setRoomReservationRoom({
                  variables: {
                    input: {
                      allowOverlapping: true,
                      ...input,
                    },
                  },
                })
              )
              .then((res) => {
                refreshAvailabilities()
                updateState(
                  res.data?.accommodationRoomReservationSetRoom || null
                )
              })
              .catch(() => undefined)
          : isCapacityRestricted
            ? warn({
                cancelLabel: <T>common:action.cancel</T>,
                description: <T>RoomLayout:calendar.restricted.description</T>,
                title: <T>RoomLayout:calendar.restricted.title</T>,
              }).catch(() => undefined)
            : updateState(payload || null)
      })
      .catch(() => undefined)

  const handleUpdateRoom = (id: string, internalInfo: string) =>
    updateRoom({ variables: { input: { id, internalInfo } } })
      .then(({ data }) => {
        data &&
          setReservationGroups((current) =>
            current.map((group) => ({
              ...group,
              roomReservations: group.roomReservations.map((r) =>
                r.__typename === 'RoomReservation' && r.request.room.id === id
                  ? {
                      ...r,
                      request: {
                        ...r.request,
                        room: {
                          ...r.request.room,
                          internalInfo: data.roomUpdate.internalInfo,
                        },
                      },
                    }
                  : r
              ),
            }))
          )
      })
      .catch(() => undefined)

  contextValueRef.current = {
    addPendingRoomReservation,
    addPendingRoomTypeReservation,
    addReservationGroup,
    changeGroupSettings,
    createReservation,
    handleAddTarget,
    handleMassUpdate,
    handleRemoveTarget,
    handleSetRoomReservationRoom,
    handleTargetAddSales,
    handleTargetRemoveSales,
    handleTargetRoom,
    handleTargetRoomType,
    handleUpdateDates,
    handleUpdateRoom,
    handleUpdateRoomQuantity,
    handleUpdateTarget,
    processing,
    refreshTicker,
    removeGroup,
    removeReservation,
    reservationGroups,
    roomFeatures,
    salesId,
    saleType,
    setFulfilledByRooms,
    setGroupName,
    setNeeds,
    state,
    updateBedQuantity,
    updated,
  }

  return (
    <ReservationListContext.Provider value={contextValueRef.current}>
      {children}
    </ReservationListContext.Provider>
  )
}

export const useReservationListContext = () =>
  useContext(ReservationListContext)
