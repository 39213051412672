import { createContext, ReactNode, useContext, useRef } from 'react'
import { ApolloError, useQuery } from '@apollo/client'

import { SalesDetailsPayload, salesQueries } from '@/modules/Sales/queries'

import {
  CommunicationTemplatesQuery as QueryData,
  CommunicationTemplatesQueryVariables as QueryVariables,
  SalesGuestCommunicationSettingsInput,
  SetGuestCommunicationSettingsMutation,
} from '~generated-types'

import { useSetGuestCommunicationMutation } from './mutations'
import { communicationsQueries } from './queries'
import { CommunicationTemplates } from './types'

type ContextType = {
  communicationTemplates: CommunicationTemplates | undefined
  error: ApolloError | undefined
  loading: boolean
  refetch: () => void
  setGuestCommunication: (
    input: SalesGuestCommunicationSettingsInput
  ) => Promise<SetGuestCommunicationSettingsMutation | null | undefined>
}

const GuestCommunicationContext = createContext<ContextType>({
  communicationTemplates: [],
  error: undefined,
  loading: false,
  refetch: () => undefined,
  setGuestCommunication: () => Promise.reject(),
})

export const GuestCommunicationContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    communicationsQueries.CommunicationTemplates,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const communicationTemplates = data?.registry.communicationTemplates

  const [setGuestCommunicationMutation] = useSetGuestCommunicationMutation()

  const setGuestCommunication = (input: SalesGuestCommunicationSettingsInput) =>
    setGuestCommunicationMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedData = cache.readQuery<SalesDetailsPayload>({
          query: salesQueries.SALES_DETAILS,
          variables: { id: input.salesId },
        })

        if (cachedData && data) {
          cache.writeQuery<SalesDetailsPayload>({
            data: {
              ...cachedData,
              sales: {
                ...cachedData.sales,
                communicationSettings: data.salesSetGuestCommunicationSettings,
              },
            },
            query: salesQueries.SALES_DETAILS,
          })
        }
      },
      variables: { input },
    })
      .then(({ data }) => data)
      .catch(() => undefined)

  contextValueRef.current = {
    communicationTemplates,
    error,
    loading,
    refetch,
    setGuestCommunication,
  }

  return (
    <GuestCommunicationContext.Provider value={contextValueRef.current}>
      {children}
    </GuestCommunicationContext.Provider>
  )
}

export const useGuestCommunicationContext = () =>
  useContext(GuestCommunicationContext)
