import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { ScrollTableProps } from './types'

type Props = ScrollTableProps & {
  children: ReactNode
  isExpanded: boolean
  isFirstGroup: boolean
  onToggleExpand: () => void
  onToggleExpandRoomType: () => void
  onToggleExpandRoom: () => void
  showEndHeader?: boolean
}

export const PrimaryHeaderRow = ({
  children,
  columnHeaderWidth,
  isExpanded,
  isFirstGroup,
  onToggleExpand,
  onToggleExpandRoomType,
  onToggleExpandRoom,
  rowHeight,
}: Props) => (
  <HeaderWrapper isFirstGroup={isFirstGroup} rowHeight={rowHeight}>
    <Header>
      <FlexRow
        justifyContent="space-between"
        flex={1}
        style={{ width: columnHeaderWidth }}
      >
        <FlexRow justifyContent="flex-start">
          <ExpandButton
            isFirstGroup={isFirstGroup}
            noNudge
            onClick={() => onToggleExpand()}
            rowHeight={rowHeight}
          >
            <ExpandIcon icon={isExpanded ? 'angle-down' : 'angle-right'} />
          </ExpandButton>

          <LabelWrapper>{children}</LabelWrapper>
        </FlexRow>

        <FlexRow>
          <VerticalDivider />

          <ToggleButton
            isFirstGroup={isFirstGroup}
            noNudge
            onClick={() => onToggleExpandRoomType()}
            rowHeight={rowHeight}
          >
            <PrimaryColor>
              <T>Accommodation:AvailabilityCalendar.roomTypes</T>
            </PrimaryColor>
          </ToggleButton>

          <VerticalDivider />

          <ToggleButton
            isFirstGroup={isFirstGroup}
            noNudge
            onClick={() => onToggleExpandRoom()}
            rowHeight={rowHeight}
          >
            <PrimaryColor>
              <T>Accommodation:AvailabilityCalendar.rooms</T>
            </PrimaryColor>
          </ToggleButton>

          <VerticalDivider />
        </FlexRow>
      </FlexRow>
    </Header>
  </HeaderWrapper>
)

////////////

const ExpandButton = styled(InnocuousButton)<{
  isFirstGroup: boolean
  rowHeight: string
}>`
  border-radius: 0;
  justify-content: center;
  margin-right: 0;
  max-height: ${({ isFirstGroup, rowHeight }) =>
    `calc(${rowHeight} - ${isFirstGroup ? '1px' : '2px'})`};
  width: 41px;
`

const ExpandIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const Header = styled.div`
  position: sticky;
  left: 0px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const HeaderWrapper = styled.section<{
  isFirstGroup: boolean
  rowHeight: string
}>`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    background: ${theme.palette.smoke.lighter};
  `}

  height: ${({ rowHeight }) => rowHeight};
  display: inline-flex;
  align-items: center;
  z-index: 3002;

  ${({ isFirstGroup, theme }) =>
    !isFirstGroup && `border-top: 1px solid ${theme.palette.smoke.dark};`}
`

const LabelWrapper = styled.span`
  margin-left: 10px;
`

const ToggleButton = styled(ExpandButton)`
  font-weight: 500;
  text-transform: capitalize;
  width: fit-content;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const VerticalDivider = styled.div`
  display: flex;
  align-self: stretch;
  width: 0px;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`
