import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { EditButton } from '@/modules/Products/components/common'
import type { ProductCategory } from '@/modules/Products/types'
import { categoryHooks } from '@/modules/Registry/Category'
import { useTheme } from '@/theme'

import { CategorySetTree } from './components'

type Props = {
  categories: ProductCategory[]
  id: string
}

export const CategorySelector = ({ categories, id }: Props) => {
  const { palette } = useTheme()

  const { readOnly, updateProduct } = useCatalogProductManagerContext()
  const { categorySets } = categoryHooks.useCategorySetsByTarget({
    targetKey: 'PRODUCT_LIST',
  })

  const selectedCategories = categories.filter(
    ({ set }) => !!categorySets.find(({ id }) => id === set.id)
  )

  const [isHover, setHover] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>(
    selectedCategories.map(({ id }) => id)
  )

  const handleUpdateCategories = () =>
    updateProduct({ categoryIds: selectedCategoryIds, id }).then(() =>
      setModalOpen(false)
    )

  const handleToggleCategory = (categoryId: string) =>
    selectedCategoryIds.includes(categoryId)
      ? setSelectedCategoryIds(
          selectedCategoryIds.filter((id) => id !== categoryId)
        )
      : setSelectedCategoryIds([...selectedCategoryIds, categoryId])

  return (
    <Wrapper flex={1}>
      <Title>
        <T>Products:ProductManager.pricing.category</T>
      </Title>

      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal>
            <Section>
              {categorySets.map((data) => (
                <CategorySetTree
                  data={data}
                  expand={
                    categorySets.length === 1 ||
                    !!categories.find(({ set }) => set.id === data.id)
                  }
                  key={`category-set-${data.id}`}
                  selection={selectedCategoryIds}
                  toggleCategory={handleToggleCategory}
                />
              ))}
            </Section>
          </InlineModal>
        }
        onClose={handleUpdateCategories}
        placement="bottom-start"
        referenceElement={({ ref }) => (
          <EditButton
            disabled={!categorySets.length || readOnly}
            onClick={() => setModalOpen(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            ref={ref as Ref<HTMLButtonElement>}
          >
            {selectedCategories.length ? (
              <FlexRow>
                <CategoryLabel>{selectedCategories[0].name}</CategoryLabel>

                {selectedCategories.length > 1 && (
                  <span>
                    + {selectedCategories.length - 1} <T>common:helpers.more</T>
                  </span>
                )}
              </FlexRow>
            ) : (
              '—'
            )}

            {isHover && (
              <FontAwesomeIcon
                color={palette.text.lighter}
                icon="pen"
                size="sm"
              />
            )}
          </EditButton>
        )}
        zIndex={10007}
      />
    </Wrapper>
  )
}

/////////

const CategoryLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(0.5)}rem;
    max-width: ${theme.spacing.gu(30)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(24)}rem;
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`
