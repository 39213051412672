import communicationSettings from './communicationSettings'
import eventEnrollment from './eventEnrollment'
import eventSales from './eventSales'
import salesAssignee from './salesAssignee'
import salesFacetFeature from './salesFacetFeature'
import salesLifecycle from './salesLifecycle'
import salesVst from './salesVst'

export const salesFragments = Object.freeze({
  communicationSettings,
  eventEnrollment,
  eventSales,
  salesAssignee,
  salesFacetFeature,
  salesLifecycle,
  salesVst,
})
