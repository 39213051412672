import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'dot-prop'
import moment from 'moment'
import ReactLoading from 'react-loading'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { DataField } from '@/components/DataField'
import { useDialogService } from '@/components/DialogService'
import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { AccommodationQueries } from '@/modules/Accommodation/SalesReservationList/ReservationList.queries'
import {
  SalesAccommodationAvailability,
  SalesAccommodationGroup,
} from '@/modules/Accommodation/SalesReservationList/SalesReservationList.types'
import { T } from '@/modules/Language'
import {
  AccommodationGroup,
  RoomFeature,
  RoomReservation as GQLRoomReservation,
  RoomTypeReservation as GQLRoomTypeReservation,
  Sale,
  Target,
} from '@/modules/Reservations/components/RoomLayout'
import {
  RoomLayoutMutations,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'

import {
  Button,
  ContentWrapper,
  Dates,
  HorizontalDivider,
  Label,
  ModalContentWrapper,
  NoteWrapper,
  TotalNumber,
  TotalNumberBold,
  TrashIconWrapper,
  VerticalDivider,
  Wrapper,
} from './common'
import { NestedRoomReservation } from './NestedRoomReservation'
import { RoomFeatures } from './RoomFeatures'

type Props = {
  groupId: string
  reservation: GQLRoomTypeReservation
  saleId: string
  targetId: string
}

export const RoomTypeReservation = ({
  groupId,
  reservation,
  saleId,
  targetId,
}: Props) => {
  const theme = useTheme()

  const {
    fulfilledByRooms,
    id,
    request: {
      beds,
      checkIn,
      checkOut,
      extraBeds,
      features,
      info,
      roomType,
      rooms,
    },
    roomReservations,
  } = reservation

  const { confirm } = useDialogService()
  const {
    onSetCleaningMode,
    onSetSelectedRoomType,
    onSetSales,
    refreshCalendar,
    sales,
    selectedRoomType,
    targetSalesId,
  } = useRoomLayoutContext()

  const [isHover, setHover] = useState<boolean>(false)

  const [deleteRoomTypeReservation] = useMutation(
    RoomLayoutMutations.DELETE_ROOM_TYPE_RESERVATION,
    {
      // Manually update GraphQL cache after removing
      update(cache, { data }) {
        if (targetSalesId === saleId) {
          const salesCache: SalesAccommodationAvailability | null =
            cache.readQuery({
              query: AccommodationQueries.SALE_RESERVATIONS,
              variables: { id: saleId },
            })
          if (salesCache?.sales) {
            const newSales = {
              ...salesCache,
              sales: {
                ...salesCache.sales,
                accommodation: {
                  ...salesCache.sales.accommodation,
                  accommodationGroups:
                    salesCache.sales.accommodation.accommodationGroups.map(
                      (group: SalesAccommodationGroup) =>
                        group.id ===
                        data?.accommodationRoomTypeReservationDelete
                          .accommodationGroup.id
                          ? data?.accommodationRoomTypeReservationDelete
                              .accommodationGroup
                          : group
                    ),
                },
              },
            }

            cache.writeQuery({
              data: { ...newSales },
              query: AccommodationQueries.SALE_RESERVATIONS,
            })
          }
        }
      },
    }
  )
  const [setFulfilled, { loading }] = useMutation(
    RoomLayoutMutations.SET_ROOM_TYPE_RESERVATION_FULFILLED
  )
  const [setNote] = useMutation(
    RoomLayoutMutations.SET_ROOM_TYPE_RESERVATION_NEEDS
  )

  const handleDeleteRoomTypeReservation = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.remove</T>,
      description: (
        <>
          <T>
            RoomLayout:sidebar.confirmRoomTypeReservationRemoval.description
          </T>
          <ModalContentWrapper>{renderContent()}</ModalContentWrapper>
        </>
      ),
    })
      .then(() =>
        deleteRoomTypeReservation({
          variables: {
            input: {
              id,
            },
          },
        })
      )
      .then(() => {
        refreshCalendar()
        onSetSales(
          sales.map((sale: Sale) =>
            sale.id === saleId
              ? {
                  ...sale,
                  accommodationGroups: sale.accommodationGroups.map(
                    (group: AccommodationGroup) =>
                      group.id === groupId
                        ? {
                            ...group,
                            targets: group.targets.map((target: Target) =>
                              target.id === targetId
                                ? {
                                    ...target,
                                    gqlRoomTypeReservations:
                                      target.gqlRoomTypeReservations?.filter(
                                        (
                                          roomTypeReservation: GQLRoomTypeReservation
                                        ) => roomTypeReservation.id !== id
                                      ),
                                  }
                                : target
                            ),
                          }
                        : group
                  ),
                }
              : sale
          )
        )
      })
      .catch(() => undefined)

  const handleSetFulfilledByRooms = () => {
    setFulfilled({
      variables: {
        input: {
          fulfilledByRooms: !fulfilledByRooms,
          id,
        },
      },
    })
      .then((res) => {
        const updatedRoomReservation =
          res.data?.accommodationRoomTypeReservationSetFulfilledByRooms
            .roomTypeReservation

        onSetSales(
          sales.map((sale: Sale) =>
            sale.id === saleId
              ? {
                  ...sale,
                  accommodationGroups: sale.accommodationGroups.map(
                    (group: AccommodationGroup) =>
                      group.id === groupId
                        ? {
                            ...group,
                            targets: group.targets.map((target: Target) =>
                              target.id === targetId
                                ? {
                                    ...target,
                                    gqlRoomTypeReservations:
                                      target.gqlRoomTypeReservations?.map(
                                        (
                                          reservation: GQLRoomTypeReservation
                                        ) =>
                                          reservation.id === id
                                            ? updatedRoomReservation ||
                                              reservation
                                            : reservation
                                      ),
                                  }
                                : target
                            ),
                          }
                        : group
                  ),
                }
              : sale
          )
        )
      })
      .catch(() => undefined)
  }

  const handleSetNote = (note: string | null | undefined) =>
    setNote({
      variables: {
        input: {
          featureIds: features.map((feature: RoomFeature) => feature.id),
          id,
          info: note || '',
        },
      },
    })

  const handleSelectRoomType = () => {
    onSetCleaningMode(false)

    return onSetSelectedRoomType(
      selectedRoomType.id === roomType.id &&
        selectedRoomType.reservationId === id
        ? null
        : {
            checkIn: checkIn.date,
            checkOut: checkOut.date,
            id: roomType.id,
            reservationId: id,
          }
    )
  }

  const renderContent = () => (
    <FlexColumn noPadding>
      <Label>{roomType.name}</Label>

      <Dates>
        {checkIn.type === CheckInTypes.EARLY && (
          <FontAwesomeIcon
            color={theme.palette.text.lighter}
            icon="sun"
            size="sm"
            style={{ marginRight: theme.spacing.gutterSmall }}
          />
        )}
        {moment(checkIn.date).format('dd, D.M.YY')} {' – '}
        {moment(checkOut.date).format('dd, D.M.YY')}
        {checkOut.type === CheckOutTypes.LATE && (
          <FontAwesomeIcon
            color={theme.palette.text.lighter}
            icon="moon"
            size="xs"
            style={{ marginLeft: theme.spacing.gutterSmall }}
          />
        )}
      </Dates>
    </FlexColumn>
  )

  const getTotal = (item: string) =>
    roomReservations.reduce(
      // @ts-ignore
      (acc: number, r: GQLRoomReservation) => acc + get(r.request, item),
      0
    )

  return (
    <>
      <Wrapper
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <FlexColumn alignItems="center">
          <Button
            borderRadius="0"
            onClick={handleSelectRoomType}
            padding={`0 ${theme.spacing.gutter}`}
          >
            <FontAwesomeIcon
              size="xs"
              color={
                selectedRoomType.reservationId === id
                  ? theme.palette.danger.main
                  : theme.palette.text.light
              }
              icon={selectedRoomType.reservationId === id ? 'stop' : 'play'}
            />
          </Button>

          <HorizontalDivider />

          <FlexRow flex={1}>
            {loading ? (
              <ReactLoading
                type={'spin'}
                height={18}
                width={18}
                color={theme.palette.smoke.main}
              />
            ) : (
              <CheckboxInput
                checked={fulfilledByRooms}
                noMargin
                onChange={handleSetFulfilledByRooms}
              />
            )}
          </FlexRow>
        </FlexColumn>

        <VerticalDivider />

        <ContentWrapper flex={1} justifyContent="space-between">
          <FlexColumn noPadding>
            {renderContent()}

            <NoteWrapper>
              <DataField
                handleSubmit={(value) => handleSetNote(value)}
                style={{ maxWidth: `${theme.spacing.gu(28)}rem` }}
                inputType="textarea"
                placeholder={<T>RoomLayout:sidebar.addNote</T>}
                value={info}
                variant="primary"
              />
            </NoteWrapper>
          </FlexColumn>
        </ContentWrapper>

        <FlexColumn
          alignItems="flex-end"
          justifyContent={features.length ? 'space-between' : 'flex-end'}
          style={{ padding: `${theme.spacing.gu(1)}rem` }}
        >
          <RoomFeatures features={features} />
          {isHover && (
            <TrashIconWrapper onClick={handleDeleteRoomTypeReservation}>
              <FontAwesomeIcon fixedWidth icon="trash" size="xs" />
            </TrashIconWrapper>
          )}
        </FlexColumn>

        <VerticalDivider />

        <FlexColumn
          justifyContent="center"
          style={{
            padding: `${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem`,
          }}
        >
          <TotalNumber justifyContent="space-between" alignItems="center">
            <FontAwesomeIcon icon="door-open" size="sm" />
            {roomReservations.length}
          </TotalNumber>
          <TotalNumber justifyContent="space-between" alignItems="center">
            <FontAwesomeIcon icon="bed" size="sm" />
            {getTotal('beds')} / {getTotal('room.beds')}
          </TotalNumber>
          <TotalNumber justifyContent="space-between" alignItems="center">
            <FontAwesomeIcon icon="couch" size="sm" />
            {getTotal('extraBeds')} / {getTotal('room.extraBeds')}
          </TotalNumber>
        </FlexColumn>

        <VerticalDivider />

        <FlexColumn
          justifyContent="center"
          style={{
            background: theme.palette.primary.extraLighter,
            padding: theme.spacing.gutterSmall,
          }}
        >
          <TotalNumberBold justifyContent="center" alignItems="center">
            {rooms}
          </TotalNumberBold>
          <TotalNumberBold justifyContent="center" alignItems="center">
            {beds}
          </TotalNumberBold>
          <TotalNumberBold justifyContent="center" alignItems="center">
            {extraBeds}
          </TotalNumberBold>
        </FlexColumn>
      </Wrapper>

      {roomReservations.map((r: GQLRoomReservation, index: number) => (
        <NestedRoomReservation
          groupId={groupId}
          isLast={index === roomReservations.length - 1}
          key={r.id}
          reservation={r}
          roomTypeCheckIn={checkIn.date}
          roomTypeCheckOut={checkOut.date}
          roomTypeName={roomType.name}
          roomTypeReservationId={id}
          saleId={saleId}
          targetId={targetId}
        />
      ))}
    </>
  )
}
