import { ChangeEvent, Ref, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'
import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Option } from '@/components/ThemedSelect'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import { useDimensionShortcuts } from '../../../hooks'
import { useSetDimensionShortcutMutation } from '../../../mutations'
import { Placeholder } from './common'

type Props = {
  salesId: string
  readOnly?: boolean
}

export const DimensionShortcut = ({ salesId, readOnly }: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const { refresh } = salesHooks.useSalesDetailsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const { dimensionShortcuts, error, loading } = useDimensionShortcuts({
    salesId,
  })

  const [setShortcut] = useSetDimensionShortcutMutation()

  const onSetShortcut = (option: Option | null | undefined) => {
    setModalOpen(false)

    setShortcut({
      variables: {
        input: { salesId, shortcutId: option ? option.value : '' },
      },
    })
      .then(() => refresh())
      .catch(() => undefined)
  }

  const renderContent = () => {
    // Fetching
    if (loading) {
      return (
        <ReactLoading
          color={palette.smoke.main}
          height={20}
          type="spin"
          width={20}
        />
      )
    }

    // Error
    if (error) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.errorShortcut</T>
        </Placeholder>
      )
    }

    // Empty
    if (!dimensionShortcuts.length) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.emptyShortcut</T>
        </Placeholder>
      )
    }

    const options = dimensionShortcuts
      .map((s) => ({
        label: s.name,
        searchValue: s.name || '',
        value: s.id,
      }))
      .filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )

    return (
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal>
            <FlexColumn alignItems="center" flex={1}>
              <InputWrapper>
                <Input
                  autoFocus
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(event.target.value)
                  }
                  placeholder={translate(
                    'SalesDetails:dimensions.action.searchShortcuts',
                    language
                  )}
                  value={searchTerm}
                />
              </InputWrapper>

              <Divider />

              <Options noPadding>
                {options.length > 0 ? (
                  options.map((o) => (
                    <OptionWrapper
                      key={o.value}
                      onClick={() => onSetShortcut(o)}
                    >
                      {o.label}
                    </OptionWrapper>
                  ))
                ) : (
                  <OptionPlaceholder light>
                    <T>SalesDetails:dimensions.error.emptyShortcut</T>
                  </OptionPlaceholder>
                )}
              </Options>
            </FlexColumn>
          </InlineModal>
        }
        onClose={() => setModalOpen(false)}
        placement="bottom"
        referenceElement={({ ref }) => (
          <InnocuousButton
            compact
            disabled={readOnly}
            onClick={() => setModalOpen(true)}
            innerRef={ref as Ref<HTMLButtonElement> | undefined}
          >
            <PrimaryColor>
              <T>SalesDetails:dimensions.action.useShortcut</T>
            </PrimaryColor>
          </InnocuousButton>
        )}
      />
    )
  }

  return renderContent()
}

//////

const Divider = styled.div`
  width: 100%;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
  `}
`

const InputWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const Options = styled(FlexColumn)`
  overflow: auto;
  max-height: 240px;
  width: 100%;
`

const OptionPlaceholder = styled(FontWeight)`
  font-style: italic;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const OptionWrapper = styled.div`
  cursor: pointer;
  transition: 0.1s;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`
