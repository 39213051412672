import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { useKeycloakContext } from '@/config/keycloak'
import { NavInternalViewType } from '@/config/types'

type Props = {
  alt?: string
  href: string
  src: string
}

export const AppBarLogo = ({ alt, href, src }: Props) => {
  const { hasNavViewAccess } = useKeycloakContext()

  const hasAccess = hasNavViewAccess(NavInternalViewType.SearchSales)

  return hasAccess ? (
    <AppBarLogoNavLink to={href}>
      <img src={src} alt={alt} />
    </AppBarLogoNavLink>
  ) : (
    <AppBarLogoWrapper>
      <img src={src} alt={alt} />
    </AppBarLogoWrapper>
  )
}

///////

const AppBarLogoNavLinkStyles = css`
  display: block;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} 0 0;
    margin: 0 0 ${theme.spacing.gutterSmall};
    height: ${theme.spacing.gu(5)}rem;
  `}

  & > img {
    height: 100%;
  }
`

const AppBarLogoNavLink = styled(NavLink)`
  ${AppBarLogoNavLinkStyles}
`

const AppBarLogoWrapper = styled.div`
  ${AppBarLogoNavLinkStyles}
`
