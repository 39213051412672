import VisibilitySensor from 'react-visibility-sensor'

import { FlexColumn } from '@/components/Layout'

import { OrderState as OS } from '~generated-types'

import { Participant } from './Participant'
import type {
  DataRows,
  FetchedParticipant,
  Participant as ParticipantType,
  ParticipantSkeletonData,
} from './types'

type Props = {
  participants: (FetchedParticipant | ParticipantSkeletonData)[]
  readOnly?: boolean
  dataRows: DataRows
  selectedIds: string[]
  isSelectable: boolean
  handleSelect: (select: boolean, selectId: string) => void
}

export const ParticipantsList = ({
  participants,
  readOnly,
  dataRows,
  selectedIds,
  isSelectable,
  handleSelect,
}: Props) => (
  <FlexColumn>
    {participants.map((p) => {
      const participant = p as ParticipantType

      const participantNotRemovable = participant.services.length
        ? !!participant.services.find(({ purchaseProduct }) => {
            const state = purchaseProduct?.order?.lifecycle.state
            return !!state && state !== OS.Open && state !== OS.Correction
          })
        : false

      return (
        <VisibilitySensor
          key={participant.id}
          partialVisibility
          offset={{ bottom: -200, top: -200 }}
        >
          {({ isVisible }) => (
            <Participant
              participant={participant}
              isVisible={isVisible}
              notRemovable={participantNotRemovable}
              readOnly={readOnly}
              dataRows={dataRows}
              selectedIds={selectedIds}
              isSelectable={isSelectable}
              handleSelect={handleSelect}
            />
          )}
        </VisibilitySensor>
      )
    })}
  </FlexColumn>
)
