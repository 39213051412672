import { Fragment } from 'react'

import {
  AvailabilityByDate,
  CellStatus,
  EmphasisByDate,
  Mode,
  RefetchResponce,
} from '../../AvailabilityCalendar.types'
import { Cell, CellWithSaleInfo, ScrollTableProps } from './ScrollTable'

type RoomType = {
  id: string
  name: string
}

type Props = ScrollTableProps & {
  categoryName?: string
  depth: number
  emphasis?: EmphasisByDate | null | undefined
  mode: Mode
  property: 'beds' | 'extraBeds' | 'rooms'
  refetchAvailability: () => Promise<RefetchResponce>
  roomTypes?: RoomType[]
  status: AvailabilityByDate
}

export const CountStatusRow = ({
  emphasis,
  mode,
  property,
  status,
  roomTypes,
  categoryName,
  refetchAvailability,
  ...tableProps
}: Props) => {
  const { columnGroups } = tableProps

  return (
    <>
      {columnGroups.map(({ items, key: weekKey }, weekIdx) => (
        <Fragment key={`data-${weekKey}`}>
          {items.map(({ key: dateKey }, dateIdx) => {
            const capacity = status[dateKey]?.totalCapacity?.[property]
            const value = status[dateKey]?.[mode]?.[property]
            const valueStr = `${value ?? '–'}`
            const cellStatus = getCellStatus(capacity ?? 0, value ?? 0)
            const isLastCell =
              weekIdx === columnGroups.length - 1 &&
              dateIdx === items.length - 1

            return roomTypes ? (
              <CellWithSaleInfo
                categoryName={categoryName}
                dateKey={dateKey}
                emphasis={emphasis?.[dateKey]}
                isLast={isLastCell}
                key={`data-${dateKey}-${property}`}
                refetchAvailability={refetchAvailability}
                roomTypes={roomTypes}
                status={cellStatus}
                {...tableProps}
              >
                {valueStr}
              </CellWithSaleInfo>
            ) : (
              <Cell
                emphasis={emphasis?.[dateKey]}
                isBold={property === 'rooms'}
                isLast={isLastCell}
                key={`data-${dateKey}-${property}`}
                status={cellStatus}
                {...tableProps}
              >
                {valueStr}
              </Cell>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

////////////

const getCellStatus = (capacity: number, value: number): CellStatus => {
  if (value / capacity <= 0) {
    return CellStatus.OCCUPIED
  }

  if (value / capacity >= 1) {
    return CellStatus.VACANT
  }

  if (capacity === 0) {
    return CellStatus.UNAVAILABLE
  }

  return CellStatus.PARTIAL
}
