import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  ParticipantVstSetDatesDefaultMutation,
  ParticipantVstSetDatesDefaultMutationVariables,
} from '~generated-types'

export type {
  ParticipantVstSetDatesDefaultMutation,
  ParticipantVstSetDatesDefaultMutationVariables,
}

export const SET_VST_DATES_DEFAULT = gql`
  ${participantFragments.participantVst}

  mutation ParticipantVSTSetDatesDefault(
    $input: ParticipantVstSetDatesDefaultInput!
  ) {
    participantVstSetDatesDefault(input: $input) {
      participantId
      vst {
        ...ParticipantVst
      }
    }
  }
`

export function useVSTSetDatesDefault() {
  return useMutation<
    ParticipantVstSetDatesDefaultMutation,
    ParticipantVstSetDatesDefaultMutationVariables
  >(SET_VST_DATES_DEFAULT)
}

export default useVSTSetDatesDefault
