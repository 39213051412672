import { useState } from 'react'

import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import { VSTDetailsModal } from './VSTDetailsModal'

type Props = {
  isVSTWarning: boolean
  participant: FetchedParticipant
  readOnly?: boolean
}

export const VSTCompactDetails = ({
  isVSTWarning,
  participant,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <ModalContainer
      referenceElement={({ ref }) => (
        <ServiceItemSquared
          ref={ref}
          disabled={readOnly}
          isWarning={isVSTWarning}
          minWidth={`${spacing.gu(16)}rem`}
          onClick={readOnly ? () => null : () => setModalOpen(true)}
          renderContent={() => (
            <T>ParticipantsList:PersonalFields.VSTDetails</T>
          )}
        />
      )}
      onClose={() => setModalOpen(false)}
      isOpen={isModalOpen}
      modal={<VSTDetailsModal participant={participant} />}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}
