import { EmailsList, GuestCommunication } from '@/modules/Communications'

import { SectionContainer } from '../components'
import { SectionFeatures } from '../types'

const SECTION: SectionFeatures = {
  icon: 'envelope',
  key: 'guest_communication',
}

const CommunicationsComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <GuestCommunication />
    <EmailsList />
  </SectionContainer>
)

export const Communications = Object.freeze({
  component: CommunicationsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
