import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  SalesParticipantDeleteRoomMutation,
  SalesParticipantDeleteRoomMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantDeleteRoomMutation,
  SalesParticipantDeleteRoomMutationVariables,
}

export const SALES_PARTICIPANT_DELETE_ROOM = gql`
  ${participantFragments.participantService}

  mutation SalesParticipantDeleteRoom(
    $input: SalesParticipantDeleteRoomInput!
  ) {
    salesParticipantDeleteRoom(input: $input) {
      participantRoomId
      service {
        ...ParticipantService
      }
    }
  }
`

export function useSalesParticipantDeleteRoom() {
  return useMutation<
    SalesParticipantDeleteRoomMutation,
    SalesParticipantDeleteRoomMutationVariables
  >(SALES_PARTICIPANT_DELETE_ROOM)
}

export default useSalesParticipantDeleteRoom
