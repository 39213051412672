import React from 'react'

import { BottomSheet } from '@/components/BottomSheet'
import { useDialogService } from '@/components/DialogService'
import {
  ReservationListContextProvider,
  useReservationListContext,
} from '@/modules/Accommodation/SalesReservationList/ReservationListState'
import { SalesAccommodationGroup } from '@/modules/Accommodation/SalesReservationList/SalesReservationList.types'
import { SalesReservationManager } from '@/modules/Accommodation/SalesReservationManager'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

type Props = {
  groupId: string
  targetId: string
  roomPreview: {
    [sectionId: string]: boolean
  }
  focusedRoomId: string
  onCloseManager: () => void
}

const AccommodationManager = ({
  groupId,
  targetId,
  roomPreview,
  focusedRoomId,
  onCloseManager,
}: Props) => {
  const { confirm } = useDialogService()

  const { data, getEstimatedEndDate, getEstimatedStartDate } =
    salesHooks.useSalesDetailsContext()
  const { reservationGroups } = useReservationListContext()

  const group = reservationGroups.find(
    (group) => group.id === groupId
  ) as SalesAccommodationGroup

  const { customer, estimatedDates, estimatedParticipants, name, orderNumber } =
    data

  const customerData = customer?.customer

  const salesDetails = {
    customerName:
      customerData?.__typename === 'CustomerOrganization'
        ? customerData.organization.name
        : customerData?.__typename === 'CustomerPerson'
          ? `${customerData.person.firstName} ${customerData.person.lastName}`
          : '',
    dates: estimatedDates,
    participants: estimatedParticipants,
    saleName: name,
    saleNumber: orderNumber,
  }

  const estimatedEnd = getEstimatedEndDate()
  const estimatedStart = getEstimatedStartDate()
  const defaultRange =
    estimatedEnd && estimatedStart
      ? {
          from: estimatedStart.clone(),
          to: estimatedEnd.clone(),
        }
      : null

  const handleCloseManager = () => {
    const showConfirmModal =
      !!group.roomTypeReservations.find(
        (r) => r.__typename === 'PendingRoomTypeReservation'
      ) ||
      !!group.roomReservations.find(
        (r) => r.__typename === 'PendingRoomReservation'
      )

    return showConfirmModal
      ? confirm({
          cancelLabel: <T>common:action.cancel</T>,
          confirmLabel: <T>common:action.close</T>,
          description: (
            <T>Accommodation:SalesReservationManager.confirmClosing</T>
          ),
        })
          .then(() => onCloseManager())
          .catch(() => undefined)
      : onCloseManager()
  }

  return (
    <BottomSheet
      onClose={handleCloseManager}
      title={<T>SalesDetails:overlay.accommodation</T>}
    >
      <SalesReservationManager
        defaultRange={defaultRange}
        groupId={groupId}
        targetId={targetId}
        salesDetails={salesDetails}
        focusedRoomId={focusedRoomId}
        openSectionsInitially={roomPreview}
      />
    </BottomSheet>
  )
}

const AccommodationManagerStateWrapper = (props: Props) => {
  const { data } = salesHooks.useSalesDetailsContext()

  return (
    <ReservationListContextProvider
      salesId={data.id}
      eventId={data.event?.id || null}
    >
      <AccommodationManager {...props} />
    </ReservationListContextProvider>
  )
}

export { AccommodationManagerStateWrapper as AccommodationManager }
