import { Ref } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { OutsideClickHelper } from '@/components/OutsideClick'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

import { NavItemType } from '~generated-types'

import { NavbarButtonStyles } from '../common'
import { DropdownNavItem } from './types'

type Props = {
  activeItem?: DropdownNavItem
  dropdownItems: DropdownNavItem[]
  hasAccess: boolean
  itemKey: NavItemType
  setIsOpen: (isOpen: boolean) => void
}

export const NavbarDropdownToggle = ({
  activeItem,
  dropdownItems,
  hasAccess,
  itemKey,
  setIsOpen,
}: Props) =>
  hasAccess ? (
    <OutsideClickHelper
      onOutsideClick={() => setIsOpen(false)}
      render={({ ref }) => (
        <StyledNavDropdownToggle
          className={classNames({ 'is-active': !!activeItem })}
          onClick={() => setIsOpen(true)}
          to=""
          ref={ref as Ref<HTMLAnchorElement>}
        >
          <LabelWrapper>
            <Label>
              <T>{`Navigation:navItem.${itemKey}`}</T>
            </Label>
            {activeItem && <SubLabel>{activeItem.label}</SubLabel>}
          </LabelWrapper>

          {dropdownItems && <Icon icon="angle-down" />}
        </StyledNavDropdownToggle>
      )}
    />
  ) : (
    <Tooltip
      content={<T>common:error.access.title</T>}
      placement="bottom"
      trigger={(triggerProps) => (
        <StyledNavDropdownToggle
          className={classNames({ 'is-active': !!activeItem })}
          disabled
          to=""
          {...triggerProps}
        >
          <LabelWrapper>
            <Label>
              <T>{`Navigation:navItem.${itemKey}`}</T>
            </Label>
            {activeItem && <SubLabel>{activeItem.label}</SubLabel>}
          </LabelWrapper>

          <Icon icon="lock" size="sm" style={{ marginBottom: 2 }} />
        </StyledNavDropdownToggle>
      )}
    />
  )

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}

  @media (max-width: 1350px) {
    display: none;
  }
`

const Label = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
`

const StyledNavButton = styled(NavLink)`
  ${NavbarButtonStyles}

  &.is-active ${Label} {
    font-weight: 700;
  }
`

const StyledNavDropdownToggle = styled(StyledNavButton).attrs(() => ({
  as: 'button',
}))`
  border: none;
  box-shadow: none;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const SubLabel = styled.span`
  font-variant-caps: all-small-caps;
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`
