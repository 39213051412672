import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import {
  AgeReference,
  ParticipantBirthday,
} from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import { AgeField, ParticipantFragment } from '~generated-types'

import { AgeCategorySelector } from './common/AgeCategorySelector'
import { AgeInput } from './common/AgeInput'
import { BirthdayInputs } from './common/BirthdayInputs'

type ParticipantAgeCategory = ParticipantFragment['ageCategory']

type Props = {
  age: number | null
  ageCategory: ParticipantAgeCategory | null
  ageSource: AgeField | null
  birthday: ParticipantBirthday | null
  handleSubmit: (value: AgeReference) => Promise<any>
}

const ParticipantAgeModal = ({
  age,
  ageCategory,
  ageSource,
  birthday,
  handleSubmit,
}: Props) => {
  const { spacing } = useTheme()

  return (
    <InlineModal style={{ overflow: 'visible', width: '250px' }}>
      <InlineModalSection style={{ margin: 0, padding: 0 }}>
        <FlexColumn
          alignItems="stretch"
          flex={1}
          style={{ padding: `${spacing.gu(2)}rem` }}
        >
          <BirthdayInputs birthday={birthday} handleSubmit={handleSubmit} />
          <AgeInput
            age={age}
            ageSource={ageSource}
            handleSubmit={handleSubmit}
          />
          <AgeCategorySelector
            ageCategory={ageCategory}
            ageSource={ageSource}
            handleSubmit={handleSubmit}
          />
        </FlexColumn>
      </InlineModalSection>
    </InlineModal>
  )
}

export default ParticipantAgeModal
