import { gql, useMutation } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

import {
  RoomTypeReservationSetNeedsMutation,
  RoomTypeReservationSetNeedsMutationVariables,
} from '~generated-types'

export type {
  RoomTypeReservationSetNeedsMutation,
  RoomTypeReservationSetNeedsMutationVariables,
}

export const ROOM_TYPE_RESERVATION_SET_NEEDS = gql`
  ${accommodationFragments.accommodationSettings}
  ${accommodationFragments.roomTypeReservation}

  mutation RoomTypeReservationSetNeeds(
    $input: AccommodationRoomTypeReservationSetNeedsInput!
  ) {
    accommodationRoomTypeReservationSetNeeds(input: $input) {
      roomTypeReservation {
        ...RoomTypeReservation
      }
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export function useRoomTypeReservationSetNeeds() {
  return useMutation<
    RoomTypeReservationSetNeedsMutation,
    RoomTypeReservationSetNeedsMutationVariables
  >(ROOM_TYPE_RESERVATION_SET_NEEDS)
}

export default useRoomTypeReservationSetNeeds
