import { gql } from '@apollo/client'

import accommodationIssue from './accommodationIssue'

export default gql`
  ${accommodationIssue}

  fragment RoomReservation on RoomReservation {
    id
    issues {
      ...AccommodationIssue
    }
    request {
      beds
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      info
      extraBeds
      features {
        icon
        id
        name
        shortName
      }
      info
      room {
        floor
        id
        number
        beds
        extraBeds
        internalInfo
        roomType {
          id
          accommodationLevel {
            id
            name
            shortName
          }
          category {
            id
            name
          }
          name
        }
        status {
          cleaningStatus
        }
      }
    }
    participantRooms {
      id
      participant {
        id
      }
    }
    roomTypeReservation {
      id
    }
    type
    target {
      id
    }
  }
`
