import { gql } from '@apollo/client'

import participantPersonalData from './participantPersonalData'
import participantService from './participantService'

export default gql`
  ${participantPersonalData}
  ${participantService}

  fragment Participant on SalesParticipant {
    ...ParticipantPersonalData
    services {
      ...ParticipantService
    }
  }
`
