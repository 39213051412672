import { gql } from '@apollo/client'

export default gql`
  fragment CommunicationSettings on GuestCommunicationSettings {
    id
    lastIntegrationSyncAt
    origin
    rateCode
    source
    template {
      description
      id
      name
    }
  }
`
