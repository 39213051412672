import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { IconButton, LinkLikeButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import TextInput from '../../../ParticipantRow/ParticipantMainInfo/components/common/TextInput'

type Props = {
  roomFriends: string[]
  handleUpdateRoomFriends: (
    roomFriends: string[]
  ) => Promise<FetchedParticipant | undefined>
}

const RoomFriends = ({ roomFriends, handleUpdateRoomFriends }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const [friends, setFriends] = useState(roomFriends)

  return (
    <Wrapper noPadding alignItems="flex-end">
      {friends.map((friend, index) => (
        <FlexRow
          key={`${friend}_${index}`}
          style={{ marginBottom: spacing.gutter, width: '100%' }}
          alignItems="center"
        >
          <IconWrapper>
            <FontAwesomeIcon
              color={palette.primary.dark}
              icon={'user'}
              size="sm"
            />
          </IconWrapper>
          <InputWrapper>
            <TextInput
              value={friend}
              placeholder={translate(
                'ParticipantsList:ParticipantFormFields.participantName',
                language
              )}
              onSubmit={(name) => {
                const friendsList = friends.map((friend, i) =>
                  i === index ? name : friend
                )
                setFriends(friendsList)
                return handleUpdateRoomFriends(friendsList)
              }}
              style={{
                border: `1px solid ${palette.smoke.dark}`,
                borderRadius: '6px',
              }}
            />
          </InputWrapper>
          <IconButton
            color="transparent"
            icon="trash"
            onClick={() => {
              const friendsList = friends.filter((f) => f !== friend)
              setFriends(friendsList)
              return handleUpdateRoomFriends(friendsList)
            }}
            style={{
              color: palette.danger.main,
              marginLeft: spacing.gutterSmall,
            }}
          />
        </FlexRow>
      ))}
      <LinkLikeButton
        style={{
          padding: `${spacing.gu(1)}rem ${spacing.gu(1.5)}rem`,
        }}
        onClick={() => setFriends([...friends, ''])}
      >
        + <T>ParticipantsList:PersonalFields.addRoomFriend</T>
      </LinkLikeButton>
    </Wrapper>
  )
}

export default RoomFriends

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const InputWrapper = styled.div`
  flex: 1;
  width: 100%;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`
