import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

type Props = {
  isEditMode: boolean
  readOnly: boolean
  setEditMode: (editMode: boolean) => void
}

export const DimensionModeSwitcher = ({
  isEditMode,
  readOnly,
  setEditMode,
}: Props) => (
  <ButtonGroup>
    <ButtonGroupButton
      disabled={readOnly}
      noNudge
      onClick={() => setEditMode(false)}
      selected={!isEditMode}
      size="small"
    >
      <T>SalesDetails:meals.action.view</T>
    </ButtonGroupButton>
    <ButtonGroupButton
      disabled={readOnly}
      noNudge
      onClick={() => setEditMode(true)}
      selected={isEditMode}
      size="small"
    >
      <T>common:action.edit</T>
    </ButtonGroupButton>
  </ButtonGroup>
)
