import { Fragment } from 'react'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { ParticipantForPrint } from '@/modules/ParticipantsList/types'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

type Participant = Omit<ParticipantForPrint, 'services' | '__typename'> & {
  dates: {
    checkIn: string | null
    checkOut: string | null
  }[]
}

export type Room = {
  participants: Participant[]
  id: string
  number: string | null
}

type Props = {
  isFirst: boolean
  room: Room
}

export const RoomsTableRow = ({
  isFirst,
  room: { id, number, participants },
}: Props) => {
  const roomRowSpan = participants.reduce((acc, p) => acc + p.dates.length, 0)

  const [firstParticipant, ...restParticipants] = participants
  const [firstParticipantFirstDate, ...firstParticipantRestDates] =
    firstParticipant.dates

  return (
    <>
      <tr style={{ pageBreakInside: isFirst ? 'auto' : 'avoid' }}>
        <td rowSpan={roomRowSpan}>
          {number ?? (
            <Placeholder>
              <T>ParticipantsList:ParticipantRooms.roomUnassigned</T>
            </Placeholder>
          )}
        </td>
        <td rowSpan={firstParticipant.dates.length}>
          {getParticipantName(
            firstParticipant.firstName,
            firstParticipant.lastName
          )}
        </td>
        <td style={{ borderRightWidth: 1 }}>
          {formatDate(
            firstParticipantFirstDate.checkIn,
            firstParticipantFirstDate.checkOut,
            'short'
          ) || '–'}
        </td>
      </tr>

      {firstParticipantRestDates.map(({ checkIn, checkOut }, idx) => (
        <tr className="extra-row" key={`date-row-${id}-${idx}`}>
          <td style={{ borderRightWidth: 1 }}>
            {formatDate(checkIn, checkOut, 'short') || '–'}
          </td>
        </tr>
      ))}

      {restParticipants.map(({ dates, firstName, id, lastName }) => {
        const [firstDate, ...restDates] = dates

        return (
          <Fragment key={`participant-row-${id}`}>
            <tr className="extra-row">
              <td rowSpan={dates.length}>
                {getParticipantName(firstName, lastName)}
              </td>
              <td style={{ borderRightWidth: 1 }}>
                {formatDate(firstDate.checkIn, firstDate.checkOut, 'short') ||
                  '–'}
              </td>
            </tr>

            {restDates.map(({ checkIn, checkOut }, idx) => (
              <tr className="extra-row" key={`date-row-${id}-${idx}`}>
                <td style={{ borderRightWidth: 1 }}>
                  {formatDate(checkIn, checkOut, 'short') || '–'}
                </td>
              </tr>
            ))}
          </Fragment>
        )
      })}
    </>
  )
}

///////

const getParticipantName = (firstName: string, lastName: string) =>
  firstName || lastName ? (
    `${lastName}${firstName && lastName ? ', ' : ''}${firstName}`
  ) : (
    <Placeholder>
      <T>ParticipantsList:unnamedParticipant</T>
    </Placeholder>
  )

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
