import { gql, useMutation } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

import {
  AccommodationTargetRoomMutation,
  AccommodationTargetRoomMutationVariables,
} from '~generated-types'

export type {
  AccommodationTargetRoomMutation,
  AccommodationTargetRoomMutationVariables,
}

export const ACCOMMODATION_TARGET_ROOM = gql`
  ${accommodationFragments.accommodationTarget}
  ${accommodationFragments.roomReservation}
  ${accommodationFragments.roomTypeReservation}

  mutation AccommodationTargetRoom($input: AccommodationRoomTargetInput!) {
    accommodationRoomTarget(input: $input) {
      accommodationTarget {
        ...AccommodationTarget
      }
      roomReservation {
        ...RoomReservation
        roomTypeReservation {
          ...RoomTypeReservation
        }
      }
    }
  }
`

export function useAccommodationTargetRoom() {
  return useMutation<
    AccommodationTargetRoomMutation,
    AccommodationTargetRoomMutationVariables
  >(ACCOMMODATION_TARGET_ROOM)
}

export default useAccommodationTargetRoom
