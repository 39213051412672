import { Fragment } from 'react'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { ParticipantForPrint } from '@/modules/ParticipantsList/types'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

export type ParticipantService = {
  dates: {
    checkIn: string | null
    checkOut: string | null
  }[]
  roomId: string
  roomNumber: string | null
}

type Participant = Omit<ParticipantForPrint, 'services' | '__typename'> & {
  services: ParticipantService[]
}

type Props = {
  participant: Participant
}

export const ParticipantsTableRow = ({
  participant: { firstName, id, lastName, services },
}: Props) => {
  if (!services.length) {
    return (
      <tr key={`participant-row-${id}`}>
        <td>{getParticipantName(firstName, lastName)}</td>
        <td>–</td>
        <td>–</td>
      </tr>
    )
  }

  const participantRowSpan = services.reduce(
    (acc, s) => acc + s.dates.length,
    0
  )

  const [firstService, ...restServices] = services
  const [firstServiceFirstDate, ...firstServiceRestDates] = firstService.dates

  return (
    <>
      <tr>
        <td rowSpan={participantRowSpan}>
          {getParticipantName(firstName, lastName)}
        </td>
        <td rowSpan={firstService.dates.length}>
          {firstService.roomNumber ?? '–'}
        </td>
        <td style={{ borderRightWidth: 1 }}>
          {formatDate(
            firstServiceFirstDate.checkIn,
            firstServiceFirstDate.checkOut,
            'short'
          ) || '–'}
        </td>
      </tr>

      {firstServiceRestDates.map(({ checkIn, checkOut }, idx) => (
        <tr className="extra-row" key={`date-row-${id}-${idx}`}>
          <td style={{ borderRightWidth: 1 }}>
            {formatDate(checkIn, checkOut, 'short') || '–'}
          </td>
        </tr>
      ))}

      {restServices.map(({ dates, roomId, roomNumber }) => {
        const [firstDate, ...restDates] = dates

        return (
          <Fragment key={`room-row-${roomId}`}>
            <tr className="extra-row">
              <td rowSpan={dates.length}>{roomNumber ?? '–'}</td>
              <td style={{ borderRightWidth: 1 }}>
                {formatDate(firstDate.checkIn, firstDate.checkOut, 'short') ||
                  '–'}
              </td>
            </tr>

            {restDates.map(({ checkIn, checkOut }) => (
              <tr className="extra-row" key={`date-row-${roomId}`}>
                <td style={{ borderRightWidth: 1 }}>
                  {formatDate(checkIn, checkOut, 'short') || '–'}
                </td>
              </tr>
            ))}
          </Fragment>
        )
      })}
    </>
  )
}

///////

const getParticipantName = (firstName: string, lastName: string) =>
  firstName || lastName ? (
    `${lastName}${firstName && lastName ? ', ' : ''}${firstName}`
  ) : (
    <Placeholder>
      <T>ParticipantsList:unnamedParticipant</T>
    </Placeholder>
  )

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
