import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataField } from '@/components/DataField'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Placeholder } from '@/modules/Products/components'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { useTheme } from '@/theme'

type Props = {
  id: string
  name: string
}

export const NameInput = ({ id, name }: Props) => {
  const { spacing } = useTheme()
  const { readOnly, updateProduct } = useCatalogProductManagerContext()

  const [value, setValue] = useState<string>(name)

  const handleUpdateName = (newName: string | null) => {
    setValue(newName ?? '')

    return newName !== name
      ? updateProduct({ id, name: newName ?? '' }).then(
          (res) => !res && setValue(name)
        )
      : new Promise(() => undefined)
  }

  return (
    <Wrapper flex={1}>
      <Title>
        <T>Products:ProductManager.pricing.name</T>
      </Title>

      <DataFieldWrapper>
        <DataField
          disabled={readOnly}
          customValueRenderer={(value) => <Name>{value}</Name>}
          handleSubmit={handleUpdateName}
          inputType="text"
          placeholder={
            <Placeholder>
              <T>Products:ProductManager.pricing.unnamedProduct</T>
            </Placeholder>
          }
          style={{
            marginLeft: `-${spacing.guPx(1) + 1}px`,
            width: `calc(100% + ${spacing.gu(1)}rem)`,
          }}
          value={value}
        />
      </DataFieldWrapper>
    </Wrapper>
  )
}

////////

const DataFieldWrapper = styled.div`
  width: 100%;

  input {
    &:not([type='checkbox']):not([type='radio']) {
      ${({ theme }) => css`
        height: ${theme.spacing.gu(4)}rem;
      `}
    }
  }
`

const Name = styled(FlexRow)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(0.5)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`
