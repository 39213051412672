import { DateRange } from '@/components/TimeControls'

import { ReservationList } from './ReservationList'
import { ReservationListContextProvider } from './ReservationListState'
import { SalesDetailsBase } from './SalesReservationList.types'

type Props = {
  defaultRange: DateRange | null | undefined
  hideTitle?: boolean
  salesId: string
  eventId: string | null
  salesDetails: SalesDetailsBase
  readOnly?: boolean
}

export const SalesReservationList = ({
  defaultRange,
  hideTitle,
  salesId,
  eventId,
  salesDetails,
  readOnly,
}: Props) => (
  <ReservationListContextProvider salesId={salesId} eventId={eventId}>
    <ReservationList
      defaultRange={defaultRange}
      hideTitle={hideTitle}
      salesId={salesId}
      salesDetails={salesDetails}
      readOnly={readOnly}
    />
  </ReservationListContextProvider>
)
