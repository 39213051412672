import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  SalesParticipantUpdateRoomMutation,
  SalesParticipantUpdateRoomMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantUpdateRoomMutation,
  SalesParticipantUpdateRoomMutationVariables,
}

export const SALES_PARTICIPANT_UPDATE_ROOM = gql`
  ${participantFragments.participantService}

  mutation SalesParticipantUpdateRoom(
    $input: SalesParticipantUpdateRoomInput!
  ) {
    salesParticipantUpdateRoom(input: $input) {
      service {
        ...ParticipantService
      }
    }
  }
`

export function useSalesParticipantUpdateRoom() {
  return useMutation<
    SalesParticipantUpdateRoomMutation,
    SalesParticipantUpdateRoomMutationVariables
  >(SALES_PARTICIPANT_UPDATE_ROOM)
}

export default useSalesParticipantUpdateRoom
