import { gql } from '@apollo/client'

export default gql`
  query SalesAndEnrollmentsBase($id: ID!) {
    sales(id: $id) {
      id
      type
      orderNumber
      participantConnection {
        totalElements
      }
      enrollments {
        nodes {
          id
          type
          orderNumber
          name
          participantConnection {
            totalElements
          }
          customer {
            customer {
              id
              ... on CustomerPerson {
                person {
                  firstName
                  lastName
                }
              }
              ... on CustomerOrganization {
                organization {
                  name
                }
              }
            }
          }
        }
        totalPages
        totalElements
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
