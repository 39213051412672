import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

import { DropdownNavItem } from './types'

type Props = {
  hasAccess: boolean
  isActive: boolean
  item: DropdownNavItem
}

export const NavbarDropdownButton = ({
  hasAccess,
  isActive,
  item: { isEmbedded, label, openInNewTab, to },
}: Props) =>
  hasAccess ? (
    <DropdownButtonWrapper>
      {isEmbedded ? (
        <DropdownButtonEmbedded
          activeClassName="is-active"
          to={to}
          target={openInNewTab ? '_blank' : '_self'}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
        >
          {label}
        </DropdownButtonEmbedded>
      ) : (
        <DropdownButtonExternal
          href={to}
          target={openInNewTab ? '_blank' : '_self'}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
        >
          {label}
        </DropdownButtonExternal>
      )}
    </DropdownButtonWrapper>
  ) : (
    <DropdownButtonWrapper>
      <Tooltip
        content={<T>common:error.access.title</T>}
        placement="right"
        trigger={(triggerProps) => (
          <DropdownButtonExternal
            className={classNames({ 'is-active': isActive })}
            disabled
            {...triggerProps}
          >
            {label}
            <Icon icon="lock" size="xs" />
          </DropdownButtonExternal>
        )}
      />
    </DropdownButtonWrapper>
  )

////////////

const DropdownButtonWrapper = styled.li`
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`

const DropdownButtonStyles = css<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  text-decoration: none;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
    color: ${theme.palette.text.light};
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: #bbb;
      cursor: not-allowed;
    `}

  &.is-active {
    ${({ disabled, theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${theme.palette.primary.extraLight};
      opacity: ${disabled ? 0.6 : 1};
    `}
  }

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        color: ${theme.palette.primary.dark};
        background: ${theme.palette.primary.extraLight};
      `}
  }
`

const DropdownButtonEmbedded = styled(NavLink)`
  ${DropdownButtonStyles}
`

const DropdownButtonExternal = styled.a`
  ${DropdownButtonStyles}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(4)}rem;
  `}
`
