import { Fragment } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { useParticipantsListContext } from '@/modules/ParticipantsList/ParticipantsListState'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import {
  ParticipantFragment,
  ParticipantServiceFragment,
} from '~generated-types'

import { ParticipantCheckManualControl } from '../../ParticipantServices/AccommodationService/modules'
import { ParticipantSaleSelector } from './ParticipantSaleSelector'

type Props = {
  participant: ParticipantFragment
  services: ParticipantServiceFragment[]
  readOnly?: boolean
}

export const ParticipantAdditionalInfo = ({
  participant,
  services,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const { data: sales } = salesHooks.useSalesDetailsContext()

  const { handleMoveParticipant } = useParticipantsListContext()

  return (
    <FlexColumn>
      <FlexRow
        alignItems={'stretch'}
        justifyContent={'flex-start'}
        flex={1}
        style={{ margin: `${spacing.gu(1)}rem 0`, minHeight: '35px' }}
      >
        {sales.type === 'EVENT' && (
          <ParticipantSaleSelector
            participantSalesId={participant.sales.id}
            readOnly={readOnly}
            handleSelectSale={(newSale) =>
              handleMoveParticipant(
                participant?.id,
                newSale,
                participant?.sales.id
              )
            }
          />
        )}

        <span style={{ flex: 1 }} />

        <FlexColumn>
          {services.map((service) => (
            <Fragment key={`additional-info-${service.id}`}>
              {service.__typename === 'ServiceParticipantBed' &&
                service.participantRoom && (
                  <ParticipantCheckManualControl
                    checkIn={service.participantRoom.checkIn}
                    checkOut={service.participantRoom.checkOut}
                    roomReservationId={service.participantRoom.id}
                    readOnly={readOnly}
                  />
                )}
            </Fragment>
          ))}
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  )
}
