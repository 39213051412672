import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { RoomTypeList } from './components/RoomTypeList'

export const RoomTypeRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <RoomTypeList />
      </Route>

      {/* TODO: Create / edit –views */}
    </Switch>
  )
}

export default RoomTypeRoutes
