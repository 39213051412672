import { gql } from '@apollo/client'

export default gql`
  query ParticipantDefaultVstDates($id: ID!) {
    salesParticipant(id: $id) {
      id
      vst {
        defaults {
          end
          start
        }
      }
    }
  }
`
