import { gql } from '@apollo/client'

export default gql`
  query SalesParticipantCounts($id: ID!) {
    sales(id: $id) {
      id
      participantCounts {
        byAgeCategory {
          count
          ageCategory {
            key
            name
            shortName
          }
        }
      }
    }
  }
`
