import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

export const AccessErrorPage = () => (
  <Wrapper alignItems="center" justifyContent="center" flex={1}>
    <FlexColumn>
      <Icon icon="user-lock" size="3x" />

      <Title>
        <T>common:error.access.title</T>
      </Title>

      <Description>
        <T>common:error.access.description1</T>
      </Description>

      <Description>
        <T>common:error.access.description2</T>
      </Description>
    </FlexColumn>
  </Wrapper>
)

////////////

const Description = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(4)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(8)}rem;
  `}
`
