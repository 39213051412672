import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client/errors'
import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router'

import { DateRange } from '@/components/TimeControls'
import { useEmailsSearch } from '@/modules/Communications/Emails/hooks'
import {
  getSortOrder,
  getSortOrderFromRoute,
  parseDate,
} from '@/modules/Communications/Emails/utils'
import { translate, useLanguageContext } from '@/modules/Language'
import { useRouteValue } from '@/utils/hooks'

import {
  EmailEventType,
  EmailSortProperty as SortField,
  SortOrder as SortDirection,
} from '~generated-types'

import {
  DateIntervalProps,
  EmailsSearch,
  SearchFilterProps,
  SortOrder,
  StatusFilterProps,
} from './types'

type Filter = {
  key: string
  label: string
  onClick: () => void
  value: string
  visible: boolean
}

type ContextType = {
  clearAllFilters: () => void
  dateIntervalProps: DateIntervalProps
  emails?: EmailsSearch
  error?: ApolloError
  filters: Filter[]
  handleSetSize: (size: number) => void
  loading: boolean
  page: number
  searchFilterProps: SearchFilterProps
  setPage: (arg: number) => void
  size: number
  statusFilterProps: StatusFilterProps
  handleSetSortOrder: (sortOrder: SortOrder) => void
  sortOptions: SortOrder[]
  sortOrder: SortOrder
}

const RegistersEmailsListContext = createContext<ContextType>({
  clearAllFilters: () => undefined,
  dateIntervalProps: {
    setValue: () => undefined,
    value: undefined,
  },
  emails: undefined,
  error: undefined,
  filters: [],
  handleSetSize: () => undefined,
  handleSetSortOrder: () => undefined,
  loading: false,
  page: 0,
  searchFilterProps: {
    setValue: () => undefined,
    value: '',
  },
  setPage: () => undefined,
  size: 0,
  sortOptions: [],
  sortOrder: {
    direction: SortDirection.Asc,
    field: SortField.SentAt,
    label: 'en',
  },
  statusFilterProps: {
    setValue: () => undefined,
    statuses: [],
    value: [],
  },
})

export const RegistersEmailsListContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { language } = useLanguageContext()
  const history = useHistory()
  const { path } = useRouteMatch()

  const DEFAULT_PAGE_SIZE = 50
  const DEFAULT_SORT_ORDER = getSortOrder(
    SortDirection.Asc,
    SortField.SentAt,
    language
  )

  // Initial state
  const { setValue: setRoutePage, value: routePage } = useRouteValue({
    routeKey: 'page',
  })
  const { setValue: setRouteSize, value: routeSize } = useRouteValue({
    routeKey: 'size',
  })
  const { setValue: setRouteSort, value: routeSort } = useRouteValue({
    routeKey: 'sort',
  })
  const { setValue: setRouteSearch, value: routeSearch } = useRouteValue({
    routeKey: 'search',
  })
  const { setValue: setRouteStatus, value: routeStatus } = useRouteValue({
    multi: true,
    routeKey: 'status',
  })
  const { setValue: setRouteDates, value: routeDates } = useRouteValue({
    multi: false,
    routeKey: 'dates',
  })

  const [page, setPage] = useState<number>(
    routePage ? Number(routePage) - 1 : 0
  )
  const [size, setSize] = useState<number>(
    routeSize ? Number(routeSize) : DEFAULT_PAGE_SIZE
  )
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    Array.isArray(routeSort) || !routeSort
      ? DEFAULT_SORT_ORDER
      : (getSortOrderFromRoute(routeSort, language) ?? DEFAULT_SORT_ORDER)
  )
  const [searchValue, setSearchValue] = useState<string>(
    Array.isArray(routeSearch) || !routeSearch ? '' : routeSearch
  )
  const [statuses, setStatuses] = useState<EmailEventType[]>(
    Array.isArray(routeStatus) ? (routeStatus as EmailEventType[]) : []
  )

  const [range, setRange] = useState<DateRange | undefined>(
    routeDates && typeof routeDates === 'string'
      ? {
          from: moment(routeDates.split('/')[0]),
          to: moment(routeDates.split('/')[1]),
        }
      : undefined
  )

  //Lifecycle methods
  useEffect(() => {
    if (routePage || page) {
      setRoutePage((page + 1).toString())
    }
  }, [page])

  // Emails search hook
  const { emails, error, loading } = useEmailsSearch({
    filter: {
      dates: range && {
        end: parseDate(range.to),
        start: parseDate(range.from),
      },
      freeText: searchValue,
      statuses: statuses,
    },
    pagination: {
      page,
      size,
    },
    sort: [
      {
        field: sortOrder.field,
        order: sortOrder.direction,
      },
    ],
  })

  // Methods
  const handleSetSize = (size: number) => {
    setSize(size)
    setPage(0)
    setRouteSize(size.toString())
  }
  const handleSetSortOrder = (sortOrder: SortOrder) => {
    setSortOrder(sortOrder)
    setPage(0)
    setRouteSort(`${sortOrder.field}_${sortOrder.direction}`)
  }
  const handleSetSearch = (search: string) => {
    setSearchValue(search)
    setPage(0)
    setRouteSearch(search)
  }
  const handleSetStatus = (status: EmailEventType[]) => {
    setStatuses(status)
    setPage(0)
    setRouteStatus(status)
  }
  const handleSetInterval = (range?: DateRange) => {
    setRange(range)
    setPage(0)
    setRouteDates(
      range ? `${parseDate(range.from)}/${parseDate(range.to)}` : null
    )
  }
  const clearAllFilters = () => {
    setSearchValue('')
    handleSetStatus([])
    handleSetInterval()
    setPage(0)

    const currentSize = DEFAULT_PAGE_SIZE !== size ? size : null
    const currentSort = !isEqual(DEFAULT_SORT_ORDER, sortOrder)
      ? `${sortOrder.field}_${sortOrder.direction}`
      : null

    if (currentSize && currentSort) {
      return history.replace(`${path}?size=${currentSize}&sort=${currentSort}`)
    }

    if (currentSize) {
      return history.replace(`${path}?size=${currentSize}`)
    }

    if (currentSort) {
      return history.replace(`${path}?sort=${currentSort}`)
    }

    return history.replace(path)
  }

  // Filter props

  const searchFilterProps = {
    setValue: debounce(handleSetSearch, 200),
    value: searchValue,
  }

  const statusFilterProps = {
    setValue: handleSetStatus,
    statuses: Object.values(EmailEventType).map((status) => ({
      label: translate(
        `Emails:RegistersEmailList.filter.statuses.${status}`,
        language
      ),
      status: status,
    })),
    value: statuses,
  }

  const dateIntervalProps = {
    setValue: handleSetInterval,
    value: range,
  }

  const filters = [
    {
      key: 'search',
      label: translate('Emails:RegistersEmailList.filter.search', language),
      onClick: () => handleSetSearch(''),
      value: searchValue,
      visible: !!searchValue,
    },
    {
      key: 'select',
      label: translate('Emails:RegistersEmailList.filter.status', language),
      onClick: () => handleSetStatus([]),
      value: statuses
        .map((status) =>
          translate(
            `Emails:RegistersEmailList.filter.statuses.${status}`,
            language
          )
        )
        .join(', '),
      visible: !!statuses.length,
    },
    {
      key: 'dates',
      label: translate('Emails:RegistersEmailList.filter.dates', language),
      onClick: () => handleSetInterval(),
      value: range
        ? `${moment(range.from).format('YYYY-MM-DD')} - ${moment(
            range.to
          ).format('YYYY-MM-DD')}`
        : '',
      visible: !!range,
    },
  ]

  const sortOptions = [
    getSortOrder(SortDirection.Asc, SortField.SentAt, language),
    getSortOrder(SortDirection.Desc, SortField.SentAt, language),
  ]

  contextValueRef.current = {
    clearAllFilters,
    dateIntervalProps,
    emails,
    error,
    filters,
    handleSetSize,
    handleSetSortOrder,
    loading,
    page,
    searchFilterProps,
    setPage,
    size,
    sortOptions,
    sortOrder,
    statusFilterProps,
  }

  return (
    <RegistersEmailsListContext.Provider value={contextValueRef.current}>
      {children}
    </RegistersEmailsListContext.Provider>
  )
}

export const useRegistersEmailsListContext = () =>
  useContext(RegistersEmailsListContext)
