import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'

import { EmailEventType } from '~generated-types'

import { StatusFilterProps } from '../../../types'

export const StatusSelector = ({
  statuses,
  setValue,
  value,
}: StatusFilterProps) => {
  const toggleValue = (status: EmailEventType) => {
    value.includes(status)
      ? setValue(value.filter((v) => v !== status))
      : setValue([...value, status])
  }

  return (
    <Wrapper>
      {statuses.map((s, idx) => (
        <CheckboxWrapper key={`status-${idx}`}>
          <CheckboxInput
            checked={value.includes(s.status)}
            noMargin
            onChange={() => toggleValue(s.status)}
          >
            <CheckboxLabel>{s.label}</CheckboxLabel>
          </CheckboxInput>
        </CheckboxWrapper>
      ))}
    </Wrapper>
  )
}

////////

const CheckboxWrapper = styled.div`
  height: 18px;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }

  label {
    flex: 1;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const CheckboxLabel = styled(FlexRow)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  border-radius: 4px;
  max-height: 500px;
  overflow: auto;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
