import { useMemo } from 'react'
import moment from 'moment'

import { T, useLanguageContext } from '@/modules/Language'
import { ListingTable } from '@/modules/Listing/common'
import {
  ParticipantForPrint,
  ParticipantsForPrintServiceBed,
} from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { Room, RoomsTableRow } from './RoomsTableRow'

type RoomsById = {
  [roomId: string]: Room
}

type Props = {
  participants: ParticipantForPrint[]
}

export const RoomsTable = ({ participants }: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const { rooms, unassignedRoom } = useMemo(() => {
    const { unassigned, ...restRooms } = participants.reduce(
      (acc: RoomsById, p) => {
        const { __typename, services, ...rest } = p

        const accommodationServices = p.services.filter(
          (s) => s.__typename === 'ServiceParticipantBed'
        ) as ParticipantsForPrintServiceBed[]

        accommodationServices.forEach((s) => {
          const room = s.participantRoom?.roomReservation.request.room ?? null
          const roomId = room?.id ?? 'unassigned'
          const roomNumber = room?.number ?? null
          const dates = {
            checkIn: s.dates?.checkIn.date ?? null,
            checkOut: s.dates?.checkOut.date ?? null,
          }

          const participants = acc[roomId]?.participants ?? []
          const pIndex = acc[roomId]?.participants.findIndex(
            ({ id }) => id === rest.id
          )

          if (pIndex >= 0) {
            participants[pIndex] = {
              dates: [...participants[pIndex].dates, dates],
              ...rest,
            }
          } else {
            participants.push({
              dates: [dates],
              ...rest,
            })
          }

          acc[roomId] = {
            id: roomId,
            number: roomNumber,
            participants,
          }
        })

        if (!accommodationServices.length) {
          acc['unassigned'] = {
            id: 'unassigned',
            number: null,
            participants: [
              ...(acc['unassigned']?.participants ?? []),
              {
                dates: [
                  {
                    checkIn: null,
                    checkOut: null,
                  },
                ],
                ...rest,
              },
            ],
          }
        }

        return acc
      },
      {}
    )

    const sortRoomData = (room: Room) => ({
      ...room,
      participants: room.participants
        .map((participant) => ({
          ...participant,
          dates: participant.dates.sort(
            (a, b) => moment(a.checkIn).valueOf() - moment(b.checkIn).valueOf()
          ),
        }))
        .sort(generateCompareFn(['lastName', 'firstName'])),
    })

    const sortedRooms = Object.values(restRooms)
      .map(sortRoomData)
      .sort((a, b) =>
        (a.number ?? '').localeCompare(b.number ?? '', language, {
          numeric: true,
        })
      )

    return {
      rooms: sortedRooms,
      unassignedRoom: unassigned ? sortRoomData(unassigned) : null,
    }
  }, [language, participants])

  return (
    <ListingTable style={{ marginTop: spacing.gutter }}>
      <thead>
        <tr>
          <th>
            <T>ParticipantsList:ParticipantRooms.room</T>
          </th>
          <th>
            <T>ParticipantsList:excelHeader.name</T>
          </th>
          <th>
            <T>ParticipantsList:excelHeader.accommodationDates</T>
          </th>
        </tr>
      </thead>
      <tbody>
        {rooms.map((room, idx) => (
          <RoomsTableRow isFirst={idx === 0} room={room} key={room.id} />
        ))}

        {unassignedRoom && (
          <RoomsTableRow isFirst={!rooms.length} room={unassignedRoom} />
        )}
      </tbody>
    </ListingTable>
  )
}
