import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Cell, ColumnHeader, Row } from './BaseComponents'
import { ScrollTableProps } from './types'

type Props = ScrollTableProps & {
  children: ReactNode
}

export const PlaceholderRow = ({ children, ...props }: Props) => (
  <Row>
    <ColumnHeader {...props} />
    <PlaceholderCell {...props}>{children}</PlaceholderCell>
  </Row>
)

////////////

const PlaceholderCell = styled(Cell)<{ columnHeaderWidth: string }>`
  border-left: none;
  flex-basis: auto;
  left: ${({ columnHeaderWidth }) => columnHeaderWidth};
  padding: 8px 10px;
  position: sticky;
  width: auto;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
