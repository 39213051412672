import moment from 'moment'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import { ResourceOrigin } from '~generated-types'

import { ContentSection } from '../../Sales/components/SalesDetails/components'
import {
  ContentPlaceholder,
  ListContainerColumn,
  ListRow,
  RowWrapper,
  Section,
  VerticalDivider,
} from '../common'
import { SourceSelect, TemplateSelect } from './components'
import { useGuestCommunicationContext } from './GuestCommunicationState'

export const GuestCommunication = () => {
  const {
    data: { communicationSettings, id },
    saleReadOnly,
  } = salesHooks.useSalesDetailsContext()

  const { setGuestCommunication } = useGuestCommunicationContext()

  const handleResetTemplate = () => {
    setGuestCommunication({
      resetDefault: true,
      salesId: id,
    })
  }

  return (
    <RowWrapper>
      <ContentSection
        titleAlign="center"
        title={<T>GuestCommunication:title</T>}
        headerExtra={
          communicationSettings?.origin === ResourceOrigin.Manual && (
            <InnocuousButton
              compact
              disabled={saleReadOnly}
              onClick={handleResetTemplate}
            >
              <PrimaryColor>
                <T>GuestCommunication:template.reset</T>
              </PrimaryColor>
            </InnocuousButton>
          )
        }
      >
        <ListContainerColumn>
          <FlexRow>
            <Section noPadding flex={2} noLeftMargin>
              <Label>
                <T>GuestCommunication:template.title</T>
              </Label>
            </Section>

            <VerticalDivider />
            <Section noPadding flex={1.4}>
              <Label>
                <T>GuestCommunication:source.title</T>
              </Label>
            </Section>

            <VerticalDivider />
            <Section noPadding flex={1.2} />

            <Section noPadding flex={1} noRightMargin>
              <Label>
                <T>GuestCommunication:lastSync.title</T>
              </Label>
            </Section>
          </FlexRow>
          <ListRow>
            <Section noPadding justify="start" noLeftMargin flex={2}>
              <TemplateSelect
                salesId={id}
                saleCommunicationSettings={communicationSettings}
                saleReadOnly={saleReadOnly}
              />
            </Section>

            <VerticalDivider />
            <Section noPadding justify="start" flex={1.4}>
              <SourceSelect
                salesId={id}
                saleCommunicationSettings={communicationSettings}
                saleReadOnly={saleReadOnly}
              />
            </Section>

            <VerticalDivider />
            <Section noPadding justify="start" flex={1.2} />

            <Section noPadding noRightMargin justify="start" flex={1}>
              {communicationSettings?.lastIntegrationSyncAt ? (
                moment(communicationSettings.lastIntegrationSyncAt).format(
                  'DD.MM.YYYY HH:mm'
                )
              ) : (
                <ContentPlaceholder>
                  - <T>GuestCommunication:lastSync.notSynced</T> -
                </ContentPlaceholder>
              )}
            </Section>
          </ListRow>
        </ListContainerColumn>
      </ContentSection>
    </RowWrapper>
  )
}
