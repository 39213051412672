import { Ref, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uniqBy from 'lodash.uniqby'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Reservation } from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { useTheme } from '@/theme'

import { PaymentStatus, SalesType } from '~generated-types'

import { Button } from '../common'
import { PaymentAgreementModal } from './PaymentAgreementModal'

type Props = {
  reservation: Reservation
}

export const PaymentAgreement = ({ reservation }: Props) => {
  const { participantRooms, sales } = reservation
  const { orders, paymentAgreement, type } = sales

  const { palette } = useTheme()

  const [isOpen, setOpen] = useState<boolean>(false)

  const getColor = (isExists: boolean, isPaid: boolean) =>
    isExists
      ? isPaid
        ? palette.success.dark
        : palette.danger.dark
      : palette.smoke.extraDark

  const chipColor = useMemo(() => {
    const isOrdersPaid = orders.every(
      ({ paymentInfo }) => paymentInfo.status === PaymentStatus.Paid
    )

    if (type === SalesType.Sales) {
      return getColor(!!orders.length, isOrdersPaid)
    }

    const internalOrders = participantRooms
      .map((r) => r.participant.sales.orders)
      .flat()

    const isInternalOrdersPaid = internalOrders.every(
      ({ paymentInfo }) => paymentInfo.status === PaymentStatus.Paid
    )

    return getColor(
      !!orders.length || !!internalOrders.length,
      isOrdersPaid && isInternalOrdersPaid
    )
  }, [orders, participantRooms, type])

  const participantsSales = useMemo(() => {
    if (type === SalesType.Sales) {
      return []
    }

    const allSales = participantRooms.map((r) => r.participant.sales)

    return uniqBy(allSales, 'id')
  }, [participantRooms, type])

  const renderEventPaymentAgreement = () => {
    const allPaymentAgreements = [...participantsSales, sales]
      .map(({ paymentAgreement }) => paymentAgreement)
      .filter(Boolean)

    if (!allPaymentAgreements.length) {
      return null
    }

    if (allPaymentAgreements.length === 1) {
      return <PaymentCode>{allPaymentAgreements[0]?.code}</PaymentCode>
    }

    const uniqPaymentAgreements = uniqBy(allPaymentAgreements, 'id')

    if (uniqPaymentAgreements.length === 1) {
      return <PaymentCode>{uniqPaymentAgreements[0]?.code}</PaymentCode>
    }

    return (
      <FontAwesomeIcon
        color={palette.warning.darker}
        icon="cubes"
        size="sm"
        style={{ marginTop: 5 }}
      />
    )
  }

  const renderSalesPaymentAgreement = () =>
    paymentAgreement && <PaymentCode>{paymentAgreement.code}</PaymentCode>

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={<PaymentAgreementModal reservation={reservation} />}
      onClose={() => setOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Button
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setOpen(true)}
          width="35px"
        >
          <FlexColumn>
            <FontAwesomeIcon color={chipColor} icon="euro-sign" />
            {type === SalesType.Sales
              ? renderSalesPaymentAgreement()
              : renderEventPaymentAgreement()}
          </FlexColumn>
        </Button>
      )}
    />
  )
}

///////

const PaymentCode = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.warning.darker};
    margin-top: 4px;
  `};
`
