import { gql } from '@apollo/client'

import reservationStatus from './reservationStatus'

export default gql`
  ${reservationStatus}

  fragment CapacityStatus on TargetCapacityStatus {
    status {
      ...ReservationStatus
    }
    dailyStatus {
      date
      status {
        ...ReservationStatus
      }
    }
  }
`
