import { useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import { ListingRoomFeatureFragment } from '@/modules/Listing/fragments'

import {
  RoomStatusListingQuery as QueryData,
  RoomStatusListingQueryVariables as QueryVariables,
} from '~generated-types'

import { RoomReservation } from './types'

const QUERY = gql`
  ${ListingRoomFeatureFragment}

  fragment RoomStatusListingSales on Sales {
    customer {
      customer {
        customerNumber
        id

        ... on CustomerOrganization {
          organization {
            businessId
            name
          }
        }

        ... on CustomerPerson {
          person {
            firstName
            lastName
          }
        }
      }
    }
    id
    name
    orderNumber
    type
  }

  fragment RoomStatusListingParticipantRooms on ParticipantRoom {
    id
    participant {
      accommodationRequest
      additionalInfo
      age
      ageCategory {
        key
        name
        shortName
      }
      birthday {
        date
        month
        year
      }
      firstName
      gender
      id
      lastName
      nationality
      sales {
        ...RoomStatusListingSales
      }
    }
    request {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      info
    }
  }

  fragment RoomStatusListingRequest on RoomRequest {
    beds
    checkIn {
      date
      type
    }
    checkOut {
      date
      type
    }
    extraBeds
    features {
      ...ListingRoomFeature
    }
    info
    room {
      id
    }
  }

  fragment RoomStatusListingRoom on Room {
    beds
    building {
      id
      name
    }
    extraBeds
    id
    number
    status {
      cleaningStatus
    }
  }

  fragment RoomStatusListingRoomReservation on RoomReservation {
    id
    participantRooms {
      ...RoomStatusListingParticipantRooms
    }
    request {
      ...RoomStatusListingRequest
    }
  }

  query RoomStatusListing($input: ReservationSearchInput!) {
    registry {
      rooms {
        ...RoomStatusListingRoom
      }
    }
    accommodationRoomReservations(input: $input) {
      arriving {
        ...RoomStatusListingRoomReservation
      }
      departing {
        ...RoomStatusListingRoomReservation
      }
      present {
        ...RoomStatusListingRoomReservation
      }
    }
  }
`

interface Props {
  date: Moment
}

interface Hook {
  error: boolean
  loading: boolean
  roomReservations: RoomReservation[]
}

const useRoomStatuses = ({ date }: Props): Hook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        date: date.format('YYYY-MM-DD'),
      },
    },
  })

  const roomReservations = useMemo(() => {
    if (!data) {
      return []
    }

    const arriving = data.accommodationRoomReservations.arriving
    const departing = data.accommodationRoomReservations.departing
    const present = data.accommodationRoomReservations.present

    return data.registry.rooms.map((room): RoomReservation => {
      const arrivingReservation =
        arriving.find(({ request }) => request.room.id === room.id) ?? null
      const departingReservation =
        departing.find(({ request }) => request.room.id === room.id) ?? null
      const presentReservation =
        present.find(({ request }) => request.room.id === room.id) ?? null

      return {
        arrivingReservation,
        departingReservation,
        presentReservation,
        room,
      }
    })
  }, [data])

  return {
    error: !!error,
    loading,
    roomReservations,
  }
}

export default useRoomStatuses
