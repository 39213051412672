import { gql } from '@apollo/client'

export default gql`
  fragment ParticipantVst on ParticipantVst {
    end
    included
    lesson {
      fee
      hours
    }
    state
    start
    vstDays
  }
`
