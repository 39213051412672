import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  SalesParticipantSetRoomFriendsMutation,
  SalesParticipantSetRoomFriendsMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantSetRoomFriendsMutation,
  SalesParticipantSetRoomFriendsMutationVariables,
}

export const SALES_PARTICIPANT_SET_ROOM_FRIENDS = gql`
  ${participantFragments.participant}

  mutation SalesParticipantSetRoomFriends(
    $input: SalesParticipantSetRoomFriendsInput!
  ) {
    salesParticipantSetRoomFriends(input: $input) {
      ...Participant
    }
  }
`

export function useSalesParticipantSetRoomFriends() {
  return useMutation<
    SalesParticipantSetRoomFriendsMutation,
    SalesParticipantSetRoomFriendsMutationVariables
  >(SALES_PARTICIPANT_SET_ROOM_FRIENDS)
}

export default useSalesParticipantSetRoomFriends
