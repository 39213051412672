import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'

import { salesHooks } from '@/modules/Sales/hooks'

import { useEmailsSearch } from './hooks'
import { EmailsSearch } from './types'

type Pagination = Omit<EmailsSearch, 'nodes' | '__typename'> & {
  currentPage: number
}

type ContextType = {
  error?: ApolloError
  loading: boolean
  emails?: EmailsSearch
  handleSwitchPage: (pageNumber: number) => void
  setPageSize: (size: number) => void
  pagination: Pagination
  pageSize: number
  setShowAllEmails: (arg: boolean) => void
  showAllEmails: boolean
}

const EmailsListContext = createContext<ContextType>({
  emails: undefined,
  error: undefined,
  handleSwitchPage: () => undefined,
  loading: false,
  pageSize: 0,
  pagination: {
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  },
  setPageSize: () => undefined,
  setShowAllEmails: () => undefined,
  showAllEmails: false,
})

export const EmailsListContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const {
    data: { id: salesId },
  } = salesHooks.useSalesDetailsContext()

  const storedCurrentPage: number | null = JSON.parse(
    sessionStorage.getItem(`salesEmailsCurrentPage-${salesId}`) || 'null'
  )

  const storedPageSize: number | null = JSON.parse(
    sessionStorage.getItem(`salesEmailsPageSize-${salesId}`) || 'null'
  )

  const storedPagination = {
    currentPage: storedCurrentPage ?? 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  }

  const [pagination, setPagination] = useState<Pagination>(storedPagination)
  const [pageSize, setPageSizeState] = useState(storedPageSize ?? 5)
  const [showAllEmails, setShowAllEmails] = useState<boolean>(false)

  const { emails, error, loading } = useEmailsSearch({
    filter: { salesId },
    pagination: {
      page: showAllEmails ? 0 : pagination.currentPage,
      size: showAllEmails ? 1000 : pageSize,
    },
  })

  useEffect(() => {
    if (error || !emails) return

    const { totalPages, totalElements, hasNextPage, hasPreviousPage } = emails

    setPagination({
      currentPage:
        pagination.currentPage > totalPages - 1 ? 0 : pagination.currentPage,
      hasNextPage,
      hasPreviousPage,
      totalElements,
      totalPages,
    })
  }, [emails])

  const handleSwitchPage = (pageNumber: number) => {
    if (!pagination) return

    setPagination({
      ...pagination,
      currentPage: pageNumber,
    })

    sessionStorage.setItem(
      `salesEmailsCurrentPage-${salesId}`,
      JSON.stringify(pageNumber)
    )
  }

  const setPageSize = (size: number) => {
    setPageSizeState(size)

    sessionStorage.setItem(
      `salesEmailsPageSize-${salesId}`,
      JSON.stringify(size)
    )
  }

  contextValueRef.current = {
    emails,
    error,
    handleSwitchPage,
    loading,
    pageSize,
    pagination,
    setPageSize,
    setShowAllEmails,
    showAllEmails,
  }

  return (
    <EmailsListContext.Provider value={contextValueRef.current}>
      {children}
    </EmailsListContext.Provider>
  )
}

export const useEmailsListContext = () => useContext(EmailsListContext)
