import { gql } from '@apollo/client'

export default gql`
  query SuitableParticipants($id: ID!, $input: SuitableParticipantsInput) {
    accommodationRoomReservation(id: $id) {
      id
      suitableParticipants(input: $input) {
        labels
        participant {
          id
          age
          ageCategory {
            abbreviation
            key
            name
          }
          ageSource
          birthday {
            year
            month
            date
          }
          firstName
          lastName
          gender
          services {
            id
            ... on ServiceParticipantBed {
              participantRoom {
                id
                roomReservation {
                  id
                  request {
                    room {
                      id
                      number
                    }
                  }
                }
              }
            }
          }
          sales {
            id
          }
        }
      }
    }
  }
`
