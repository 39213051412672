import { useMemo } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import { DimensionLabelValidationCategory } from '~generated-types'

import { SalesDimension } from '../../../types'
import { ValueReadOnly } from './ValueReadOnly'

type Props = {
  dimension: SalesDimension
}

export const DimensionPreview = ({
  dimension: { dimension, selectedLabel, validatedOptions },
}: Props) => {
  const isInvalid = useMemo(
    () =>
      selectedLabel?.id
        ? !validatedOptions.find(
            (l) =>
              l.validationCategory === DimensionLabelValidationCategory.Valid &&
              l.label.id === selectedLabel.id
          )
        : dimension.required,
    [dimension, selectedLabel, validatedOptions]
  )

  return (
    <Wrapper>
      <ValueReadOnly
        label={selectedLabel?.name || ''}
        placeholder={dimension.name || '–'}
        isInvalid={isInvalid}
      />
    </Wrapper>
  )
}

//////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem;
  `}
`
