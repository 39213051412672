import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridOptions } from 'ag-grid-community'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexRow, Gutter } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'

import { AgGrid } from './AgGrid'

type Props<T> = Omit<GridOptions, 'rowData'> & {
  error?: boolean
  rowData: T[]
  title: string
}

export const AgGridSearchList = <T,>({ title, ...props }: Props<T>) => {
  const { language } = useLanguageContext()

  const [searchValue, setSearchValue] = useState<string>('')

  return (
    <Wrapper type={[3, 5, 10]}>
      <TitleWrapper alignItems="center" justifyContent="space-between">
        <Title>{title}</Title>

        <SearchInput alignItems="center">
          <SearchIconWrapper justifyContent="center" flex="none">
            <SearchIcon icon="magnifying-glass" />
          </SearchIconWrapper>

          <Input
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder={translate('AgGrid:search', language)}
            value={searchValue}
          />
        </SearchInput>
      </TitleWrapper>

      <AgGridWrapper>
        <AgGrid {...props} quickFilterText={searchValue} />
      </AgGridWrapper>
    </Wrapper>
  )
}

///////
const AgGridWrapper = styled.div`
  flex: 1;
`

const SearchIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const SearchIconWrapper = styled(FlexRow)`
  position: absolute;
  width: 30px;
  margin-left: 2px;
`

const SearchInput = styled(FlexRow)`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      padding-left: 30px;

      ${({ theme }) => css`
        width: ${theme.spacing.gu(32)}rem;
      `}

      &::placeholder {
        font-style: italic;

        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`

const Wrapper = styled(Gutter)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: auto;
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
