import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { GenderFilterType } from '@/modules/ParticipantsList/types'

import { Gender } from '~generated-types'

import { CheckboxLabel, CheckboxWrapper, Divider } from './common'

type Props = {
  filters: GenderFilterType[] | null
  setFilters: (filters: GenderFilterType[] | null) => void
}

export const GenderFilter = ({ filters, setFilters }: Props) => {
  const { language } = useLanguageContext()

  const isEmptySelected = !!(filters?.length === 0)

  const handleSelect = (targetFilter: GenderFilterType) => {
    if (!filters) {
      return setFilters([targetFilter])
    }

    const newFilters = !!filters.find((f) => f.value === targetFilter.value)
      ? filters.filter((f) => f.value !== targetFilter.value)
      : [...filters, targetFilter]

    return setFilters(newFilters.length ? newFilters : null)
  }

  return (
    <FlexColumn noPadding>
      {Object.values(Gender).map((value) => (
        <CheckboxWrapper alignItems="center" key={value}>
          <CheckboxInput
            checked={!!filters?.find((v) => v.value === value)}
            noMargin
            onChange={() =>
              handleSelect({
                label: translate(`enums:gender.${value}`, language),
                value,
              })
            }
          >
            <CheckboxLabel>
              {translate(`enums:gender.${value}`, language)}
            </CheckboxLabel>
          </CheckboxInput>
        </CheckboxWrapper>
      ))}

      <Divider />

      <CheckboxWrapper alignItems="center">
        <CheckboxInput
          checked={isEmptySelected}
          noMargin
          onChange={() => setFilters(isEmptySelected ? null : [])}
        >
          <CheckboxLabel>
            <T>ParticipantsList:filters.empty.genders</T>
          </CheckboxLabel>
        </CheckboxInput>
      </CheckboxWrapper>
    </FlexColumn>
  )
}
