import { ReactNode } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

import { FlexColumn, FlexRow, Gutter } from '@/components/Layout'
import { P } from '@/components/Typography'

// List components

export const ListWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`
export const RowWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(4)}rem;
  `}
`
export const ListContainerRow = styled(FlexRow)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};

    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1)}rem;
  `}
`
export const ListContainerColumn = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1.5)}rem;
  `}
`
export const ListRow = styled(FlexRow)<{ noBorder?: boolean }>`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1.5)}rem;
    padding-top: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ noBorder, theme }) =>
    !noBorder &&
    css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
`
export const Section = styled(FlexColumn)<{
  noRightMargin?: boolean
  noMargin?: boolean
  noLeftMargin?: boolean
  minWidth?: string
  justify?: string
}>`
  ${({
    theme,
    noMargin,
    noLeftMargin,
    noRightMargin,
    minWidth,
    justify,
  }) => css`
    justify-content: ${justify ? justify : 'center'};

    ${noMargin
      ? `margin: 0`
      : noLeftMargin
        ? `margin: 0 ${theme.spacing.gu(2)}rem 0 0;`
        : noRightMargin
          ? `margin: 0 0 0 ${theme.spacing.gu(2)}rem ;`
          : `margin: 0 ${theme.spacing.gu(2)}rem;`};

    ${minWidth ? `${minWidth}` : ``};
  `};
`
export const VerticalDivider = styled.div<{ isTransparent?: boolean }>`
  height: 100%;
  min-height: 15px;
  width: 1px;

  ${({ theme, isTransparent }) => css`
    background: ${isTransparent ? 'transparent' : theme.palette.smoke.light};
  `}
`
export const HorizontalDivider = styled.div<{ isTransparent?: boolean }>`
  height: 1px;
  width: 100%;

  ${({ theme, isTransparent }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
    background: ${isTransparent ? 'transparent' : theme.palette.smoke.light};
  `}
`
export const ContentPlaceholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
export const HelperText = styled.span`
  white-space: pre-line;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
    color: ${theme.palette.text.lighter};
    min-height: ${theme.spacing.gu(7)}rem;
  `};
`
export const StyledSpinner = styled(ReactLoading)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem auto;
  `}
`
export const MessagePlaceholder = styled.div`
  width: 100%;
  text-align: center;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
  `}
`

// Placeholder

const Label = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

export const ListPlaceholder = ({ children }: { children: ReactNode }) => (
  <Gutter type={[2, 3]}>
    <Label>{children}</Label>
  </Gutter>
)

// Truncate content on multiple lines

const ContentWrapper = styled.span`
  display: grid;
  flex: auto;
`
const Truncate = styled.span<{ linesToShow?: number }>`
  display: -webkit-box;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;

  ${({ theme, linesToShow }) => css`
    -webkit-line-clamp: ${linesToShow || 1};
    line-height: ${theme.typography.fontSizeBig};
  `};
`

export const TruncateContent = ({
  content,
  linesToShow,
}: {
  content: ReactNode | string
  linesToShow?: number
}) => {
  return (
    <ContentWrapper>
      <Truncate linesToShow={linesToShow}>{content}</Truncate>
    </ContentWrapper>
  )
}
