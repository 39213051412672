import { gql } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

export default gql`
  ${accommodationFragments.accommodationTarget}

  query TargetById($id: ID!) {
    accommodationTarget(id: $id) {
      ...AccommodationTarget
    }
  }
`
