import { Fragment } from 'react'
import styled, { css } from 'styled-components/macro'

import { T, useLanguageContext } from '@/modules/Language'
import { ParticipantForPrint } from '@/modules/ParticipantsList/types'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

import { Group } from './ParticipantsGroupTable'

export type ParticipantService = {
  dates: {
    checkIn: string | null
    checkOut: string | null
  }[]
  roomId: string
  roomNumber: string | null
}

type Participant = Omit<ParticipantForPrint, 'services' | '__typename'> & {
  services: ParticipantService[]
}

type Props = {
  group: Group
  isFirst: boolean
  pageBreak: boolean
}

export const ParticipantsGroupTableRow = ({
  group: { group, participants },
  isFirst,
  pageBreak,
}: Props) => {
  const { language } = useLanguageContext()

  return (
    <>
      {participants
        .sort((a, b) => participantsCompareFn(a, b, language))
        .map((p, idx) => {
          const groupRowSpan = participants.reduce(
            (acc, p) =>
              acc +
              (p.services.length > 0
                ? p.services.reduce((acc, s) => acc + s.dates.length, 0)
                : 1),
            0
          )

          const { firstName, id, lastName, services } = p

          const pageBreakBefore = pageBreak && idx === 0 ? 'always' : 'inherit'

          if (!services.length) {
            return (
              <tr
                key={`participant-row-${id}`}
                className={idx === 0 ? '' : 'extra-row'}
                style={{
                  pageBreakBefore,
                  pageBreakInside: isFirst ? 'auto' : 'avoid',
                }}
              >
                {idx === 0 && (
                  <td rowSpan={groupRowSpan}>
                    {group === 'unassigned' ? (
                      <Placeholder>
                        <T>ParticipantsList:ParticipantRooms.groupUnassigned</T>
                      </Placeholder>
                    ) : (
                      group
                    )}
                  </td>
                )}
                <td>{getParticipantName(firstName, lastName)}</td>
                <td>–</td>
                <td style={{ borderRightWidth: 1 }}>–</td>
              </tr>
            )
          }

          const participantRowSpan = services.reduce(
            (acc, s) => acc + s.dates.length,
            0
          )

          const [firstService, ...restServices] = services
          const [firstServiceFirstDate, ...firstServiceRestDates] =
            firstService.dates

          return (
            <Fragment key={`participant-group-row-${p.id}`}>
              <tr
                className={idx === 0 ? '' : 'extra-row'}
                style={{
                  pageBreakBefore,
                  pageBreakInside: isFirst ? 'auto' : 'avoid',
                }}
              >
                {idx === 0 && (
                  <td rowSpan={groupRowSpan}>
                    {group === 'unassigned' ? (
                      <Placeholder>
                        <T>ParticipantsList:ParticipantRooms.groupUnassigned</T>
                      </Placeholder>
                    ) : (
                      group
                    )}
                  </td>
                )}
                <td rowSpan={participantRowSpan}>
                  {getParticipantName(firstName, lastName)}
                </td>

                <td rowSpan={firstService.dates.length}>
                  {firstService.roomNumber ?? '–'}
                </td>
                <td style={{ borderRightWidth: 1 }}>
                  {formatDate(
                    firstServiceFirstDate.checkIn,
                    firstServiceFirstDate.checkOut,
                    'short'
                  ) || '–'}
                </td>
              </tr>

              {firstServiceRestDates.map(({ checkIn, checkOut }, idx) => (
                <tr className="extra-row" key={`date-row-${id}-${idx}`}>
                  <td style={{ borderRightWidth: 1 }}>
                    {formatDate(checkIn, checkOut, 'short') || '–'}
                  </td>
                </tr>
              ))}

              {restServices.map(({ dates, roomId, roomNumber }) => {
                const [firstDate, ...restDates] = dates

                return (
                  <Fragment key={`room-row-${roomId}`}>
                    <tr className="extra-row">
                      <td rowSpan={dates.length}>{roomNumber ?? '–'}</td>
                      <td style={{ borderRightWidth: 1 }}>
                        {formatDate(
                          firstDate.checkIn,
                          firstDate.checkOut,
                          'short'
                        ) || '–'}
                      </td>
                    </tr>

                    {restDates.map(({ checkIn, checkOut }) => (
                      <tr className="extra-row" key={`date-row-${roomId}`}>
                        <td style={{ borderRightWidth: 1 }}>
                          {formatDate(checkIn, checkOut, 'short') || '–'}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                )
              })}
            </Fragment>
          )
        })}
    </>
  )
}

///////

const getParticipantName = (firstName: string, lastName: string) =>
  firstName || lastName ? (
    `${lastName}${firstName && lastName ? ', ' : ''}${firstName}`
  ) : (
    <Placeholder>
      <T>ParticipantsList:unnamedParticipant</T>
    </Placeholder>
  )

const participantsCompareFn = (
  a: Participant,
  b: Participant,
  language: string
) => {
  return a.firstName.length === 0 && b.firstName.length !== 0
    ? 1
    : b.firstName.length === 0 && a.firstName.length !== 0
      ? -1
      : a.firstName.localeCompare(b.firstName, language, { numeric: true })
}

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
