import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { PaymentReservationFeeDetails as ReservationFeeDetailsType } from '@/modules/Order/types'

import { SectionWithTooltip } from '../../common'

type Props = {
  details: ReservationFeeDetailsType
}

export const ReservationFeeDetails = ({
  details: { reservationFee },
}: Props) => (
  <SectionWithTooltip
    content={reservationFee.invoiceNumber || <FontColor lighter>–</FontColor>}
    flex={1}
    sign="#"
    title={<T>Orders:Payments.field.invoiceNumber</T>}
  />
)
