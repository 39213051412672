import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'

import { useRoomReservationsContext } from '../../../../DailyRoomReservationsState'
import { Reservation, ReservationType } from '../../../../types'
import { Placeholder, Section } from '../common'
import {
  CheckStatus,
  Dates,
  Features,
  Notes,
  Participants,
  PaymentAgreement,
  Sales,
} from './components'

type Props = {
  flex: number
  isLast?: boolean
  reservation: Reservation | null
  type: ReservationType
}

export const ReservationSection = ({
  flex,
  isLast,
  reservation,
  type,
}: Props) => {
  const { targetId } = useRoomReservationsContext()

  if (!reservation) {
    return (
      <Section
        alignItems="center"
        flex={flex}
        justifyContent="center"
        isLastRight={isLast}
      >
        <Placeholder>
          <T>{`FrontDesk:DailyRoomReservations.placeholder.${type}`}</T>
        </Placeholder>
      </Section>
    )
  }

  const { id, participantRooms, request, sales } = reservation

  return (
    <Section
      alignItems="center"
      flex={flex}
      highlight={id === targetId}
      isLastRight={isLast}
    >
      <Dates request={request} />

      <Separator />

      <Participants participantRooms={participantRooms} id={id} />

      <Separator />

      <CheckStatus reservation={reservation} type={type} />

      <Separator />

      <Sales sales={sales} />

      <Separator />

      <PaymentAgreement reservation={reservation} />

      <Separator />

      <Notes reservation={reservation} />

      {type === ReservationType.Arrivinng && (
        <>
          <Separator />
          <Features features={request.features} />
        </>
      )}
    </Section>
  )
}

////////////

const Separator = styled.div`
  width: 1px;
  height: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.lighter};
  `};
`
