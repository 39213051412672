import { gql, useMutation } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

import {
  RoomReservationDeleteMutation,
  RoomReservationDeleteMutationVariables,
} from '~generated-types'

export type {
  RoomReservationDeleteMutation,
  RoomReservationDeleteMutationVariables,
}

export const ROOM_RESERVATION_DELETE = gql`
  ${accommodationFragments.accommodationSettings}

  mutation RoomReservationDelete(
    $input: AccommodationRoomReservationDeleteInput!
  ) {
    accommodationRoomReservationDelete(input: $input) {
      deleted
      id
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export function useRoomReservationDelete() {
  return useMutation<
    RoomReservationDeleteMutation,
    RoomReservationDeleteMutationVariables
  >(ROOM_RESERVATION_DELETE)
}

export default useRoomReservationDelete
