import { useQuery } from '@apollo/client'

import type {
  DimensionShortcutsQuery as QueryData,
  DimensionShortcutsQueryVariables as QueryVariables,
} from '~generated-types'

import { dimensionQueries } from '../queries'
import { DimensionShortcut } from '../types'

type Props = {
  salesId: string
}

export const useDimensionShortcuts = ({ salesId }: Props) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    dimensionQueries.GET_DIMENSION_SHORTCUTS,
    { variables: { salesId } }
  )

  const dimensionShortcuts: DimensionShortcut[] =
    data?.registry.dimensionShortcuts || []

  return {
    dimensionShortcuts,
    error,
    loading,
    refetch,
  }
}
