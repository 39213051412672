import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { DefaultFilterType } from '@/modules/ParticipantsList/types'
import { ageCategoryHooks } from '@/modules/Registry/AgeCategory'
import { generateCompareFn } from '@/utils/arrays'

import { FilterContainer } from '../FilterContainer'
import { CheckboxLabel, CheckboxWrapper, Divider, Placeholder } from './common'

type Props = {
  filters: DefaultFilterType[] | null
  setFilters: (filters: DefaultFilterType[] | null) => void
}

export const AgeCategoryFilter = ({ filters, setFilters }: Props) => {
  const { ageCategories, loading } = ageCategoryHooks.useAgeCategories()

  const isEmptySelected = !!(filters?.length === 0)

  const handleSelect = (targetFilter: DefaultFilterType) => {
    if (!filters) {
      return setFilters([targetFilter])
    }

    const newFilters = !!filters.find((f) => f.value === targetFilter.value)
      ? filters.filter((f) => f.value !== targetFilter.value)
      : [...filters, targetFilter]

    return setFilters(newFilters.length ? newFilters : null)
  }

  return (
    <FilterContainer isLoading={loading}>
      <FlexColumn noPadding>
        {!!ageCategories.length ? (
          <>
            {[...ageCategories]
              .sort(generateCompareFn('sortOrder'))
              .map(({ key, name }) => (
                <CheckboxWrapper alignItems="center" key={key}>
                  <CheckboxInput
                    checked={!!filters?.find((v) => v.value === key)}
                    noMargin
                    onChange={() => handleSelect({ label: name, value: key })}
                  >
                    <CheckboxLabel>{name}</CheckboxLabel>
                  </CheckboxInput>
                </CheckboxWrapper>
              ))}

            <Divider />

            <CheckboxWrapper alignItems="center">
              <CheckboxInput
                checked={isEmptySelected}
                noMargin
                onChange={() => setFilters(isEmptySelected ? null : [])}
              >
                <CheckboxLabel>
                  <T>ParticipantsList:filters.empty.ageCategoryIds</T>
                </CheckboxLabel>
              </CheckboxInput>
            </CheckboxWrapper>
          </>
        ) : (
          <Placeholder>
            <T>ParticipantsList:filters.noAgeCategories</T>
          </Placeholder>
        )}
      </FlexColumn>
    </FilterContainer>
  )
}
