import { gql } from '@apollo/client'

export default gql`
  query SpecialDietaries {
    registry {
      allergies {
        name
      }
    }
  }
`
