import { CSSProperties, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ControlProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { useLanguageContext } from '@/modules/Language'
import { participantQueries } from '@/modules/ParticipantsList/queries'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  LanguagesRegistryQuery,
  LanguagesRegistryQueryVariables,
} from '~generated-types'

type Props = {
  isDisabled?: boolean
  isLoading?: boolean
  handleSetLanguage: (language: string | null) => void
  participantLanguage: string
  title?: string
}

export const LanguageSelector = ({
  isDisabled,
  isLoading,
  handleSetLanguage,
  participantLanguage,
  title,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { language } = useLanguageContext()

  const [loadLanguages, { data }] = useLazyQuery<
    LanguagesRegistryQuery,
    LanguagesRegistryQueryVariables
  >(participantQueries.LANGUAGES_REGISTRY, {
    fetchPolicy: 'cache-first',
    variables: { locale: language },
  })

  useEffect(() => {
    loadLanguages()
  }, [])

  const options = data
    ? [...data.registry.registryLanguages]
        .sort(generateCompareFn('name'))
        .map(({ name, code }) => ({
          label: name,
          searchValue: name,
          value: code,
        }))
    : []

  const extraStyles = {
    container: (styles: CSSProperties) => ({
      ...styles,
      fontWeight: 400,
      marginBottom: spacing.gutter,
      width: '100%',
    }),
    control: (
      styles: CSSProperties,
      { isDisabled, isFocused }: ControlProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      background: isDisabled ? palette.smoke.lighter : 'transparent',
      borderColor: isFocused ? palette.primary.main : palette.smoke.dark,
      cursor: 'pointer',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
  }

  return (
    <FlexColumn style={{ width: '100%' }}>
      <Title>{title}</Title>
      <ThemedSelect
        isClearable
        isDisabled={isDisabled}
        isLoading={isLoading}
        extraStyles={extraStyles}
        name="language-selector"
        onChange={(selected: Option | null | undefined) =>
          handleSetLanguage(selected ? selected.value : null)
        }
        options={options}
        isSearchable
        placeholder=""
        value={
          options.find(({ value }) => value === participantLanguage) || null
        }
      />
    </FlexColumn>
  )
}

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
