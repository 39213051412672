import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { participantQueries } from '@/modules/ParticipantsList/queries'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import {
  SpecialDietariesQuery,
  SpecialDietariesQueryVariables,
} from '~generated-types'

import DietValueWrapper from './DietValueWrapper'

type Props = {
  specialDietaryNeeds: string[]
  handleUpdateDietaryNeeds: (
    dietaryNeeds: string[]
  ) => Promise<FetchedParticipant | undefined>
}

const SpecialDietary = ({
  specialDietaryNeeds,
  handleUpdateDietaryNeeds,
}: Props) => {
  const { spacing } = useTheme()

  const [diets, setDiets] = useState(specialDietaryNeeds)

  const { data } = useQuery<
    SpecialDietariesQuery,
    SpecialDietariesQueryVariables
  >(participantQueries.SPECIAL_DIETARIES, {
    fetchPolicy: 'cache-first',
  })

  const dietaryOptions =
    data?.registry.allergies
      .map(({ name }) => name)
      .filter((diet) => !diets.find((d) => d === diet)) || []

  return (
    <FlexColumn style={{ marginTop: spacing.gutter }}>
      {diets.map((diet, index) => (
        <DietValueWrapper
          key={diet}
          value={diet}
          dietaryOptions={dietaryOptions}
          placeholder={<T>ParticipantsList:PersonalFields.addSpecialDietary</T>}
          onRemove={() => {
            const dietsList = diets.filter((d) => d !== diet)
            setDiets(dietsList)
            return handleUpdateDietaryNeeds(dietsList)
          }}
          onAdd={(dietValue) => {
            const dietsList = diets.map((diet, i) =>
              i === index ? dietValue : diet
            )
            setDiets(dietsList)
            return handleUpdateDietaryNeeds(dietsList)
          }}
        />
      ))}
      <DietValueWrapper
        value={null}
        dietaryOptions={dietaryOptions}
        placeholder={<T>ParticipantsList:PersonalFields.addSpecialDietary</T>}
        onRemove={() => Promise.reject()}
        onAdd={(dietValue) => {
          const dietsList = [...diets, dietValue]
          setDiets(dietsList)
          return handleUpdateDietaryNeeds(dietsList)
        }}
      />
    </FlexColumn>
  )
}

export default SpecialDietary
