import { useMemo } from 'react'

import { FlexRow } from '@/components/Layout'
import { useParticipantsListContext } from '@/modules/ParticipantsList/ParticipantsListState'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'

import { Education } from './Education'
import { Language } from './Language'
import { LifeStage } from './LifeStage'
import { Year } from './Year'

type Props = {
  selectedIds: string[]
}

export const VSTDetails = ({ selectedIds }: Props) => {
  const { participants } = useParticipantsListContext()

  const selectedParticipants: FetchedParticipant[] = useMemo(
    () =>
      participants.filter(({ id }) =>
        selectedIds.find((selectedId) => selectedId === id)
      ),
    [selectedIds, participants]
  )

  return (
    <FlexRow style={{ alignItems: 'unset', height: '35px' }}>
      <Year selectedParticipants={selectedParticipants} />
      <Education selectedParticipants={selectedParticipants} />
      <Language selectedParticipants={selectedParticipants} />
      <LifeStage selectedParticipants={selectedParticipants} />
    </FlexRow>
  )
}
