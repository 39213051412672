import { useState } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components/macro'

import { useKeycloakContext } from '@/config/keycloak'
import { translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { NavSortBy } from '~generated-types'

import {
  NavDropdownExternalItem,
  NavDropdownInternalItem,
  NavDropdownItemType,
  NavItem,
} from '../../../types'
import { ReactComponent as UpArrow } from './arrow-up-with.svg'
import { NavbarDropdownButton } from './NavbarDropdownButton'
import { NavbarDropdownToggle } from './NavbarDropdownToggle'
import { DropdownNavItem } from './types'

type Props = {
  item: NavItem
}

export const NavbarButton = ({ item: { items, key, sort } }: Props) => {
  const { pathname } = useLocation()
  const { language } = useLanguageContext()
  const { hasNavViewAccess } = useKeycloakContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const dropdownItems: DropdownNavItem[] = items.map((item) => {
    const externalItem = item as NavDropdownExternalItem
    const internalItem = item as NavDropdownInternalItem

    return item.type === NavDropdownItemType.InternalItem
      ? {
          isEmbedded: !internalItem.isExternalUrl,
          key: internalItem.key,
          label: translate(
            `Navigation:navDropdownItem.${internalItem.key}`,
            language
          ),
          openInNewTab: internalItem.openInNewTab,
          to: internalItem.to,
        }
      : {
          isEmbedded: externalItem.isEmbedded,
          key: externalItem.key,
          label: externalItem.name,
          openInNewTab: externalItem.openInNewTab,
          to: externalItem.isEmbedded ? externalItem.to : externalItem.url,
        }
  })

  const sortedDropdownItems =
    sort === NavSortBy.Name
      ? dropdownItems.sort(generateCompareFn('label'))
      : dropdownItems

  const activeItem = dropdownItems.find(({ to }) => {
    const match = pathname.match(/^\/[^/]+\/[^/]+/gm)

    return match && to === match[0]
  })

  return (
    <Wrapper className={classNames({ 'is-open': isOpen })}>
      <NavbarDropdownToggle
        activeItem={activeItem}
        dropdownItems={dropdownItems}
        hasAccess={items.some(({ key }) => hasNavViewAccess(key))}
        itemKey={key}
        setIsOpen={setIsOpen}
      />

      {!!sortedDropdownItems.length && (
        <Dropdown>
          <StyledUpArrow />

          {sortedDropdownItems.map((item, idx) => (
            <NavbarDropdownButton
              hasAccess={hasNavViewAccess(item.key)}
              isActive={activeItem?.to === item.to}
              item={item}
              key={`navDropdownItem-${item.key}-${idx}`}
            />
          ))}
        </Dropdown>
      )}
    </Wrapper>
  )
}

////////////

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  80% {
    opacity: 1;
  }
  to {
    transform: translateY(0);
  }
`

const Dropdown = styled.ul`
  display: none;
  position: absolute;
  z-index: 5000;
  left: 0;
  top: calc(100% + -2px);
  margin: 0;
  padding: 2px;
  list-style: none;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.14);
  animation: ${fadeUp} 0.1s ease-out both;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const Wrapper = styled.li`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;

  &.is-open ${Dropdown} {
    display: block;
  }
`

const StyledUpArrow = styled(UpArrow)`
  height: 16px;
  left: 16px;
  position: absolute;
  top: -16px;
  width: 16px;
`
