import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import {
  Customer,
  Sales as SalesType,
} from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { parseCustomerName } from '@/modules/FrontDesk/components/DailyRoomReservations/utils'

import { SalesLabel } from './common'

type Props = {
  sales: SalesType
}

export const Sales = ({ sales }: Props) => (
  <Wrapper alignItems="flex-start" flex={1} noPadding>
    {getSalesLabel(sales)}
    {getCustomerLabel(sales.customer?.customer)}
  </Wrapper>
)

////////////

const getCustomerLabel = (customer?: Customer) => (
  <CustomerWrapper>
    <NoWrapSpan>
      {customer ? parseCustomerName(customer) || '—' : '–'}
    </NoWrapSpan>
  </CustomerWrapper>
)

const getSalesLabel = ({ id, name, orderNumber, type }: SalesType) => (
  <SalesLink
    rel="noopener noreferrer"
    target="_blank"
    to={`/sales/details/${id}`}
  >
    <NoWrapSpan>
      <SalesLabel name={name} orderNumber={orderNumber} type={type} />
    </NoWrapSpan>
  </SalesLink>
)

const CustomerWrapper = styled.span`
  display: block;
  margin-top: 2px;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `};
`

const NoWrapSpan = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const SalesLink = styled(Link)`
  text-decoration: none;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
  `};
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `};

  width: 170px;
`
