import { useRef } from 'react'
import { GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { AgGridPlaceholder } from './AgGridPlaceholder'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

type Props<T> = Omit<GridOptions, 'rowData'> & {
  error?: boolean
  height?: string
  rowData: T[]
}

export const AgGrid = <T,>({ error, height, ...props }: Props<T>) => {
  const { palette } = useTheme()

  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    if (!gridRef.current) return

    const displayedRowCount = gridRef.current.api.getDisplayedRowCount()

    if (displayedRowCount === 0) {
      gridRef.current.api.showNoRowsOverlay()
    } else {
      gridRef.current.api.hideOverlay()
    }
  }

  const renderLoadingOverlay = () => (
    <ReactLoading
      color={palette.smoke.light}
      type="spin"
      height={50}
      width={50}
    />
  )

  const renderPlaceholderOverlay = () =>
    error ? (
      <AgGridPlaceholder
        content={<T>AgGrid:error</T>}
        icon="triangle-exclamation"
      />
    ) : (
      <AgGridPlaceholder
        content={<T>AgGrid:empty</T>}
        icon="magnifying-glass"
      />
    )

  return (
    <AgGridContainer className="ag-theme-quartz" height={height}>
      <AgGridReact
        {...props}
        ref={gridRef}
        loadingOverlayComponent={renderLoadingOverlay}
        noRowsOverlayComponent={renderPlaceholderOverlay}
        onModelUpdated={onModelUpdated}
      />
    </AgGridContainer>
  )
}

/////////

const AgGridContainer = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? '100%'};
  width: 100%;

  ${({ theme }) => css`
    // Font
    --ag-font-family: ${theme.typography.fontFamily};
    --ag-font-size: ${theme.typography.fontSizeBase};

    // Colors
    --ag-foreground-color: ${theme.palette.text.main};
    --ag-background-color: ${theme.palette.white};
    --ag-header-foreground-color: ${theme.palette.text.light};
    --ag-header-background-color: ${theme.palette.smoke.extraLight};
    --ag-border-color: ${theme.palette.smoke.dark};

    // Hover states
    --ag-row-hover-color: ${theme.palette.primary.extraLighter};
    --ag-selected-row-background-color: ${theme.palette.primary.lighter};
    --ag-range-selection-border-color: ${theme.palette.primary.main};

    // Spacing
    --ag-grid-size: ${theme.spacing.gu(1)}rem;
    --ag-row-height: ${theme.spacing.gu(6)}rem;
    --ag-header-height: ${theme.spacing.gu(7)}rem;
    --ag-cell-horizontal-padding: ${theme.spacing.gu(2)}rem;

    .ag-cell-error {
      color: ${theme.palette.danger.darker};
      background: #ffefef;
    }

    .ag-row-error {
      background: #ffefef;
    }
  `}
`
