import { STRAPI_URL } from '@/config/env'
import { apiCall, elasticApiGet, elasticApiSearch } from '@/utils/api'

import {
  CalendarListingPreset,
  CalendarResourceElastic,
} from './CalendarResources.types'

type CalendarResourcesServiceType = {
  fetchListingPresets: () => Promise<CalendarListingPreset[]>
  find: (id: string) => Promise<CalendarResourceElastic>
  findAll: () => Promise<CalendarResourceElastic[]>
  findAllByIds: (resourceIds: string[]) => Promise<CalendarResourceElastic[]>
  findAllByCategories: (
    categories: Array<string[]>,
    isRestrictedMode: boolean
  ) => Promise<CalendarResourceElastic[]>
}

const RESOURCE_DOCUMENT_TYPE = 'resourcedocument'
const RESOURCE_INDEX = 'registry.resource'

const CalendarResourcesService: CalendarResourcesServiceType = Object.freeze({
  fetchListingPresets: () =>
    apiCall<Record<string, unknown>[]>(`${STRAPI_URL}/cal-listing-presets`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    }).then(({ data, ok }) => {
      if (!ok || !data) {
        throw new Error('Failed to retrieve calendar listing presets')
      }

      return data.map(
        (x) =>
          ({
            groupsDisplay: x.group_display,
            id: `${x.id}`,
            label: (x.name as string) || '',
            resourceIds: [
              ...(Array.isArray(x.equipments)
                ? x.equipments.map(({ id }) => `EQUIPMENT-${id}`)
                : []),
              ...(Array.isArray(x.human_resources)
                ? x.human_resources.map(({ id }) => `HUMAN_RESOURCE-${id}`)
                : []),
              ...(Array.isArray(x.properties)
                ? x.properties.map(({ id }) => `PROPERTY-${id}`)
                : []),
            ],
            resourceProductDisplay: false,
            searchGroups: Array.isArray(x.search_groups)
              ? x.search_groups.map((group) => ({
                  active: group.is_active === true,
                  categoryPaths: Array.isArray(group.categories)
                    ? group.categories.map(
                        (category: Record<string, unknown>) => `/${category.id}`
                      )
                    : [],
                  id: `${group.id}`,
                  isRestrictedMode: group.is_restricted_mode === true,
                  label: group.label || '',
                }))
              : [],
            showAllTasks: x.show_all_tasks_if_assignee_exists,
            sortOrder: x.sort_order,
            taskDisplay: x.task_display === 'extended' ? 'extended' : 'minimal',
            userGroupId: Array.isArray(x.task_assignee_filter_bar)
              ? x.task_assignee_filter_bar.map(({ id }) => id.toString())
              : [],
            viewAssigneeSelection: Array.isArray(x.task_assignee_view_bar)
              ? x.task_assignee_view_bar.map(({ id }) => id.toString())
              : [],
          }) as CalendarListingPreset
      )
    }),
  find: (id: string) =>
    elasticApiGet<CalendarResourceElastic>(
      RESOURCE_INDEX,
      RESOURCE_DOCUMENT_TYPE,
      id
    ).then(({ data, ok }) => {
      if (!ok || !data) {
        throw new Error(`Failed to retrieve resource [id=${id}]`)
      }

      return data
    }),
  findAll: () =>
    elasticApiSearch<CalendarResourceElastic>(RESOURCE_INDEX, {
      query: {
        match_all: {},
      },
      size: 1000,
    }).then(({ data, ok }) => {
      if (!ok) {
        throw new Error('Failed to retrieve calendar resources')
      }

      return data
    }),
  findAllByCategories: (
    categories: Array<string[]>,
    isRestrictedMode: boolean
  ) => {
    const getSearchObj = (set: string[]) => ({
      nested: {
        path: 'categories',
        query: {
          bool: {
            should: set.map((path: string) => ({
              term: { 'categories.path': path },
            })),
          },
        },
      },
    })

    return elasticApiSearch<CalendarResourceElastic>(RESOURCE_INDEX, {
      query: {
        bool: isRestrictedMode
          ? { must: categories.map((set) => getSearchObj(set)) }
          : { should: categories.map((set) => getSearchObj(set)) },
      },
      size: 1000,
    }).then(({ data, ok }) => {
      if (!ok) {
        throw new Error('Failed to retrieve category resources')
      }

      return data
    })
  },
  findAllByIds: (resourceIds: string[]) =>
    elasticApiSearch<CalendarResourceElastic>(RESOURCE_INDEX, {
      query: {
        ids: {
          values: resourceIds,
        },
      },
      size: 1000,
    }).then(({ data, ok }) => {
      if (!ok) {
        throw new Error('Failed to retrieve calendar resources')
      }

      return data
    }),
})

export default CalendarResourcesService
