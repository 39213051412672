import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { FlexRow, Gutter } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { Action, ActionButton } from '@/modules/Order/components/Action'
import {
  Issues,
  ReferencePaymentModal,
  SectionSeparator,
} from '@/modules/Order/components/Sections'
import { orderServices } from '@/modules/Order/services'
import { Invoice, InvoiceValidatedAction as IVA } from '@/modules/Order/types'

import {
  DocumentFileStatus,
  InvoiceAction as IA,
  InvoicePaymentType as IPT,
  InvoiceState,
} from '~generated-types'

import { OtherPaymentActions } from './OtherPaymentActions'
import { RefundActions } from './RefundActions'

type ValidatedActions = {
  addPaymentAction?: IVA
  addReferencePaymentAction?: IVA
  addOtherPaymentAction?: IVA
  createRefundAction?: IVA
  printInvoiceAction?: IVA
  sendInvoiceAction?: IVA
  deleteAction?: IVA
}

type Props = {
  invoice: Invoice
  openDocumentModal: () => void
  openPaymentsModal: () => void
  orderId: string
  loadCosting: () => void
}

export const ActionsSection = ({
  invoice: {
    document: invoiceDocument,
    id,
    seller,
    lifecycle: { state, validatedActions: actions },
    paymentType,
    type,
  },
  openDocumentModal,
  openPaymentsModal,
  loadCosting,
  orderId,
}: Props) => {
  const { confirm } = useDialogService()

  const { acceptInvoice, removeInvoice, setPaymentType } =
    orderServices.invoiceService()

  const [processingTarget, setProcessingTarget] = useState<IA | null>(null)
  const [isReferencePaymentModalOpen, setReferencePaymentModalOpen] =
    useState<boolean>(false)

  const loadCostingEnabled = useMemo(
    () => state === InvoiceState.Accepted,
    [state]
  )

  const {
    addPaymentAction,
    addReferencePaymentAction,
    addOtherPaymentAction,
    createRefundAction,
    printInvoiceAction,
    sendInvoiceAction,
    deleteAction,
  } = useMemo(
    () =>
      actions.reduce((acc: ValidatedActions, o) => {
        switch (o.action) {
          case IA.AddManualPayment:
            return { ...acc, addPaymentAction: o }
          case IA.AddManualReferenceTransferPayment:
            return { ...acc, addReferencePaymentAction: o }
          case IA.AddOtherPayment:
            return { ...acc, addOtherPaymentAction: o }
          case IA.CreateRefund:
            return { ...acc, createRefundAction: o }
          case IA.PrintInvoice:
            return { ...acc, printInvoiceAction: o }
          case IA.SendInvoice:
            return { ...acc, sendInvoiceAction: o }
          case IA.Delete:
            return { ...acc, deleteAction: o }
          default:
            return acc
        }
      }, {}),
    [actions]
  )

  const validationIssues =
    actions.find((a) => a.action === IA.AcceptProcess)?.issues.flat() ?? []

  const handleDeleteInvoice = () => {
    setProcessingTarget(IA.Delete)

    return removeInvoice(id, orderId, type).then(
      (isRemoved) => !isRemoved && setProcessingTarget(null)
    )
  }

  const handleAcceptInvoice = (action: IA, paymentType: IPT) => {
    setProcessingTarget(action)

    const doAccept = () =>
      setPaymentType(id, orderId, paymentType).then(() =>
        acceptInvoice(id, orderId)
      )

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <PrimaryColor>
          <T>common:action.continue</T>
        </PrimaryColor>
      ),
      description: <T>{`Orders:Confirmation.${action}.description`}</T>,
      title: <T>{`Orders:Confirmation.${action}.title`}</T>,
    })
      .then(doAccept)
      .catch(() => undefined)
      .finally(() => setProcessingTarget(null))
  }

  const handleAddPayment = () => {
    openPaymentsModal()

    if (!createRefundAction && paymentType !== IPT.Cash) {
      setPaymentType(id, orderId, IPT.Cash)
    }
  }

  return (
    <Wrapper>
      {addPaymentAction && (
        <>
          <Gutter type={[0, 1.5, 0, 0]}>
            <Action
              onClick={handleAddPayment}
              phase={type}
              validatedAction={addPaymentAction}
            />
          </Gutter>
          <Separator light />
        </>
      )}

      {sendInvoiceAction && (
        <Gutter type={[0, 1.5, 0, 0]}>
          <Action
            isProcessing={processingTarget === IA.SendInvoice}
            onClick={() => handleAcceptInvoice(IA.SendInvoice, IPT.Invoice)}
            phase={type}
            validatedAction={sendInvoiceAction}
          />
        </Gutter>
      )}

      {printInvoiceAction && (
        <Gutter type={[0, 1.5, 0, 0]}>
          <Action
            isProcessing={processingTarget === IA.PrintInvoice}
            onClick={() => handleAcceptInvoice(IA.PrintInvoice, IPT.Pdf)}
            phase={type}
            validatedAction={printInvoiceAction}
          />
        </Gutter>
      )}

      {(sendInvoiceAction || printInvoiceAction) && <Separator light />}

      {addOtherPaymentAction && (
        <>
          <Gutter type={[0, 1.5, 0, 0]}>
            <OtherPaymentActions
              action={addOtherPaymentAction}
              invoiceId={id}
              orderId={orderId}
              phase={type}
              sellerId={seller?.id}
            />
          </Gutter>
          <Separator light />
        </>
      )}

      {addReferencePaymentAction && (
        <>
          <Gutter type={[0, 1.5, 0, 0]}>
            <Action
              onClick={() => setReferencePaymentModalOpen(true)}
              phase={type}
              validatedAction={addReferencePaymentAction}
            />
          </Gutter>
          <Separator light />
        </>
      )}

      {invoiceDocument && (
        <Gutter type={[0, 1.5, 0, 0]}>
          <ActionButton
            action="SHOW_DOCUMENT"
            label={
              <T>{`Orders:Documents.action.${
                invoiceDocument.fileStatus === DocumentFileStatus.Final
                  ? 'showDocument'
                  : 'showDocumentPreview'
              }`}</T>
            }
            onClick={openDocumentModal}
          />
        </Gutter>
      )}

      {loadCostingEnabled && (
        <Gutter type={[0, 1.5, 0, 0]}>
          <ActionButton
            action="LOAD_COSTING"
            label={<T>{'Orders:Action.INVOICE.LOAD_COSTING'}</T>}
            onClick={loadCosting}
          />
        </Gutter>
      )}

      <Spacer />

      {!!validationIssues.length && (
        <>
          <Issues issues={validationIssues} />
          <Separator light />
        </>
      )}

      {createRefundAction && (
        <RefundActions
          action={createRefundAction}
          invoiceId={id}
          isProcessing={processingTarget === createRefundAction.action}
          orderId={orderId}
          phase={type}
        />
      )}

      {deleteAction && (
        <Action
          isProcessing={processingTarget === deleteAction.action}
          onClick={handleDeleteInvoice}
          phase={type}
          validatedAction={deleteAction}
        />
      )}

      <ModalContainer
        isOpen={isReferencePaymentModalOpen}
        onClose={() => setReferencePaymentModalOpen(false)}
        modal={
          <ReferencePaymentModal
            invoiceId={id}
            onClose={() => setReferencePaymentModalOpen(false)}
            orderId={orderId}
          />
        }
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </Wrapper>
  )
}

////////////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Separator = styled(SectionSeparator)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`
