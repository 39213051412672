import { Redirect, Switch } from 'react-router-dom'

import { NotFoundPage, PageRoute } from '@/components/Page'
import { globalRoutes } from '@/config/constants'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { FrontDeskRoutes } from '@/modules/FrontDesk'
import { ListingRoutes } from '@/modules/Listing'
import { RegistryRoutes } from '@/modules/Registry/routes'
import { ReportsRoutes } from '@/modules/Reports'
import { ReservationRoutes } from '@/modules/Reservations'
import { SalesRoutes } from '@/modules/Sales/routes'
import { SearchRoutes } from '@/modules/Search'

import { NavInternalViewType, NavItemType } from '~generated-types'

import { ExternalPage } from '../ExternalPage'

export const AppRoutesDesktop = () => {
  const { allNavDropdownExternalItems } = useWillbaConfigContext()

  return (
    <Switch>
      {/* Redirect to sales search list from root route */}
      <Redirect
        exact
        from={`/`}
        to={globalRoutes[NavInternalViewType.SearchSales]}
      />

      {/* Redirect to sales search list from mobile route */}
      <Redirect
        from={`/mobile`}
        to={globalRoutes[NavInternalViewType.SearchSales]}
      />

      {allNavDropdownExternalItems.map(({ key, name, to, url }) => (
        <PageRoute accessKey={key} exact key={to} pageTitleKey={name} path={to}>
          <ExternalPage id={to} url={url} />
        </PageRoute>
      ))}

      <PageRoute
        component={FrontDeskRoutes}
        pageTitleKey={`Navigation:navItem.${NavItemType.FrontDesk}`}
        path={globalRoutes[NavItemType.FrontDesk]}
      />

      <PageRoute
        component={ListingRoutes}
        pageTitleKey={`Navigation:navItem.${NavItemType.Listing}`}
        path={globalRoutes[NavItemType.Listing]}
      />

      <PageRoute
        component={RegistryRoutes}
        pageTitleKey={`Navigation:navItem.${NavItemType.Registry}`}
        path={globalRoutes[NavItemType.Registry]}
      />

      <PageRoute
        component={ReportsRoutes}
        pageTitleKey={`Navigation:navItem.${NavItemType.Report}`}
        path={globalRoutes[NavItemType.Report]}
      />

      <PageRoute
        component={ReservationRoutes}
        pageTitleKey={`Navigation:navItem.${NavItemType.Booking}`}
        path={globalRoutes[NavItemType.Booking]}
      />

      <PageRoute
        component={SearchRoutes}
        pageTitleKey={`Navigation:navItem.${NavItemType.Search}`}
        path={globalRoutes[NavItemType.Search]}
      />

      <PageRoute component={SalesRoutes} path="/sales" />

      <PageRoute component={NotFoundPage} pageTitle="404" />
    </Switch>
  )
}
