import { notify } from '@/components/NotificationService'
import { ELASTIC_URL } from '@/config/env'

import { apiCall } from '../apiCall'
import { Response } from '../apiCall/types'
import { ElasticSearchResult } from './types'

export const elasticApiSearch = <T>(
  index: string,
  queryObject: {
    [key: string]: any
  }
): Promise<Response<T[]>> =>
  apiCall<ElasticSearchResult<T>>(`${ELASTIC_URL}/${index}/_search/`, {
    body: JSON.stringify(queryObject),
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(({ data, ok, ...rest }) => {
    if (!ok) {
      notify({ translationPath: 'common:error.common', type: 'ERROR' })
    }
    return {
      ...rest,
      data: ok ? parseElasticResponse(data) : [],
      ok,
    }
  })

////////////

export const parseElasticResponse = <T>(
  elasticResponse: ElasticSearchResult<T>
): T[] => {
  const hits = elasticResponse?.hits?.hits

  if (!hits || !Array.isArray(hits)) {
    console.warn('Failed to parse hits from elastic response', elasticResponse)
    return []
  }

  return hits.map((i) => i._source)
}
