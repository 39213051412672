import { gql } from '@apollo/client'

import serviceParticipantBed from './serviceParticipantBed'
import servicePurchase from './servicePurchase'

export default gql`
  ${serviceParticipantBed}
  ${servicePurchase}

  fragment ParticipantService on ServiceParticipantConnection {
    id
    type
    ...ServiceParticipantBed
    ...ServicePurchase
  }
`
