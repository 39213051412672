import { gql } from '@apollo/client'

export default gql`
  query SaleAccommodationBase($input: SuitableRoomsInput!) {
    suitableRooms(input: $input) {
      roomReservation {
        id
        participantRooms {
          id
          participant {
            id
            firstName
            lastName
          }
        }
        request {
          room {
            id
            number
          }
        }
      }
    }
  }
`
