import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { useTheme } from '@/theme'

import { ConditionRuleType, DayOfWeek } from '~generated-types'

import { Button } from './common'

type Weekday = {
  isSelected: boolean
  key: DayOfWeek
  label: string
}

type Props = {
  id: string
  weekdays: DayOfWeek[]
}

const WEEKDAYS = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
]

export const WeekdaySelector = ({ id, weekdays: selectedWeekdays }: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()
  const { readOnly, updateProductItemRule } = useCatalogProductManagerContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [weekdays, setWeedays] = useState<Weekday[]>(
    Object.values(WEEKDAYS).map((weekday) => ({
      isSelected: !!selectedWeekdays.find((day) => day === weekday),
      key: weekday,
      label: translate(`enums:weekday.abbrev.${weekday}`, language),
    }))
  )

  const handleUpdateRuleValue = () => {
    updateProductItemRule({
      condition: {
        ruleType: ConditionRuleType.Weekday,
        weekdays: weekdays
          .filter(({ isSelected }) => !!isSelected)
          .map(({ key }) => key),
      },
      productRuleId: id,
    }).then(() => setModalOpen(false))
  }

  const handleChangeWeekday = (weekdayKey: string) =>
    setWeedays(
      weekdays.map((weekday) =>
        weekday.key === weekdayKey
          ? { ...weekday, isSelected: !weekday.isSelected }
          : weekday
      )
    )

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <Modal>
          <FlexRow flex={1} justifyContent="space-between">
            {weekdays.map((weekday: Weekday) => (
              <DayButton
                isSelected={weekday.isSelected}
                key={weekday.key}
                onClick={() => handleChangeWeekday(weekday.key)}
              >
                {weekday.label}
              </DayButton>
            ))}
          </FlexRow>
        </Modal>
      }
      onClose={handleUpdateRuleValue}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          disabled={readOnly}
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setModalOpen(true)}
        >
          {!selectedWeekdays.length ? (
            <FontAwesomeIcon
              color={palette.primary.main}
              fixedWidth
              size="sm"
              icon="plus"
            />
          ) : selectedWeekdays.length === 7 ? (
            <T>Products:ProductManager.pricing.rule.everyDay</T>
          ) : (
            selectedWeekdays.map((weekday, index, weekdayArray) => (
              <span key={weekday}>
                {translate(`enums:weekday.abbrev.${weekday}`, language)}
                {index !== weekdayArray.length - 1 && ', '}
              </span>
            ))
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

///////

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(50)}rem;
  `}
`

const DayButton = styled.button<{ isSelected: boolean }>`
  cursor: pointer;
  border-radius: 4px;
  outline: none;

  ${({ isSelected, theme }) => css`
    background: ${isSelected
      ? theme.palette.primary.main
      : theme.palette.white};
    border: 1px solid
      ${isSelected ? theme.palette.primary.main : theme.palette.text.lighter};
    color: ${isSelected ? theme.palette.white : theme.palette.text.lighter};
    height: ${theme.spacing.gu(4)}rem;
    width: ${theme.spacing.gu(5.5)}rem;
  `}
`
