import { gql } from '@apollo/client'

export default gql`
  fragment DimensionReference on DimensionReference {
    id
    selection {
      dimension {
        id
        name
        required
        sortOrder
      }
      id
      selectedLabel {
        id
        name
      }
      validatedOptions {
        id
        label {
          id
          name
        }
        validationCategory
      }
    }
  }
`
