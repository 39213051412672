import {
  CleaningStatus,
  Participant,
  Reservation,
  ReservationType,
  RoomReservation,
  RoomStatus,
  Sales,
} from '../../types'

type FilterRoomReservationsProps = {
  buildings: string[]
  cleaningStatuses: CleaningStatus[]
  isArrivingReservation: (reservation: Reservation) => boolean
  isDepartingReservation: (reservation: Reservation) => boolean
  reservationTypes: ReservationType[]
  roomReservations: RoomReservation[]
  roomStatuses: RoomStatus[]
  selectedParticipant: Participant | null
  selectedSale: Sales | null
}

export const filterRoomReservations = ({
  buildings,
  cleaningStatuses,
  isArrivingReservation,
  isDepartingReservation,
  reservationTypes,
  roomReservations,
  roomStatuses,
  selectedParticipant,
  selectedSale,
}: FilterRoomReservationsProps) => {
  if (
    !buildings.length &&
    !cleaningStatuses.length &&
    !reservationTypes.length &&
    !roomStatuses.length &&
    !selectedParticipant &&
    !selectedSale
  ) {
    return roomReservations
  }

  return roomReservations.filter((r) => {
    let isMatched = true

    if (!!reservationTypes.length) {
      const isArrivinng = reservationTypes.includes(ReservationType.Arrivinng)
      const isDeparting = reservationTypes.includes(ReservationType.Departing)
      const isPending = reservationTypes.includes(ReservationType.Pending)

      const arrivingReservation = r.reservations.find(isArrivingReservation)
      const departingReservation = r.reservations.find(isDepartingReservation)

      // If only PENDING is selected
      if (isPending && reservationTypes.length === 1) {
        isMatched =
          (!!arrivingReservation && !arrivingReservation?.checkIn) ||
          (!!departingReservation && !departingReservation?.checkOut)
      } else {
        const showArriving =
          isArrivinng &&
          !!arrivingReservation &&
          (!isPending || !arrivingReservation?.checkIn)
        const showDeparting =
          isDeparting &&
          !!departingReservation &&
          (!isPending || !departingReservation?.checkOut)

        isMatched = showArriving || showDeparting
      }
    }

    if (isMatched && !!roomStatuses.length) {
      const showOccupied =
        roomStatuses.includes(RoomStatus.Occupied) &&
        r.room.status.reservationStatus === RoomStatus.Occupied
      const showReserved =
        roomStatuses.includes(RoomStatus.Reserved) &&
        r.room.status.reservationStatus === RoomStatus.Reserved
      const showVacant =
        roomStatuses.includes(RoomStatus.Vacant) &&
        r.room.status.reservationStatus === RoomStatus.Vacant

      isMatched = showOccupied || showReserved || showVacant
    }

    if (isMatched && !!cleaningStatuses.length) {
      const showClean =
        cleaningStatuses.includes(CleaningStatus.Clean) &&
        r.room.status.cleaningStatus === CleaningStatus.Clean
      const showDirty =
        cleaningStatuses.includes(CleaningStatus.Dirty) &&
        r.room.status.cleaningStatus === CleaningStatus.Dirty

      isMatched = showClean || showDirty
    }

    if (isMatched && selectedParticipant) {
      isMatched = r.reservations.some(({ participantRooms }) =>
        participantRooms.some(
          ({ participant }) => participant.id === selectedParticipant.id
        )
      )
    }

    if (isMatched && selectedSale) {
      isMatched = r.reservations.some(
        ({ sales }) => sales.id === selectedSale.id
      )
    }

    if (isMatched && !!buildings.length) {
      isMatched = buildings.includes(r.room.building.id)
    }

    return isMatched
  })
}
