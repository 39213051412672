import { Fragment, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { InlineModalLine } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import RoomNote from '@/components/TextNoteModal'
import {
  useRoomReservationSetBedQuantity,
  useRoomReservationSetNeeds,
} from '@/modules/Accommodation/SalesReservationManager/mutations'
import { T, useLanguageContext } from '@/modules/Language'
import { GroupTitle } from '@/modules/ParticipantsList/components/ParticipantServices/AccommodationService/modules'
import { participantQueries } from '@/modules/ParticipantsList/queries'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  AccommodationTargetFragment,
  SaleAccommodationQuery,
  SaleAccommodationQueryVariables,
  SuitableFilter,
} from '~generated-types'

import { BedsAmount } from './BedsAmount'
import { CapacityMassUpdate } from './CapacityMassUpdate'
import { ContextSwitcher } from './ContextSwitcher'
import { ParticipantRoomLabel } from './ParticipantRoomLabel'

type SuitableRoom = SaleAccommodationQuery['suitableRooms'][0]

type UpdateRoomBedsInput = {
  beds: number
  extraBeds: number
  reservationId: string
}

type Props = {
  salesId: string
  selectedRooms: { id: string; emptyBeds: number }[]
  handleSetSelectedRooms: (rooms: { id: string; emptyBeds: number }[]) => void
}

export const RoomOptions = ({
  salesId,
  selectedRooms,
  handleSetSelectedRooms,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { language } = useLanguageContext()

  const [selectedLabel, setSelectedLabel] = useState<'ALL' | 'DEFAULT'>(
    'DEFAULT'
  )
  const [targets, setTargets] = useState<AccommodationTargetFragment[]>([])
  const [massUpdateBeds, setMassUpdateBeds] = useState(1)

  const { data, loading, refetch, error } = useQuery<
    SaleAccommodationQuery,
    SaleAccommodationQueryVariables
  >(participantQueries.SUITABLE_ROOMS, {
    fetchPolicy: 'no-cache',
    variables: { input: { filter: selectedLabel as SuitableFilter, salesId } },
  })

  const [setRoomNeeds] = useRoomReservationSetNeeds()
  const [setRoomBedQuantity] = useRoomReservationSetBedQuantity()

  useEffect(() => {
    if (!data?.suitableRooms) {
      return
    }

    // @ts-ignore
    const targets: AccommodationTargetFragment[] = (data?.suitableRooms || [])
      .map(({ roomReservation }) => roomReservation.target)
      .filter(Boolean)

    const uniqueTargets = targets
      .sort(generateCompareFn('id'))
      .filter((item, pos, ary) => !pos || item?.id !== ary[pos - 1]?.id)

    setTargets(uniqueTargets)
  }, [data])

  const bedsMassUpdate = () => {
    handleSetSelectedRooms(
      selectedRooms.map((room) => {
        const roomData = data?.suitableRooms.find(
          ({ roomReservation }) => roomReservation.id === room.id
        )
        const availableCapacity = roomData?.roomReservation
          ? roomData.roomReservation.request.beds +
            roomData.roomReservation.request.extraBeds -
            roomData.roomReservation.participantRooms.length
          : 0

        return {
          emptyBeds:
            availableCapacity < massUpdateBeds
              ? availableCapacity
              : massUpdateBeds,
          id: room.id,
        }
      })
    )
  }

  const onSelectRoom = ({ id, emptyBeds }: { id: string; emptyBeds: number }) =>
    handleSetSelectedRooms(
      selectedRooms.find(({ id: selectedId }) => selectedId === id)
        ? selectedRooms.map((selected) =>
            selected.id === id ? { emptyBeds, id } : selected
          )
        : [...selectedRooms, { emptyBeds, id }]
    )

  const onRoomClick = (roomId: string) => {
    const isSelected = !!selectedRooms.find(({ id }) => id === roomId)
    const roomData = data?.suitableRooms.find(
      ({ roomReservation }) => roomReservation.id === roomId
    )
    const emptyBeds = roomData
      ? roomData.roomReservation.request.beds -
        roomData.roomReservation.participantRooms.length
      : 0

    const updatedRooms = isSelected
      ? selectedRooms.filter(({ id }) => id !== roomId)
      : roomData
        ? [
            ...selectedRooms,
            {
              emptyBeds:
                emptyBeds <= 0
                  ? roomData.roomReservation.request.extraBeds > 0
                    ? 1
                    : 0
                  : emptyBeds,
              id: roomId,
            },
          ]
        : selectedRooms

    handleSetSelectedRooms(updatedRooms)
  }

  const selectAllOptionsForTarget = (
    options: {
      disabled: boolean
      endAdornment: JSX.Element
      label: JSX.Element
      startAdornment: JSX.Element
      value: string
    }[]
  ) => {
    const rooms = options
      .filter(({ disabled }) => !disabled)
      .map((option) => {
        const roomData = data?.suitableRooms.find(
          ({ roomReservation }) => roomReservation.id === option.value
        )

        return roomData
          ? {
              emptyBeds:
                roomData.roomReservation.request.beds -
                roomData.roomReservation.participantRooms.length,
              id: option.value,
            }
          : {
              emptyBeds: 0,
              id: '',
            }
      })

    handleSetSelectedRooms([
      ...selectedRooms.filter(
        ({ id }) => !rooms.find((room) => room.id === id)
      ),
      ...rooms,
    ])
  }

  const roomNumberSorter = (a: SuitableRoom, b: SuitableRoom) =>
    a.roomReservation.request.room.number.localeCompare(
      b.roomReservation.request.room.number,
      language,
      {
        numeric: true,
      }
    )

  const getRoomsOptionsForTarget = (targetId: string) =>
    (data?.suitableRooms || [])
      .filter(({ roomReservation }) => roomReservation.target?.id === targetId)
      .sort(roomNumberSorter)
      .map(({ roomReservation: { id, request, participantRooms } }) => {
        const selectedRoom = selectedRooms.find(
          ({ id: selectedId }) => selectedId === id
        )

        const disabled =
          request.beds + request.extraBeds - participantRooms.length <= 0

        const handleSetNotes = (note: string) =>
          setRoomNeeds({
            variables: {
              input: {
                featureIds: request.features.map(({ id }) => id),
                id,
                info: note,
              },
            },
          })

        const handleSetRoomBeds = (input: UpdateRoomBedsInput) => {
          const { reservationId, ...baseInput } = input

          return setRoomBedQuantity({
            variables: {
              input: { ...baseInput, id: reservationId },
            },
          })
            .then(() => refetch())
            .catch(() => undefined)
        }

        return {
          disabled,
          endAdornment: (
            <RoomNote note={request.info || ''} updateNote={handleSetNotes} />
          ),
          label: (
            <ParticipantRoomLabel
              participantRooms={participantRooms}
              request={request}
              reservationId={id}
              updateRoomBeds={handleSetRoomBeds}
            />
          ),
          startAdornment: (
            <>
              <CheckboxInput
                disabled={disabled}
                checked={!!selectedRoom}
                noMargin
                onChange={() => onRoomClick(id)}
                style={{ marginRight: spacing.gutter }}
              />
              <BedsAmount
                roomId={id}
                disabled={
                  request.beds + request.extraBeds - participantRooms.length <=
                  0
                }
                bedsAvailable={request.beds - participantRooms.length}
                extraBedsAvailable={request.extraBeds}
                selectedBeds={selectedRoom?.emptyBeds || 0}
                onSubmit={onSelectRoom}
              />
            </>
          ),
          value: id,
        }
      })

  return (
    <FlexColumn alignItems="center" flex={1} style={{ overflow: 'hidden' }}>
      {loading ? (
        <ContentWrapper>
          <ReactLoading
            color={palette.smoke.main}
            height={28}
            type="spin"
            width={28}
          />
        </ContentWrapper>
      ) : error ? (
        <ContentWrapper>
          <Placeholder>
            – <T>common:error.common</T> –
          </Placeholder>
        </ContentWrapper>
      ) : !targets?.length ? (
        <ContentWrapper>
          <Placeholder>
            – <T>ParticipantsList:ParticipantRooms.noRooms</T> –
          </Placeholder>
        </ContentWrapper>
      ) : (
        <>
          <ContextSwitcher
            description={
              selectedRooms.length ? (
                <CapacityMassUpdate
                  beds={massUpdateBeds}
                  setBeds={setMassUpdateBeds}
                  onSubmit={bedsMassUpdate}
                />
              ) : undefined
            }
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            loadDataForLabel={refetch}
          />

          <InlineModalLine style={{ margin: 0, width: '100%' }} />

          <TargetWrapper>
            {targets.map((target) => {
              const options = getRoomsOptionsForTarget(target.id)

              return (
                <Fragment key={`target-${target.id || 'floating'}`}>
                  <GroupTitleWrapper>
                    <GroupTitle
                      target={target}
                      selectAllForGroup={() =>
                        selectAllOptionsForTarget(options)
                      }
                    />
                  </GroupTitleWrapper>

                  {options.length ? (
                    <FlexColumn noPadding style={{ width: '100%' }}>
                      {options.map((option) => (
                        <OptionWrapper key={option.value} flex={1}>
                          {option.startAdornment}

                          <OptionLabel>{option.label}</OptionLabel>

                          {option.endAdornment}
                        </OptionWrapper>
                      ))}
                    </FlexColumn>
                  ) : (
                    <Placeholder style={{ margin: `${spacing.gu(1)}rem` }}>
                      – <T>ParticipantsList:ParticipantRooms.noRooms</T> –
                    </Placeholder>
                  )}
                </Fragment>
              )
            })}
          </TargetWrapper>
        </>
      )}
    </FlexColumn>
  )
}

//////

const ContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(3)}rem 0;
  `}
`

const GroupTitleWrapper = styled.div`
  display: flex;

  border-radius: 8px;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.primary.extraLight};
    padding: ${theme.spacing.gutterSmall};
    margin: ${theme.spacing.gu(2)}rem 0 ${theme.spacing.gu(1)}rem;
  `}
`

const OptionLabel = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const OptionWrapper = styled(FlexRow)`
  width: 100%;

  &:not(:last-child) {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.lighter};
  `}
`

const TargetWrapper = styled(FlexColumn)`
  overflow: auto;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem;
  `}
`
