import moment from 'moment'

import { FlexRow } from '@/components/Layout'
import { Pagination } from '@/components/Pagination'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentSection } from '../../Sales/components/SalesDetails/components'
import {
  ListContainerColumn,
  ListRow,
  MessagePlaceholder,
  RowWrapper,
  Section,
  StatusChip,
  StyledSpinner,
  TruncateContent,
  VerticalDivider,
} from '../common'
import { EmailsListHeader, ListTotals, ShowAllButton } from './components'
import { useEmailsListContext } from './EmailsListState'

export const EmailsList = () => {
  const theme = useTheme()

  const {
    pageSize,
    pagination,
    setPageSize,
    handleSwitchPage,
    emails,
    error,
    loading,
    showAllEmails,
  } = useEmailsListContext()

  return (
    <RowWrapper>
      <ContentSection title={<T>Emails:SalesEmailsList.title</T>}>
        <ListContainerColumn>
          {error ? (
            <MessagePlaceholder>
              <T>Emails:SalesEmailsList.error</T>
            </MessagePlaceholder>
          ) : loading ? (
            <StyledSpinner
              color={theme.palette.smoke.main}
              height={28}
              type={'spin'}
              width={28}
            />
          ) : !!emails?.nodes.length ? (
            <>
              <EmailsListHeader />

              {emails.nodes.map((email) => {
                const parsedDates = moment(email.details?.createdAt).format(
                  'DD.MM.YYYY HH:mm'
                )
                return (
                  <ListRow key={`emails-list-row-${email.id}`}>
                    <Section flex={1.3} noLeftMargin>
                      <TruncateContent content={email.details?.subject} />
                    </Section>

                    <VerticalDivider />
                    <Section flex={1}>
                      <TruncateContent content={email.details?.to} />
                    </Section>
                    <VerticalDivider />
                    <Section flex={1}>
                      <TruncateContent content={email.details?.from} />
                    </Section>
                    <VerticalDivider />
                    <Section flex={0.5}>
                      <TruncateContent
                        content={<StatusChip status={email.status} />}
                      />
                    </Section>
                    <VerticalDivider />
                    <Section flex={0.5} noRightMargin>
                      <TruncateContent content={parsedDates} />
                    </Section>
                  </ListRow>
                )
              })}
            </>
          ) : (
            <MessagePlaceholder>
              <T>Emails:SalesEmailsList.empty</T>
            </MessagePlaceholder>
          )}
        </ListContainerColumn>

        {!error && !loading && !!emails?.nodes.length && (
          <>
            <ListTotals />

            <FlexRow>
              {!showAllEmails && (
                <Pagination
                  pagination={pagination}
                  goToPage={handleSwitchPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  style={{ marginTop: 0 }}
                />
              )}
              {(pagination.hasNextPage ||
                pagination.hasPreviousPage ||
                showAllEmails) && <ShowAllButton />}
            </FlexRow>
          </>
        )}
      </ContentSection>
    </RowWrapper>
  )
}
