import { DateRange } from '@/components/TimeControls'

import { RoomLayout } from './RoomLayout'
import { RoomLayoutProvider } from './RoomLayoutContext'

type Props = {
  defaultRange: DateRange | null | undefined
  groupId?: string
  salesId?: string
  targetId?: string
}

export const RoomLayoutContainer = ({
  defaultRange,
  groupId,
  salesId,
  targetId,
}: Props) => (
  <RoomLayoutProvider
    defaultRange={defaultRange}
    targetGroupId={groupId ?? ''}
    targetId={targetId ?? ''}
    targetSalesId={salesId ?? ''}
  >
    <RoomLayout />
  </RoomLayoutProvider>
)
