import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { H2, H4 } from '@/components/Typography'
import { T } from '@/modules/Language'
import { isToday } from '@/utils/time'

import { useRoomReservationsContext } from '../DailyRoomReservationsState'

export const Header = () => {
  const { date, setDate } = useRoomReservationsContext()

  const setCurrentDate = () => setDate(moment())
  const setNextDate = () => setDate(date.clone().add(1, 'days'))
  const setPrevDate = () => setDate(date.clone().subtract(1, 'days'))

  return (
    <StickyHeader>
      <Gutter flex={1} type={[2, 4]}>
        <FlexRow alignItems="center" justifyContent="space-between">
          <FlexColumn alignItems="flex-start" noPadding>
            <SectionTitle>
              <T>FrontDesk:DailyRoomReservations.subtitle</T>
            </SectionTitle>
            <ViewTitle>
              <T>FrontDesk:DailyRoomReservations.title</T>
            </ViewTitle>
          </FlexColumn>
          <SelectedDateWrapper>
            <Button onClick={setPrevDate} variant="borderless">
              <FontAwesomeIcon icon="angle-left" />
            </Button>
            <FlexColumn>
              <SelectedDateLabel>
                {date.format('dd D.M.YYYY')}
              </SelectedDateLabel>
              {!isToday(date) && (
                <TextButton onClick={setCurrentDate}>
                  <T>FrontDesk:DailyRoomReservations.showToday</T>
                </TextButton>
              )}
            </FlexColumn>
            <Button onClick={setNextDate} variant="borderless">
              <FontAwesomeIcon icon="angle-right" />
            </Button>
          </SelectedDateWrapper>
        </FlexRow>
      </Gutter>
    </StickyHeader>
  )
}

////////////

const SelectedDateWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  width: 240px;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(4)}rem;
  `}
`

const SelectedDateLabel = styled(H4)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`

const SectionTitle = styled.span`
  font-variant-caps: all-small-caps;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
  `}
`

export const TextButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50px;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
  `}
`

const StickyHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: 0;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.1);
  z-index: 3;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const ViewTitle = styled(H2)`
  margin: 0;
  font-weight: 600;
`
