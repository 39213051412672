import { useState } from 'react'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { ModalContainer } from '@/components/Modal'
import { SortOption } from '@/components/Reactivesearch'
import { useKeycloakContext } from '@/config/keycloak'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { CatalogProductManager } from '@/modules/Products'
import {
  CatalogProduct,
  ElasticCatalogProduct,
  ElasticProductTitle,
} from '@/modules/Products/types'

import { ProductTitlePicker } from '../ProductTitlePicker'
import { ListControls, ListFilters, ListHeader, ListItem } from './components'

const ComponentIds = Object.freeze({
  CATALOG: 'catalog',
  CATEGORY: 'category',
  LIST: 'page',
  SEARCH: 'catalog-product-search',
})

type TargetCatalog = {
  id: string
  sellerId: string
}

type TargetProduct = {
  id: string
  sellerId: string
}

type Props = {
  onItemCreate: (
    item: ElasticProductTitle,
    catalogId: string
  ) => Promise<CatalogProduct | undefined>
  onItemRemove: (id: string) => Promise<string | undefined>
}

export const CatalogProductList = ({ onItemCreate, onItemRemove }: Props) => {
  const { hasUserAccess } = useKeycloakContext()

  const { language } = useLanguageContext()
  const { confirm } = useDialogService()

  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [refreshTicker, setRefreshTicker] = useState<number>(0)
  const [targetCatalog, setTargetCatalog] = useState<TargetCatalog | null>(null)
  const [targetProduct, setTargetProduct] = useState<TargetProduct | null>(null)

  const readOnly = !hasUserAccess('willba.catalog-product:write')

  const sortOptions: SortOption[] = [
    {
      dataField: 'name.keyword',
      direction: 'asc',
      label: translate('Products:CatalogProductList.sort.nameAsc', language),
    },
    {
      dataField: 'name.keyword',
      direction: 'desc',
      label: translate('Products:CatalogProductList.sort.nameDesc', language),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { _score: 'asc' },
        { [sortProperty.dataField]: sortProperty.direction },
        { 'name.keyword': 'asc' },
      ]
    : [{ _score: 'asc' }, { 'name.keyword': 'asc' }]

  const defaultQueryFn = () => ({
    query: {
      bool: {
        must_not: [
          {
            match: { queryVersion: refreshTicker },
          },
        ],
      },
    },
    sort,
  })

  const handleCloseProductManager = () => {
    setTargetProduct(null)
    setRefreshTicker(refreshTicker + 1)
  }

  const handleItemCreate = (item: ElasticProductTitle) => {
    if (isProcessing || !onItemCreate || !targetCatalog) {
      return new Promise<void>((reject) => reject())
    }

    setProcessing(true)

    return onItemCreate(item, targetCatalog.id)
      .then((product) => {
        setTargetCatalog(null)

        if (product) {
          setRefreshTicker(refreshTicker + 1)
          setTargetProduct({
            id: product.id,
            sellerId: product.catalog.company.id,
          })
        }
      })
      .finally(() => setProcessing(false))
  }

  const handleItemOpen = ({ catalog, id }: ElasticCatalogProduct) =>
    setTargetProduct({ id, sellerId: catalog.companyId })

  const handleItemRemove = (itemId: string) =>
    onItemRemove
      ? confirm({
          cancelLabel: <T>common:action.cancel</T>,
          confirmLabel: (
            <DangerColor>
              <T>common:action.remove</T>
            </DangerColor>
          ),
          description: (
            <T>Products:Confirmation.deleteCatalogProduct.description</T>
          ),
          title: <T>Products:Confirmation.deleteCatalogProduct.title</T>,
        })
          .then(() => onItemRemove(itemId))
          .then(() => setRefreshTicker(refreshTicker + 1))
          .catch(() => undefined)
      : new Promise<void>((reject) => reject())

  return (
    <>
      <ElasticFilterSearchList
        columnCount={7}
        indexName="registry.products"
        reactiveListProps={{
          componentId: ComponentIds.LIST,
          dataField: 'name',
          defaultQuery: defaultQueryFn,
          react: {
            and: [
              ComponentIds.CATALOG,
              ComponentIds.CATEGORY,
              ComponentIds.SEARCH,
            ],
          },
          size: 50,
        }}
        renderListControls={() => (
          <ListControls
            readOnly={readOnly}
            setTargetCatalog={setTargetCatalog}
          />
        )}
        renderListFilters={() => (
          <ListFilters
            componentIds={ComponentIds}
            getDefaultQuery={defaultQueryFn}
            URLParams
          />
        )}
        renderListHeader={() => <ListHeader />}
        renderListItem={(item: ElasticCatalogProduct) => (
          <ListItem
            data={item}
            key={`catalog-product-${item.id}`}
            onItemOpen={() => handleItemOpen(item)}
            onItemRemove={() => handleItemRemove(item.id)}
            readOnly={readOnly}
          />
        )}
        sortProps={{
          options: sortOptions,
          setValue: setSortProperty,
          value: sortProperty,
        }}
        title={<T>Products:CatalogProductList.title</T>}
      />

      {targetCatalog && (
        <ProductTitlePicker
          onItemSelect={handleItemCreate}
          onClose={() => setTargetCatalog(null)}
          sellerId={targetCatalog.sellerId}
        />
      )}

      {targetProduct && (
        <ModalContainer
          isOpen={!!targetProduct}
          modal={
            <CatalogProductManager
              onClose={handleCloseProductManager}
              productId={targetProduct.id}
              readOnly={readOnly}
              sellerId={targetProduct.sellerId}
            />
          }
          onClose={handleCloseProductManager}
          placement="bottom"
          referenceElement={() => null}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />
      )}
    </>
  )
}
