import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

import { SaleAccommodationBaseQuery } from '~generated-types'

import MainInfoPlaceholder from './MainInfoPlaceholder'

interface Props {
  room: SaleAccommodationBaseQuery['suitableRooms'][0]['roomReservation']
  isAllExpanded: boolean
}

type Participant =
  SaleAccommodationBaseQuery['suitableRooms'][0]['roomReservation']['participantRooms'][0]

const RoomPlaceholder = ({ room, isAllExpanded }: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(true)

  const roomParticipantsIds = useMemo(
    () => room.participantRooms.map(({ participant }) => participant.id),
    [room]
  )

  const uniqueIds = new Set(roomParticipantsIds)

  useEffect(() => {
    isAllExpanded !== isExpanded && setExpanded(isAllExpanded)
  }, [isAllExpanded])

  return (
    <Wrapper>
      <HeaderPlaceholder />
      {isExpanded && (
        <ContentWrapper>
          {Array.from(uniqueIds).map((id) => {
            const participantRoom: Participant | undefined =
              room.participantRooms.find(
                ({ participant }) => participant.id === id
              )
            return (
              <MainInfoPlaceholder
                key={id}
                rootRoomId={id}
                firstName={participantRoom?.participant?.firstName}
                lastName={participantRoom?.participant?.lastName}
              />
            )
          })}
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

export default RoomPlaceholder

const Wrapper = styled(FlexColumn)`
  margin-top: -1px;

  &:first-of-type > div:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-of-type > div:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const HeaderPlaceholder = styled.div`
  height: 40px;
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
  `}
`

const ContentWrapper = styled(FlexColumn)`
  border-top: 0;
  box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 9%);

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.smoke.lighter};
  `}

  & > div:last-of-type {
    margin-bottom: -1px;
  }
`
