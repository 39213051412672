import { useRouteDate, useRouteValue } from '@/utils/hooks'

import RoomReservationsListing from './RoomReservationsListing'
import { ReservationType } from './types'
import useRoomReservations from './useRoomReservations'

export const RoomReservationsListingContainer = () => {
  const { date, setDate } = useRouteDate()
  const { setValue: setType, value: type } = useRouteValue({
    routeKey: 'type',
    whitelist: [ReservationType.ARRIVING, ReservationType.DEPARTING],
  })
  const { arriving, departing, error, loading } = useRoomReservations({
    date,
  })

  return (
    <RoomReservationsListing
      arriving={arriving}
      date={date}
      departing={departing}
      error={error}
      loading={loading}
      setDate={setDate}
      setType={setType}
      type={(type as ReservationType) || ReservationType.ARRIVING}
    />
  )
}

export default RoomReservationsListingContainer
