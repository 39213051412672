import { gql } from '@apollo/client'

export default gql`
  query SpecialDiets {
    registry {
      diets {
        name
      }
    }
  }
`
