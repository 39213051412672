import { CSSProperties, useMemo, useState } from 'react'
import { OptionProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { Palette, Spacing, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { DimensionLabelValidationCategory } from '~generated-types'

import { useSetDimensionLabelsMutation } from '../../../mutations'
import { SalesDimension } from '../../../types'
import { Placeholder } from './common'
import { ValueWrapper } from './ValueWrapper'

type ValidationOption = SalesDimension['validatedOptions'][0]

type Props = {
  dimension: SalesDimension
  dimensions: SalesDimension[]
  salesId: string
}

export const Dimension = ({
  dimension: { dimension, selectedLabel, validatedOptions },
  dimensions,
  salesId,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { refresh } = salesHooks.useSalesDetailsContext()

  const [isEditing, setEditing] = useState(false)

  const [setLabels, { loading }] = useSetDimensionLabelsMutation()

  const onSetLabel = (
    option: Option | null | undefined,
    dimensionKey: string
  ) => {
    const selected = dimensions.map((d) => ({
      ...d,
      selectedLabel:
        d.dimension.id === dimensionKey
          ? option
            ? { ...d.selectedLabel, id: option.value, name: option.label }
            : null
          : d.selectedLabel,
    }))
    const update = selected.map((d) => ({
      dimensionId: d.dimension.id,
      labelId: d.selectedLabel ? d.selectedLabel.id : null,
    }))

    setLabels({ variables: { input: { salesId, update } } })
      .then(() => {
        refresh()
        setEditing(false)
      })
      .catch(() => undefined)
  }

  const validOptions = validatedOptions.filter(
    (l) => l.validationCategory === DimensionLabelValidationCategory.Valid
  )
  const invalidOptions = validatedOptions.filter(
    (l) => l.validationCategory === DimensionLabelValidationCategory.Invalid
  )

  const validSelectorOptions = transformToSelectorOptions(validOptions)
  const invalidSelectorOptions = transformToSelectorOptions(invalidOptions)

  const groupedOptions = [
    {
      label: <T>SalesDetails:dimensions.status.valid</T>,
      options: validSelectorOptions,
    },
    {
      label: <T>SalesDetails:dimensions.status.invalid</T>,
      options: invalidSelectorOptions,
    },
  ]

  const isInvalid = useMemo(
    () =>
      selectedLabel?.id
        ? !validatedOptions.find(
            (l) =>
              l.validationCategory === DimensionLabelValidationCategory.Valid &&
              l.label.id === selectedLabel.id
          )
        : dimension.required,
    [dimension, selectedLabel, validatedOptions]
  )

  return (
    <Wrapper flex={1}>
      <DimensionName>{dimension.name}</DimensionName>

      {validatedOptions.length ? (
        <FlexRow alignItems="center">
          {isEditing ? (
            <ThemedSelect
              autoFocus
              extraStyles={getExtraStyles(isInvalid, palette, spacing)}
              formatGroupLabel={GroupLabel}
              isClearable
              isCompact
              isLoading={loading}
              isSearchable
              menuIsOpen={isEditing}
              name="dimension-selector"
              noOptionsMessage={() => (
                <T>SalesDetails:dimensions.error.emptyLabels</T>
              )}
              onBlur={() => setEditing(false)}
              onChange={(v: Option | null | undefined) =>
                onSetLabel(v, dimension.id)
              }
              options={groupedOptions}
              placeholder="–"
              value={
                selectedLabel && {
                  label: selectedLabel.name,
                  value: selectedLabel.id,
                }
              }
            />
          ) : (
            <ValueWrapper
              label={selectedLabel?.name || ''}
              isInvalid={isInvalid}
              setEditing={setEditing}
            />
          )}
        </FlexRow>
      ) : (
        <PlaceholderWrapper>
          <Placeholder>
            <T>SalesDetails:dimensions.error.emptyLabels</T>
          </Placeholder>
        </PlaceholderWrapper>
      )}
    </Wrapper>
  )
}

//////

const transformToSelectorOptions = (options: ValidationOption[]) =>
  options
    .map((l) => ({
      label: l.label.name,
      searchValue: l.label.name || '',
      value: l.label.id,
    }))
    .sort(generateCompareFn('label'))

const getExtraStyles = (
  isInvalid: boolean,
  palette: Palette,
  spacing: Spacing
) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    '&:hover': {
      borderColor: isInvalid ? palette.danger.main : styles.borderColor,
    },
    borderColor: isInvalid ? palette.danger.main : styles.borderColor,
    boxShadow: isInvalid
      ? `0 0 0 1px ${palette.danger.main}`
      : styles.boxShadow,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 501,
  }),
  option: (
    styles: CSSProperties,
    { isSelected }: OptionProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    color: isInvalid && !isSelected ? palette.text.light : styles.color,
    cursor: 'pointer',
  }),
})

const GroupLabel = (group: { [key: string]: any }) => (
  <GroupLabelWrapper>
    <span>{group.label}</span>
    <GroupBadge>{group.options.length}</GroupBadge>
  </GroupLabelWrapper>
)

const GroupLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem 0;
  `}
`

const GroupBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    color: ${theme.palette.text.light};
    width: ${theme.spacing.gu(2.5)}rem;
    height: ${theme.spacing.gu(2.5)}rem;
    border-radius: ${theme.spacing.gu(1.25)}rem;
  `}
`

const DimensionName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.smoke.extraDark};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const PlaceholderWrapper = styled(FlexRow)`
  height: 30px;
`

const Wrapper = styled(FlexColumn)`
  max-width: 50%;
  min-width: 50%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
