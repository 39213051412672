/**
 * GENERATED
 */
import type { RoomReservationsListingRoomReservationFragment } from '~generated-types'

export type RoomReservation = RoomReservationsListingRoomReservationFragment
export type ParticipantReservation = RoomReservation['participantRooms'][0]
export type RoomReservationCheckIn = RoomReservation['request']['checkIn']
export type RoomReservationCheckOut = RoomReservation['request']['checkOut']
export type RoomReservationParticipant = ParticipantReservation['participant']
export type RoomReservationSales = RoomReservationParticipant['sales']
export type RoomReservationCustomer = NonNullable<
  RoomReservationSales['customer']
>['customer']

/**
 * MANUAL
 */
export enum ReservationType {
  ARRIVING = 'ARRIVING',
  DEPARTING = 'DEPARTING',
}
