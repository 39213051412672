import { gql } from '@apollo/client'

export default gql`
  fragment ParticipantTarget on AccommodationTarget {
    accommodationGroup {
      id
      name
      sortOrder
    }
    default
    id
    name
    sortOrder
  }
`
