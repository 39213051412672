import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { IconButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSaleParticipants } from '@/modules/ParticipantsList/hooks'
import { useTheme } from '@/theme'

import { RoomCleaningStatus, SaleAccommodationQuery } from '~generated-types'

import { AddParticipantAction } from './AddParticipantAction'
import { RoomCapacity } from './RoomCapacity'
import { RoomDates } from './RoomDates'
import { RoomNeeds } from './RoomNeeds'
import { RoomNumber } from './RoomNumber'
import { RoomTarget } from './RoomTarget'

type Props = {
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']
  isExpanded: boolean
  readOnly?: boolean
  setIsExpanded: (isExpanded: boolean) => void
  handleDeleteRoom: (id: string) => Promise<any>
}

const RoomViewHeader = ({
  room,
  isExpanded,
  readOnly,
  setIsExpanded,
  handleDeleteRoom,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { confirm } = useDialogService()
  const { handleTargetRoom } = useSaleParticipants()

  return (
    <HeaderWrapper style={{ alignItems: 'stretch' }}>
      <FlexRow justifyContent={'flex-start'} flex={1}>
        <RoomNumber
          room={room.request.room}
          targetId={room.target?.id}
          groupId={room.target?.accommodationGroup.id}
          reservationId={room.id}
          readOnly={readOnly}
        />

        <FlexColumn
          justifyContent={'center'}
          alignItems={'center'}
          style={{
            margin: `0 ${spacing.gu(1)}rem`,
            width: `${spacing.gu(4)}rem`,
          }}
        >
          {moment().isSame(room.request.checkIn.date, 'day') ||
          moment().isSame(room.request.checkOut.date, 'day') ? (
            <FontAwesomeIcon
              color={
                room.request.room.status.cleaningStatus ===
                RoomCleaningStatus.Clean
                  ? palette.success.main
                  : palette.danger.main
              }
              fixedWidth
              icon="broom"
            />
          ) : (
            moment().isAfter(room.request.checkIn.date, 'day') &&
            moment().isBefore(room.request.checkOut.date, 'day') && (
              <FontAwesomeIcon
                color={palette.coal.light}
                fixedWidth
                icon="user"
              />
            )
          )}
        </FlexColumn>

        <RoomCapacity room={room} />

        <RoomTarget
          groupId={room.target?.accommodationGroup.id || ''}
          target={room.target}
          readOnly={readOnly}
          targetAccommodation={(targetId: string) =>
            handleTargetRoom({ roomReservationId: room.id, targetId })
          }
        />

        <RoomDates
          checkIn={room.request.checkIn}
          checkOut={room.request.checkOut}
          id={room.id}
          roomNumber={room.request.room.number}
        />

        <FlexRow
          alignItems={'stretch'}
          flex={1}
          style={{ minWidth: '10rem', width: '10rem' }}
        >
          <AddParticipantAction
            room={room}
            readOnly={readOnly}
            setIsExpanded={setIsExpanded}
          />
        </FlexRow>

        <RoomNeeds room={room} readOnly={readOnly} />

        <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
          <FontAwesomeIcon
            color={palette.text.light}
            fixedWidth
            icon="user-group"
            size="sm"
          />
          <FontAwesomeIcon
            color={palette.text.light}
            fixedWidth
            icon={isExpanded ? 'arrow-up' : 'arrow-down'}
            size="sm"
          />
        </ExpandButton>

        <FlexRow alignItems="center">
          <IconButton
            disabled={readOnly}
            color="transparent"
            icon="trash"
            onClick={() =>
              confirm({
                cancelLabel: <T>common:action.cancel</T>,
                confirmLabel: (
                  <DangerColor>
                    <T>common:action.remove</T>
                  </DangerColor>
                ),
                description: (
                  <T>
                    RoomLayout:sidebar.confirmRoomReservationRemoval.description
                  </T>
                ),
              })
                .then(() => handleDeleteRoom(room.id))
                .catch(() => undefined)
            }
            style={{
              color: palette.danger.main,
            }}
            tabIndex="-1"
          />
        </FlexRow>
      </FlexRow>
    </HeaderWrapper>
  )
}

export default RoomViewHeader

const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
  `}
`

const ExpandButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
