import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { AccommodationTargetFragment } from '~generated-types'

type Props = {
  target: AccommodationTargetFragment
  selectAllForGroup?: () => void
}

const GroupTitle = ({ target, selectAllForGroup }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing, typography } = useTheme()

  return (
    <FlexRow
      justifyContent="space-between"
      alignItems="center"
      style={{ width: '100%' }}
    >
      <FontWeight
        style={{
          padding: `${spacing.gutterSmall} ${spacing.gu(3)}rem`,
        }}
      >
        <FontAwesomeIcon
          color={palette.primary.main}
          fixedWidth
          size="sm"
          icon="user-group"
          style={{ marginRight: `${spacing.gu(1)}rem` }}
        />
        {target.default
          ? translate('Accommodation:TargetGroup.default', language)
          : target.name
            ? target.name
            : `${translate(`Accommodation:TargetGroup.target`, language)} #${
                target.sortOrder
              }`}
        {`${!!target ? ' – ' : ''} ${
          target
            ? target?.accommodationGroup?.name
              ? target.accommodationGroup.name
              : `${translate(
                  `ParticipantsList:ParticipantFormFields.group`,
                  language
                )} #${target.accommodationGroup.sortOrder}`
            : ''
        }`}
        {target.private && (
          <FontAwesomeIcon
            color={palette.primary.main}
            fixedWidth
            size="sm"
            icon="lock"
            style={{ marginLeft: `${spacing.gu(1)}rem` }}
          />
        )}
      </FontWeight>
      {!!selectAllForGroup && (
        <InnocuousButton
          noNudge
          compact
          size="small"
          onClick={selectAllForGroup}
        >
          <FontAwesomeIcon
            color={palette.primary.main}
            fixedWidth
            size="sm"
            icon="check"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <PrimaryColor style={{ fontSize: typography.fontSizeSmall }}>
            <T>Accommodation:SalesReservationList.selectAllRooms</T>
          </PrimaryColor>
        </InnocuousButton>
      )}
    </FlexRow>
  )
}

export default GroupTitle
