import { gql } from '@apollo/client'

import { productFragments } from '@/modules/Products/fragments'

import participantRoom from './participantRoom'
import participantTarget from './participantTarget'

export default gql`
  ${participantRoom}
  ${participantTarget}
  ${productFragments.productPurchase}

  fragment ServiceParticipantBed on ServiceParticipantBed {
    accommodationTarget {
      ...ParticipantTarget
    }
    dates {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
    }
    id
    participantRoom {
      ...ParticipantRoom
    }
    purchaseProduct {
      ...ProductPurchase
    }
  }
`
