import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

type Props = {
  content: ReactNode
  icon?: IconProp
}

export const AgGridPlaceholder = ({ content, icon }: Props) => (
  <div>
    {icon && <Icon icon={icon} size="3x" />}
    <Content>{content}</Content>
  </div>
)

/////////////

const Content = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.extraDark};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`
