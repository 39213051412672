import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { RoomList } from './components/RoomList'

export const RoomRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <RoomList />
      </Route>

      {/* TODO: Create / edit –views */}
    </Switch>
  )
}

export default RoomRoutes
