import { useState } from 'react'
import styled, { css } from 'styled-components'

import { ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { SalesDetails } from '@/modules/Sales/components/SalesDetails/types'
import { generateCompareFn } from '@/utils/arrays'

import { HelperText } from '../../common'
import { useGuestCommunicationContext } from '../GuestCommunicationState'

type Props = {
  salesId: string
  saleCommunicationSettings: SalesDetails['communicationSettings']
  saleReadOnly: boolean
}
export const TemplateSelect = ({
  saleCommunicationSettings,
  salesId,
  saleReadOnly,
}: Props) => {
  const { communicationTemplates, setGuestCommunication } =
    useGuestCommunicationContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSetGuestCommunicationTemplate = (id?: string) => {
    setIsLoading(true)
    setGuestCommunication({
      communicationTemplateId: id || null,
      salesId,
    }).finally(() => setIsLoading(false))
  }

  const communicationTemplatesOptions =
    communicationTemplates
      ?.map((template) => ({
        label: template.name,
        value: template.id,
      }))
      .sort(generateCompareFn('label')) || []

  const helperText = saleCommunicationSettings?.template?.description || ''

  return (
    <>
      <ThemedSelect
        disabled={saleReadOnly}
        isCompact
        isClearable
        name="Template"
        placeholder={<T>GuestCommunication:template.placeholder</T>}
        isLoading={isLoading}
        onChange={(value) => handleSetGuestCommunicationTemplate(value?.value)}
        options={communicationTemplatesOptions}
        value={
          saleCommunicationSettings?.template
            ? {
                label: saleCommunicationSettings.template.name,
                value: saleCommunicationSettings.template.id,
              }
            : null
        }
      />

      <HelperText>
        {helperText && (
          <Label>
            <T>GuestCommunication:template.subtitle</T> -
          </Label>
        )}{' '}
        {helperText}
      </HelperText>
    </>
  )
}

/////////

const Label = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
  `}
`
