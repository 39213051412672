import { gql } from '@apollo/client'

import participantVst from './participantVst'

export default gql`
  ${participantVst}

  fragment ParticipantPersonalData on SalesParticipant {
    accommodationRequest
    additionalInfo
    age
    ageCategory {
      abbreviation
      key
      name
    }
    ageSource
    allergyDescription
    allergies
    auditLog {
      createdAt
    }
    birthday {
      date
      month
      year
    }
    diets
    drugAllergyDescription
    education
    email
    firstName
    gender
    group
    id
    language
    lastName
    lifeStage
    nationality
    postalAddress {
      address1
      address2
      city
      country
      postcode
    }
    roomFriends
    sales {
      id
      lifecycle {
        state {
          key
          names {
            locale
            content
          }
          systemState
        }
      }
      name
      orderNumber
      type
    }
    sortOrder
    visitStatus {
      dayVisitorDates {
        end
        start
      }
      isDayVisitor
    }
    vst {
      ...ParticipantVst
    }
  }
`
