import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { emailRegex } from '@/utils/forms'

import { AddressSection } from './components/AddressSection'
import { CountrySelector } from './components/CountrySelector'
import { LanguageSelector } from './components/LanguageSelector'

type AddressType = {
  address1: string | null
  address2: string | null
  city: string | null
  postcode: string | null
  country: string | null
}

type Props = {
  address?: FetchedParticipant['postalAddress']
  email: string
  nationality: string
  participantLanguage: string
  handleUpdateAddress: (
    address: AddressType
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateLanguage: (
    language: string | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateNationality: (
    nationality: string | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateEmail: (
    email: string | null
  ) => Promise<FetchedParticipant | undefined>
}

export const AddressModal = ({
  address,
  email,
  nationality,
  participantLanguage,
  handleUpdateAddress,
  handleUpdateLanguage,
  handleUpdateNationality,
  handleUpdateEmail,
}: Props) => {
  const { language } = useLanguageContext()
  const { address1, address2, city, postcode, country } = address || {}

  const [emailState, setEmail] = useState<string>(email)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [addressState, setAddress] = useState<AddressType>({
    address1: address1 || null,
    address2: address2 || null,
    city: city || null,
    country: country || null,
    postcode: postcode || null,
  })

  const onAddressChange = (addressField: Partial<AddressType>) =>
    setAddress({ ...addressState, ...addressField })
  const onSubmit = () => handleUpdateAddress(addressState)

  const onEmailChange = (email: string) => {
    setEmail(email.trim())
  }

  const onEmailSubmit = () => {
    if (!emailState || emailRegex.test(emailState)) {
      setEmailError(false)
      handleUpdateEmail(emailState)
    } else {
      setEmailError(true)
    }
  }

  return (
    <ModalContainer>
      <Section>
        <FlexColumn noPadding flex={1}>
          <LanguageSelector
            handleSetLanguage={handleUpdateLanguage}
            participantLanguage={participantLanguage}
            title={translate(
              'ParticipantsList:PersonalFields.language',
              language
            )}
          />
          <CountrySelector
            countryName={nationality}
            handleSetCountry={(nationality) => {
              handleUpdateNationality(nationality)
            }}
            title={translate(
              'ParticipantsList:PersonalFields.nationality',
              language
            )}
          />
          <CountrySelector
            countryName={addressState?.country}
            handleSetCountry={(country) => {
              onAddressChange({ country })
              handleUpdateAddress({
                ...addressState,
                country,
              })
            }}
            title={translate('Customers:address.country', language)}
          />
          <AddressSection
            onChange={(address1) => onAddressChange({ address1 })}
            onSubmit={onSubmit}
            title={translate('Customers:address.address', language)}
            type="textarea"
            value={addressState?.address1 || ''}
          />
          <AddressSection
            onChange={(address2) => onAddressChange({ address2 })}
            onSubmit={onSubmit}
            title={translate('Customers:address.address2', language)}
            type="textarea"
            value={addressState?.address2 || ''}
          />
          <RowWrapper>
            <AddressSection
              flex={2}
              onChange={(postcode) => onAddressChange({ postcode })}
              onSubmit={onSubmit}
              title={translate('Customers:address.postcode', language)}
              value={addressState?.postcode || ''}
            />
            <Spacer />
            <AddressSection
              flex={3}
              onChange={(city) => onAddressChange({ city })}
              onSubmit={onSubmit}
              title={translate('Customers:address.city', language)}
              value={addressState?.city || ''}
            />
          </RowWrapper>
          <RowWrapper>
            <AddressSection
              flex={1}
              onChange={(email) => onEmailChange(email)}
              onSubmit={onEmailSubmit}
              title={translate('Customers:email', language)}
              value={emailState}
              helperText={
                emailError
                  ? translate('Common:validations.email.invalid', language)
                  : ''
              }
            />
          </RowWrapper>
        </FlexColumn>
      </Section>
    </ModalContainer>
  )
}

const ModalContainer = styled(InlineModal)`
  max-height: 100vh;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(65)}rem;
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const RowWrapper = styled(FlexRow)`
  width: 100%;
`

const Section = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter} 0;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
