import { ColDef, ColGroupDef } from 'ag-grid-community'

import { AgGridSearchList } from '@/components/AgGrid'
import { naturalSortComparator } from '@/components/AgGrid/utils'
import { translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { roomHooks } from '../hooks'
import { RoomFeature } from '../types'
import { Feature } from './Feature'

export const RoomList = () => {
  const { language } = useLanguageContext()

  const { error, loading, rooms } = roomHooks.useRooms()

  const columns: (ColDef | ColGroupDef)[] = [
    {
      comparator: naturalSortComparator,
      field: 'id',
      flex: 1,
      headerName: translate('Room:field.id', language),
    },
    {
      comparator: naturalSortComparator,
      field: 'number',
      flex: 2,
      headerName: translate('Room:field.number', language),
      sort: 'asc',
    },
    {
      comparator: naturalSortComparator,
      field: 'beds',
      flex: 1,
      headerName: translate('Room:field.beds', language),
      valueGetter: (params) => `${params.data.beds} + ${params.data.extraBeds}`,
    },
    {
      comparator: naturalSortComparator,
      field: 'floor',
      flex: 1,
      headerName: translate('Room:field.floor', language),
      valueGetter: (params) =>
        params.data.floor ? `${params.data.floor}` : '–',
    },
    {
      cellRenderer: (params: { data: { features: RoomFeature[] } }) =>
        params.data.features.length
          ? [...params.data.features]
              .sort(generateCompareFn('name'))
              .map((feature) => <Feature feature={feature} key={feature.id} />)
          : '–',
      field: 'features',
      flex: 2,
      getQuickFilterText: (params) =>
        params.data.features.map(({ name }: RoomFeature) => name),
      headerName: translate('Room:field.features', language),
      valueFormatter: (params) => params.data,
    },
    {
      field: 'building',
      flex: 2,
      headerName: translate('Room:field.building', language),
      valueGetter: (params) => params.data.building.name,
    },
    {
      field: 'roomType',
      flex: 2,
      headerName: translate('Room:field.roomType', language),
      valueGetter: (params) => params.data.roomType.name,
    },
  ]

  return (
    <AgGridSearchList
      columnDefs={columns}
      error={!!error}
      loading={loading}
      rowData={rooms}
      title={translate('Room:title', language)}
    />
  )
}
