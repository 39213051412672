import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'

import { NotFoundPage, PageRoute } from '@/components/Page'
import { globalRoutes } from '@/config/constants'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { InvoiceSearchList } from '@/modules/InvoicesList'
import { EnrollmentsSearchList } from '@/modules/Sales/components/EnrollmentsSearchList'
import { SalesSearchList } from '@/modules/Sales/components/SalesSearchList'
import { TaskSearchList } from '@/modules/Task'

import { NavInternalViewType as Type, NavItemType } from '~generated-types'

export const SearchRoutes = () => {
  const { path } = useRouteMatch()
  const { allNavDropdownInternalTypes } = useWillbaConfigContext()

  return (
    <Switch>
      <Redirect exact from={path} to={globalRoutes[Type.SearchSales]} />

      {allNavDropdownInternalTypes.includes(Type.SearchSales) && (
        <PageRoute
          accessKey={Type.SearchSales}
          component={SalesList}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Search}`,
            `Navigation:navDropdownItem.${Type.SearchSales}`,
          ]}
          path={globalRoutes[Type.SearchSales]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.SearchEnrollment) && (
        <PageRoute
          accessKey={Type.SearchEnrollment}
          component={EnrollmentsList}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Search}`,
            `Navigation:navDropdownItem.${Type.SearchEnrollment}`,
          ]}
          path={globalRoutes[Type.SearchEnrollment]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.SearchTask) && (
        <PageRoute
          accessKey={Type.SearchTask}
          component={TasksList}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Search}`,
            `Navigation:navDropdownItem.${Type.SearchTask}`,
          ]}
          path={globalRoutes[Type.SearchTask]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.SearchInvoice) && (
        <PageRoute
          accessKey={Type.SearchInvoice}
          component={InvoicesList}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Search}`,
            `Navigation:navDropdownItem.${Type.SearchInvoice}`,
          ]}
          path={globalRoutes[Type.SearchInvoice]}
        />
      )}

      <PageRoute component={NotFoundPage} pageTitle="404" />
    </Switch>
  )
}

////////////

const ListWrapper = styled.div`
  flex: 1;
  overflow: auto;
`

const SalesList = () => (
  <ListWrapper>
    <SalesSearchList />
  </ListWrapper>
)
const EnrollmentsList = () => (
  <ListWrapper>
    <EnrollmentsSearchList />
  </ListWrapper>
)
const TasksList = () => (
  <ListWrapper>
    <TaskSearchList />
  </ListWrapper>
)
const InvoicesList = () => (
  <ListWrapper>
    <InvoiceSearchList />
  </ListWrapper>
)
