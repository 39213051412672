import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { useParticipantsListContext } from '@/modules/ParticipantsList/ParticipantsListState'
import { FilterType } from '@/modules/ParticipantsList/types'

import { SalesType } from '~generated-types'

export const FilterChips = () => {
  const { language } = useLanguageContext()
  const { filters, saleType, setFilters } = useParticipantsListContext()

  const { search, ...restFilters } = filters || {}

  return (
    <FlexRow>
      {Object.keys(restFilters).map((k) => {
        if (k === 'enrollmentStates' && saleType !== SalesType.Event) {
          return null
        }

        const key = k as keyof typeof restFilters
        const filter = restFilters[key] as FilterType[] | null | undefined

        if (!filter) {
          return null
        }

        const name = translate(`ParticipantsList:filters.type.${key}`, language)
        const value = filter.length
          ? filter.map(({ label }) => label).join(', ')
          : translate('ParticipantsList:filters.notSelected', language)

        return (
          <Chip alignItems="center" key={`chip-${key}`}>
            {`${name}: ${value}`}
            <IconButton onClick={() => setFilters({ ...filters, [key]: null })}>
              <FontAwesomeIcon icon="xmark" />
            </IconButton>
          </Chip>
        )
      })}
    </FlexRow>
  )
}

////////

const Chip = styled(FlexRow)`
  height: 30px;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.lighter};
    border: 1px solid ${theme.palette.smoke.main};
    padding-left: ${theme.spacing.gu(1.5)}rem;
    margin: ${theme.spacing.gu(0.5)}rem 0;
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const IconButton = styled(FlexRow)`
  cursor: pointer;
  height: 28px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
    margin-left: ${theme.spacing.gu(1.5)}rem;
    padding: 0 ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`
