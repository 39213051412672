import { gql } from '@apollo/client'

export default gql`
  fragment AccommodationSettings on AccommodationGroup {
    id
    settings {
      consumptionType
      consumptionDurationType
    }
    status {
      available
      dates {
        checkIn {
          date
        }
        checkOut {
          date
        }
      }
      reserved
      reservedDetailed {
        rooms
        beds
        extraBeds
      }
      type
      status {
        available
        date
        reserved
        reservedDetailed {
          rooms
          beds
          extraBeds
        }
      }
    }
  }
`
