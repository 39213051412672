import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { FontWeight, P } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { AccommodationTargetFragment } from '~generated-types'

type Props = {
  target: AccommodationTargetFragment
}

const TargetLabel = ({ target }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  return (
    <FlexRow alignItems={'center'} justifyContent="flex-start">
      <FontAwesomeIcon
        color={target?.private ? palette.primary.main : 'transparent'}
        fixedWidth
        size="sm"
        icon="lock"
        style={{ marginRight: `${spacing.gu(1)}rem` }}
      />

      <TargetName>
        {target.default
          ? translate('Accommodation:TargetGroup.default', language)
          : target.name
            ? target.name
            : `${translate(`Accommodation:TargetGroup.target`, language)} #${
                target.sortOrder
              }`}
      </TargetName>
      <P style={{ margin: `0 ${spacing.gu(1)}rem` }}>
        <T>Accommodation:TargetGroup.rooms</T>:{' '}
        <FontWeight bold>
          {target.status.status.reservedCapacity.rooms}
        </FontWeight>
      </P>

      <FlexRow style={{ margin: `0 ${spacing.gu(1)}rem` }}>
        <FontAwesomeIcon
          fixedWidth
          icon={'bed'}
          size="sm"
          style={{ marginRight: '0.5rem' }}
        />
        <FontWeight normal>
          {target.status.status.reservedCapacity.beds} +{' '}
          {target.status.status.reservedCapacity.extraBeds}
        </FontWeight>
      </FlexRow>
    </FlexRow>
  )
}

export default TargetLabel

const TargetName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(30)}rem;
  `}
`
