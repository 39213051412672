import participant from './participant'
import participantPersonalData from './participantPersonalData'
import participantRoom from './participantRoom'
import participantRoomReservation from './participantRoomReservation'
import participantService from './participantService'
import participantTarget from './participantTarget'
import participantVst from './participantVst'
import serviceParticipantBed from './serviceParticipantBed'
import servicePurchase from './servicePurchase'

export const participantFragments = Object.freeze({
  participant,
  participantPersonalData,
  participantRoom,
  participantRoomReservation,
  participantService,
  participantTarget,
  participantVst,
  serviceParticipantBed,
  servicePurchase,
})
