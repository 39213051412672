import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import { AddressModal } from './AddressModal'

type AddressType = {
  address1: string | null
  address2: string | null
  city: string | null
  postcode: string | null
  country: string | null
}

type Props = {
  address?: FetchedParticipant['postalAddress']
  email: string
  nationality: string
  participantLanguage: string
  handleUpdateAddress: (
    address: AddressType
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateLanguage: (
    language: string | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateNationality: (
    nationality: string | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateEmail: (
    nationality: string | null
  ) => Promise<FetchedParticipant | undefined>
  readOnly?: boolean
}

const Address = ({
  address,
  email,
  nationality,
  participantLanguage,
  handleUpdateAddress,
  handleUpdateLanguage,
  handleUpdateNationality,
  handleUpdateEmail,
  readOnly,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { address1, city, postcode, country } = address || {}

  const [isModalOpen, setModalOpen] = useState(false)

  const isEmptyAddress = !address1 && !city && !postcode && !country && !email

  return (
    <>
      <ModalContainer
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            ref={ref}
            disabled={readOnly}
            minWidth={`${spacing.gu(16)}rem`}
            onClick={readOnly ? () => null : () => setModalOpen(true)}
            renderContent={() =>
              !isEmptyAddress || nationality ? (
                <>
                  {!isEmptyAddress && (
                    <AddressWrapper noPadding>
                      <AddressSpan>{address1}</AddressSpan>
                      <AddressSpan>
                        {[country, postcode, city, email]
                          .filter(Boolean)
                          .join(', ')}
                      </AddressSpan>
                    </AddressWrapper>
                  )}
                  {nationality && isEmptyAddress && (
                    <AddressSpan>{nationality}</AddressSpan>
                  )}
                </>
              ) : (
                <>
                  <Label>
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      icon={'plus'}
                      style={{ marginRight: `${spacing.gu(1)}rem` }}
                    />
                    <T>ParticipantsList:PersonalFields.addressAndCountry</T>
                  </Label>
                </>
              )
            }
          />
        )}
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        modal={
          <AddressModal
            address={address}
            nationality={nationality}
            email={email}
            participantLanguage={participantLanguage}
            handleUpdateAddress={handleUpdateAddress}
            handleUpdateLanguage={handleUpdateLanguage}
            handleUpdateNationality={handleUpdateNationality}
            handleUpdateEmail={handleUpdateEmail}
          />
        }
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

export default Address

const AddressWrapper = styled(FlexColumn)`
  max-width: 100%;
`

const AddressSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`
