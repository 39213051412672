import { gql } from '@apollo/client'

export default gql`
  fragment ParticipantRoomReservation on RoomReservation {
    id
    request {
      checkIn {
        date
      }
      checkOut {
        date
      }
      features {
        icon
        id
        name
        shortName
      }
      info
      room {
        id
        number
        building {
          id
          name
        }
        roomType {
          id
          name
        }
      }
    }
    roomTypeReservation {
      request {
        features {
          icon
          id
          name
          shortName
        }
        info
      }
    }
  }
`
