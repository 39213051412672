import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { NotFoundPage, PageRoute } from '@/components/Page'
import { globalRoutes } from '@/config/constants'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { AvailabilityCalendar } from '@/modules/Accommodation/AvailabilityCalendar'

import { NavInternalViewType as Type, NavItemType } from '~generated-types'

import { ReservationCalendar } from './components/ReservationCalendar'
import { RoomLayout } from './components/RoomLayout'

export const ReservationRoutes = () => {
  const { path } = useRouteMatch()
  const { allNavDropdownInternalTypes } = useWillbaConfigContext()

  return (
    <Switch>
      <Redirect
        exact
        from={path}
        to={globalRoutes[Type.BookingResourceCalendar]}
      />

      {allNavDropdownInternalTypes.includes(Type.BookingResourceCalendar) && (
        <PageRoute
          accessKey={Type.BookingResourceCalendar}
          component={ReservationCalendar}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Booking}`,
            `Navigation:navDropdownItem.${Type.BookingResourceCalendar}`,
          ]}
          path={globalRoutes[Type.BookingResourceCalendar]}
        />
      )}

      {allNavDropdownInternalTypes.includes(
        Type.BookingAccommodationReservation
      ) && (
        <PageRoute
          accessKey={Type.BookingAccommodationReservation}
          component={AvailabilityCalendar}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Booking}`,
            `Navigation:navDropdownItem.${Type.BookingAccommodationReservation}`,
          ]}
          path={globalRoutes[Type.BookingAccommodationReservation]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.BookingRoomLayout) && (
        <PageRoute
          accessKey={Type.BookingRoomLayout}
          component={RoomLayout}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Booking}`,
            `Navigation:navDropdownItem.${Type.BookingRoomLayout}`,
          ]}
          path={globalRoutes[Type.BookingRoomLayout]}
        />
      )}

      <PageRoute component={NotFoundPage} pageTitle="404" />
    </Switch>
  )
}
