import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  SalesParticipantCreateMutation,
  SalesParticipantCreateMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantCreateMutation,
  SalesParticipantCreateMutationVariables,
}

export const SALES_PARTICIPANT_CREATE = gql`
  ${participantFragments.participant}

  mutation SalesParticipantCreate($input: SalesParticipantCreateInput!) {
    salesParticipantCreate(input: $input) {
      ...Participant
    }
  }
`

export function useSalesParticipantCreate() {
  return useMutation<
    SalesParticipantCreateMutation,
    SalesParticipantCreateMutationVariables
  >(SALES_PARTICIPANT_CREATE)
}

export default useSalesParticipantCreate
