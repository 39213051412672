import { gql } from '@apollo/client'

export default gql`
  query AccommodationTargets($salesId: ID!) {
    sales(id: $salesId) {
      accommodation {
        accommodationGroups {
          id
          name
          sortOrder
          targets {
            default
            id
            name
            sortOrder
          }
        }
        id
      }
      id
    }
  }
`
