import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import type { SalesProductItemUnitPrice } from '@/modules/Products/types'

import { PriceType as PT } from '~generated-types'

type Props = {
  id: string
  unitPrice: SalesProductItemUnitPrice
}

export const VatIncludedSection = ({ id, unitPrice: { type } }: Props) => {
  const { readOnly, updateProductItem } = useCatalogProductManagerContext()

  const handleUpdatePriceType = () =>
    updateProductItem({
      id,
      unitPrice: {
        type: type === PT.VatIncluded ? PT.VatExcluded : PT.VatIncluded,
      },
    })

  return (
    <Wrapper>
      <Title>
        <T>common:pricing.vatIncluded</T>
      </Title>
      <CheckboxInputWrapper>
        <CheckboxInput
          checked={type === PT.VatIncluded}
          disabled={readOnly}
          noMargin
          onChange={handleUpdatePriceType}
        />
      </CheckboxInputWrapper>
    </Wrapper>
  )
}

/////////

const CheckboxInputWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(8)}rem;
  `}
`
