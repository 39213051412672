import styled, { css } from 'styled-components/macro'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { useRoomReservationsContext } from '../../../DailyRoomReservationsState'
import { Participant } from '../../../types'

export const ParticipantFilter = () => {
  const {
    availableParticipants,
    loading,
    selectedParticipant,
    setSelectedParticipant,
  } = useRoomReservationsContext()

  const getParticipantOption = (participant: Participant) => {
    const { id, firstName, lastName } = participant

    return {
      label: getParticipantLabel({ participant }),
      searchValue: `${lastName}-${firstName}`,
      value: id,
    }
  }

  const options = [...availableParticipants]
    .sort(generateCompareFn('lastName'))
    .map(getParticipantOption)

  const selectedValue = selectedParticipant
    ? {
        ...getParticipantOption(selectedParticipant),
        label: getParticipantLabel({
          isCompact: true,
          participant: selectedParticipant,
        }),
      }
    : null

  return (
    <FilterSection
      label={<T>FrontDesk:DailyRoomReservations.participants.title</T>}
      render={() => (
        <ThemedSelect
          isLoading={loading}
          isClearable
          isSearchable
          name="room-reservations-participant-selector"
          noOptionsMessage={() => (
            <T>FrontDesk:DailyRoomReservations.participants.noOptions</T>
          )}
          onChange={(selected?: Option | null) =>
            setSelectedParticipant(
              availableParticipants?.find(({ id }) => id === selected?.value) ??
                null
            )
          }
          options={options}
          placeholder={<T>FrontDesk:DailyRoomReservations.all</T>}
          value={selectedValue}
        />
      )}
    />
  )
}

///////

const getParticipantLabel = ({
  isCompact,
  participant: { firstName, lastName },
}: {
  isCompact?: boolean
  participant: Participant
}) => (
  <NoWrapSpan isCompact={isCompact}>
    {!firstName && !lastName ? (
      <Placeholder>
        <T>FrontDesk:DailyRoomReservations.participants.lastName</T>{' '}
        <T>FrontDesk:DailyRoomReservations.participants.firstName</T>
      </Placeholder>
    ) : (
      `${lastName}${firstName && lastName ? ' ' : ''}${firstName}`
    )}
  </NoWrapSpan>
)

const NoWrapSpan = styled.span<{ isCompact?: boolean }>`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isCompact, theme }) => css`
    width: ${theme.spacing.gu(isCompact ? 24 : 32)}rem;
  `};
`

const Placeholder = styled.span`
  font-style: italic;
`
