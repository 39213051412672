import { FC, ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Media from 'react-media'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { useKeycloakContext } from '@/config/keycloak'
import { T } from '@/modules/Language'

import { NavInternalViewType } from '~generated-types'

import { NavbarButtonStyles } from '../common'
import { NewSaleModal } from './NewSaleModal'

export const NewSaleButton: FC = (): ReactElement => {
  const { hasNavViewAccess } = useKeycloakContext()

  const [isOpen, setOpen] = useState<boolean>(false)

  const hasAccess = hasNavViewAccess(NavInternalViewType.SearchSales)

  const renderLabel = () => (
    <Media query={{ minWidth: 1050 }}>
      {(matches) =>
        matches && (
          <Label>
            <T>Sales:action.newSale</T>
          </Label>
        )
      }
    </Media>
  )

  return hasAccess ? (
    <ModalContainer
      isOpen={isOpen}
      modal={<NewSaleModal onClose={() => setOpen(false)} />}
      onClose={() => setOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <ButtonWrapper ref={ref}>
          <Button
            activeClassName="is-active"
            to={'/'}
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }}
          >
            <FontAwesomeIcon icon="plus" />

            {renderLabel()}
          </Button>
        </ButtonWrapper>
      )}
    />
  ) : (
    <Tooltip
      content={<T>common:error.access.title</T>}
      placement="bottom"
      trigger={(triggerProps) => (
        <ButtonWrapper {...triggerProps}>
          <Button activeClassName="is-active" disabled to="">
            <FontAwesomeIcon
              icon="lock"
              size="sm"
              style={{ marginBottom: 2 }}
            />

            {renderLabel()}
          </Button>
        </ButtonWrapper>
      )}
    />
  )
}

////////////

const Button = styled(NavLink)`
  ${NavbarButtonStyles}
`

const ButtonWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const Label = styled.span`
  font-weight: 700;
  user-select: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
