import { gql } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

export default gql`
  ${accommodationFragments.accommodationTarget}

  query AccommodationGroupTargets($id: ID!) {
    accommodationGroup(id: $id) {
      id
      targets {
        ...AccommodationTarget
      }
    }
  }
`
