import {
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom'

import { Gutter } from '@/components/Layout'

import { CreateCustomer } from './components/CreateCustomer'
import { CustomerList } from './components/CustomerList'
import { EditCustomer } from './components/EditCustomer'

export const CustomerRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={WrappedCustomerList} exact path={path} />

      {/* Create / edit –views */}
      <Route component={WrappedCreateCustomerForm} path={`${path}/new`} />
      <Route
        component={WrappedEditCustomerForm}
        path={`${path}/:customerNumber`}
      />
    </Switch>
  )
}

export default CustomerRoutes

////////////

const STORAGE_KEY = 'CustomerList.previousSearch'

const getStoredSearch = () => sessionStorage.getItem(STORAGE_KEY) ?? ''

const storeSearch = () => {
  // We need to use window.location as the search in react router is not
  // up to date with ReactiveSearch
  const search = window.location.href.split('?')[1] ?? ''

  sessionStorage.setItem(STORAGE_KEY, search)
}

const WrappedCreateCustomerForm = ({
  history,
  location,
}: RouteComponentProps) => {
  const previousSearch = getStoredSearch()
  const baseRoute = location.pathname.split('/new')[0]
  const backURL = `${baseRoute}${previousSearch ? `?${previousSearch}` : ''}`

  return (
    <Gutter type={[0, 5, 10]}>
      <CreateCustomer
        onCreated={({ customerNumber }) =>
          history.replace(`${baseRoute}/${customerNumber}`)
        }
        onNavigateBack={() => history.push(backURL)}
      />
    </Gutter>
  )
}

const WrappedEditCustomerForm = ({
  history,
  location,
  match,
}: RouteComponentProps<{ customerNumber: string }>) => {
  const customerNumber =
    match?.params?.customerNumber || 'invalid-customer-number'
  const previousSearch = getStoredSearch()
  const listURL = location.pathname.split(`/${customerNumber}`)[0]
  const backURL = `${listURL}${previousSearch ? `?${previousSearch}` : ''}`

  return (
    <Gutter type={[0, 5, 10]} style={{ flex: 1 }}>
      <EditCustomer
        customerNumber={customerNumber}
        onNavigateBack={() => history.push(backURL)}
      />
    </Gutter>
  )
}

const WrappedCustomerList = ({ history, location }: RouteComponentProps) => (
  <Gutter type={[0, 5, 10]}>
    <CustomerList
      onAdd={() => {
        storeSearch()
        history.push(`${location.pathname}/new`)
      }}
      onOpen={({ customerNumber }) => {
        storeSearch()
        history.push(`${location.pathname}/${customerNumber}`)
      }}
    />
  </Gutter>
)
