import { gql } from '@apollo/client'

import participantRoomReservation from './participantRoomReservation'

export default gql`
  ${participantRoomReservation}

  fragment ParticipantRoom on ParticipantRoom {
    checkIn
    checkOut
    id
    roomReservation {
      ...ParticipantRoomReservation
    }
  }
`
