import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useParticipantsListContext } from '@/modules/ParticipantsList/ParticipantsListState'
import { participantQueries } from '@/modules/ParticipantsList/queries'
import { DefaultFilterType } from '@/modules/ParticipantsList/types'
import { generateCompareFn } from '@/utils/arrays'

import {
  AccommodationTargetsQuery as Query,
  AccommodationTargetsQueryVariables as QueryVariables,
  SalesType,
} from '~generated-types'

import { FilterContainer } from '../FilterContainer'
import { CheckboxLabel, CheckboxWrapper, Divider, Placeholder } from './common'

type Accommodation = Query['sales']['accommodation']
type AccommodationGroup = Accommodation['accommodationGroups'][0]
type AccommodationTarget = AccommodationGroup['targets'][0]

type Props = {
  filters: DefaultFilterType[] | null
  setFilters: (filters: DefaultFilterType[] | null) => void
}

export const AccommodationTargetFilter = ({ filters, setFilters }: Props) => {
  const { language } = useLanguageContext()
  const { salesId, saleType } = useParticipantsListContext()

  const { data, loading } = useQuery<Query, QueryVariables>(
    participantQueries.ACCOMMODATION_TARGETS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { salesId },
    }
  )

  const isEmptySelected = !!(filters?.length === 0)

  const accommodationGroups =
    data?.sales.accommodation.accommodationGroups ?? []

  const handleSelect = (targetFilter: DefaultFilterType) => {
    if (!filters) {
      return setFilters([targetFilter])
    }

    const newFilters = !!filters.find((f) => f.value === targetFilter.value)
      ? filters.filter((f) => f.value !== targetFilter.value)
      : [...filters, targetFilter]

    return setFilters(newFilters.length ? newFilters : null)
  }

  return (
    <FilterContainer isLoading={loading}>
      <FlexColumn>
        {!!accommodationGroups.length ? (
          <>
            {[...accommodationGroups]
              .sort(generateCompareFn('sortOrder'))
              .map((group) => (
                <FlexColumn key={group.id}>
                  {saleType === SalesType.Event && (
                    <GroupName>{getGroupName(group, language)}</GroupName>
                  )}

                  {[...group.targets]
                    .sort(generateCompareFn('sortOrder'))
                    .map((target) => (
                      <CheckboxWrapper alignItems="center" key={target.id}>
                        <CheckboxInput
                          checked={
                            !!filters?.find((v) => v.value === target.id)
                          }
                          noMargin
                          onChange={() =>
                            handleSelect({
                              label: getTargetName(target, language),
                              value: target.id,
                            })
                          }
                        >
                          <CheckboxLabel>
                            {getTargetName(target, language)}
                          </CheckboxLabel>
                        </CheckboxInput>
                      </CheckboxWrapper>
                    ))}
                </FlexColumn>
              ))}

            <Divider />

            <CheckboxWrapper alignItems="center">
              <CheckboxInput
                checked={isEmptySelected}
                noMargin
                onChange={() => setFilters(isEmptySelected ? null : [])}
              >
                <CheckboxLabel>
                  <T>ParticipantsList:filters.empty.accommodationTargetIds</T>
                </CheckboxLabel>
              </CheckboxInput>
            </CheckboxWrapper>
          </>
        ) : (
          <Placeholder>
            <T>ParticipantsList:filters.noAccommodationTargets</T>
          </Placeholder>
        )}
      </FlexColumn>
    </FilterContainer>
  )
}

////////

const getGroupName = (group: AccommodationGroup, language: string) =>
  group.name ||
  `${translate(
    `Accommodation:SalesReservationList.reservationGroup`,
    language
  )} #${group.sortOrder + 1}`

const getTargetName = (target: AccommodationTarget, language: string) =>
  target.default
    ? translate('Accommodation:TargetGroup.default', language)
    : target.name ||
      `${translate(`Accommodation:TargetGroup.target`, language)} #${
        target.sortOrder
      }`

const GroupName = styled(Label)`
  ${({ theme }) => css`
    margin-top: calc(${theme.spacing.gu(1)}rem + 2px);
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`
