import { gql, useMutation } from '@apollo/client'

import { participantFragments } from '@/modules/ParticipantsList/fragments'

import {
  SalesParticipantMassCreateMutation,
  SalesParticipantMassCreateMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantMassCreateMutation,
  SalesParticipantMassCreateMutationVariables,
}

export const SALES_PARTICIPANT_MASS_CREATE = gql`
  ${participantFragments.participant}

  mutation SalesParticipantMassCreate($input: [SalesParticipantCreateInput!]!) {
    salesParticipantMassCreate(input: $input) {
      ...Participant
    }
  }
`

export function useSalesParticipantMassCreate() {
  return useMutation<
    SalesParticipantMassCreateMutation,
    SalesParticipantMassCreateMutationVariables
  >(SALES_PARTICIPANT_MASS_CREATE)
}

export default useSalesParticipantMassCreate
