import { gql } from '@apollo/client'

export default gql`
  fragment RoomTypeReservation on RoomTypeReservation {
    id
    fulfilledByRooms
    request {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      features {
        icon
        id
        name
        shortName
      }
      info
      beds
      extraBeds
      rooms
      roomType {
        id
        name
        beds
        extraBeds
        accommodationLevel {
          id
          name
          shortName
        }
        category {
          id
          name
        }
        name
      }
    }
    target {
      id
    }
  }
`
