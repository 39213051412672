import { gql, useMutation } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

import {
  RoomReservationSetNeedsMutation,
  RoomReservationSetNeedsMutationVariables,
} from '~generated-types'

export type {
  RoomReservationSetNeedsMutation,
  RoomReservationSetNeedsMutationVariables,
}

export const ROOM_RESERVATION_SET_NEEDS = gql`
  ${accommodationFragments.accommodationSettings}
  ${accommodationFragments.roomReservation}

  mutation RoomReservationSetNeeds(
    $input: AccommodationRoomReservationSetNeedsInput!
  ) {
    accommodationRoomReservationSetNeeds(input: $input) {
      ... on AccommodationRoomReservationPayloadOk {
        roomReservation {
          ...RoomReservation
        }
        accommodationGroup {
          ...AccommodationSettings
        }
      }
    }
  }
`

export function useRoomReservationSetNeeds() {
  return useMutation<
    RoomReservationSetNeedsMutation,
    RoomReservationSetNeedsMutationVariables
  >(ROOM_RESERVATION_SET_NEEDS)
}

export default useRoomReservationSetNeeds
