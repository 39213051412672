import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Gutter } from '@/components/Layout'

import { useRoomReservationsContext } from '../../DailyRoomReservationsState'
import {
  BuildingFilter,
  CleaningStatusFilter,
  ParticipantFilter,
  ReservationTypeFilter,
  RoomStatusFilter,
  SalesFilter,
} from './components'

export const Sidebar = () => {
  const { isFiltersOpen, layoutVariables, setFiltersOpen } =
    useRoomReservationsContext()

  return (
    <Wrapper isOpen={isFiltersOpen} width={layoutVariables.SIDEBAR_WIDTH}>
      <ExpandButton onClick={() => setFiltersOpen(!isFiltersOpen)}>
        <FontAwesomeIcon
          icon={isFiltersOpen ? 'angles-right' : 'angles-left'}
        />
      </ExpandButton>

      <Gutter type={[2, 2]}>
        <BuildingFilter />

        <SalesFilter />

        <ParticipantFilter />

        <ReservationTypeFilter />

        <RoomStatusFilter />

        <CleaningStatusFilter />
      </Gutter>
    </Wrapper>
  )
}

////////////

const ExpandButton = styled.button`
  cursor: pointer;
  position: fixed;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    margin-left: -${theme.spacing.gu(5)}rem;
    margin-top: ${theme.spacing.gu(3)}rem;
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(5)}rem;
  `}

  border-right: none;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  box-shadow: -3px 0 5px -2px rgba(0, 0, 0, 0.1);
`

const Wrapper = styled.div<{ isOpen: boolean; width: number }>`
  display: flex;
  flex-direction: column;
  overflow: auto;

  box-shadow: -3px 0 5px -2px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-out;
  z-index: 4;

  ${({ isOpen, theme, width }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
    min-width: ${width}px;

    ${!isOpen && `margin-right: -${width}px`}
  `}
`
