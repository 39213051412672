import { gql, useMutation } from '@apollo/client'

import {
  SetGuestCommunicationSettingsMutation,
  SetGuestCommunicationSettingsMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation setGuestCommunicationSettings(
    $input: SalesGuestCommunicationSettingsInput!
  ) {
    salesSetGuestCommunicationSettings(input: $input) {
      id
      lastIntegrationSyncAt
      origin
      rateCode
      source
      template {
        description
        id
        name
      }
    }
  }
`

export const useSetGuestCommunicationMutation = () =>
  useMutation<
    SetGuestCommunicationSettingsMutation,
    SetGuestCommunicationSettingsMutationVariables
  >(MUTATION)
