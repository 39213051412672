import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { useDialogService } from '@/components/DialogService'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { AccommodationQueries } from '@/modules/Accommodation/SalesReservationList/ReservationList.queries'
import {
  SalesAccommodationAvailability,
  SalesAccommodationGroup,
} from '@/modules/Accommodation/SalesReservationList/SalesReservationList.types'
import { T } from '@/modules/Language'
import {
  AccommodationGroup,
  RoomLayoutMutations,
  RoomReservation as GQLRoomReservation,
  RoomTypeReservation as GQLRoomTypeReservation,
  Sale,
  Target,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'

import {
  ContentWrapper,
  Dates,
  Label,
  ModalContentWrapper,
  NumberInput,
  TextDivider,
  TotalNumber,
  TotalNumberBold,
  TrashIconWrapper,
  VerticalDivider,
  Wrapper,
} from './common'
import { LineLeader } from './LineLeader'
import { RoomFeatures } from './RoomFeatures'

type Props = {
  groupId: string
  isLast: boolean
  reservation: GQLRoomReservation
  roomTypeCheckIn: string
  roomTypeCheckOut: string
  roomTypeName: string
  roomTypeReservationId: string
  saleId: string
  targetId: string
}

type ContentProps = {
  extraInfo?: boolean
}

export const NestedRoomReservation = ({
  groupId,
  isLast,
  reservation,
  roomTypeCheckIn,
  roomTypeCheckOut,
  roomTypeName,
  roomTypeReservationId,
  saleId,
  targetId,
}: Props) => {
  const theme = useTheme()

  const {
    id,
    request: { beds, checkIn, checkOut, extraBeds, features, room },
  } = reservation

  const { confirm } = useDialogService()
  const { onSetSales, refreshCalendar, sales, targetSalesId } =
    useRoomLayoutContext()

  const [isHover, setHover] = useState<boolean>(false)
  const [bedQuantity, setBedQuantity] = useState<number>(beds)
  const [extraBedQuantity, setExtraBedQuantity] = useState<number>(extraBeds)

  const [deleteRoomReservation] = useMutation(
    RoomLayoutMutations.DELETE_ROOM_RESERVATION,
    {
      // Manually update GraphQL cache after removing
      update(cache, { data }) {
        if (targetSalesId === saleId) {
          const salesCache: SalesAccommodationAvailability | null =
            cache.readQuery({
              query: AccommodationQueries.SALE_RESERVATIONS,
              variables: { id: saleId },
            })
          if (salesCache?.sales) {
            const newSales = {
              ...salesCache,
              sales: {
                ...salesCache.sales,
                accommodation: {
                  ...salesCache.sales.accommodation,
                  accommodationGroups:
                    salesCache.sales.accommodation.accommodationGroups.map(
                      (group: SalesAccommodationGroup) =>
                        group.id ===
                        data?.accommodationRoomReservationDelete
                          .accommodationGroup.id
                          ? data?.accommodationRoomReservationDelete
                              .accommodationGroup
                          : group
                    ),
                },
              },
            }

            cache.writeQuery({
              data: { ...newSales },
              query: AccommodationQueries.SALE_RESERVATIONS,
            })
          }
        }
      },
    }
  )
  const [setBedQuatity] = useMutation(
    RoomLayoutMutations.SET_ROOM_RESERVATION_BED_QUANTITY
  )

  const handleDeleteRoomReservation = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.remove</T>,
      description: (
        <>
          <T>RoomLayout:sidebar.confirmRoomReservationRemoval.description</T>
          <ModalContentWrapper>
            <Content extraInfo />
          </ModalContentWrapper>
        </>
      ),
    })
      .then(() =>
        deleteRoomReservation({
          variables: {
            input: {
              id,
            },
          },
        })
      )
      .then(() => {
        refreshCalendar()
        onSetSales(
          sales.map((sale: Sale) =>
            sale.id === saleId
              ? {
                  ...sale,
                  accommodationGroups: sale.accommodationGroups.map(
                    (group: AccommodationGroup) =>
                      group.id === groupId
                        ? {
                            ...group,
                            targets: group.targets.map((target: Target) =>
                              target.id === targetId
                                ? {
                                    ...target,
                                    gqlRoomTypeReservations:
                                      target.gqlRoomTypeReservations?.map(
                                        (
                                          roomTypeReservation: GQLRoomTypeReservation
                                        ) =>
                                          roomTypeReservation.id ===
                                          roomTypeReservationId
                                            ? {
                                                ...roomTypeReservation,
                                                roomReservations:
                                                  roomTypeReservation.roomReservations.filter(
                                                    (
                                                      roomReservation: GQLRoomReservation
                                                    ) =>
                                                      roomReservation.id !== id
                                                  ),
                                              }
                                            : roomTypeReservation
                                      ),
                                  }
                                : target
                            ),
                          }
                        : group
                  ),
                }
              : sale
          )
        )
      })
      .catch(() => undefined)

  const handleSetBedQuantity = () => {
    setBedQuatity({
      variables: {
        input: {
          beds: bedQuantity,
          extraBeds: extraBedQuantity,
          id,
        },
      },
    })
      .then((res) => {
        const updatedRoomReservation =
          res.data?.accommodationRoomReservationSetBedQuantity.roomReservation

        onSetSales(
          sales.map((sale: Sale) =>
            sale.id === saleId
              ? {
                  ...sale,
                  accommodationGroups: sale.accommodationGroups.map(
                    (group: AccommodationGroup) =>
                      group.id === groupId
                        ? {
                            ...group,
                            targets: group.targets.map((target: Target) =>
                              target.id === targetId
                                ? {
                                    ...target,
                                    gqlRoomTypeReservations:
                                      target.gqlRoomTypeReservations?.map(
                                        (
                                          roomTypeReservation: GQLRoomTypeReservation
                                        ) =>
                                          roomTypeReservation.id ===
                                          roomTypeReservationId
                                            ? {
                                                ...roomTypeReservation,
                                                roomReservations:
                                                  roomTypeReservation.roomReservations.map(
                                                    (
                                                      roomReservation: GQLRoomReservation
                                                    ) =>
                                                      roomReservation.id === id
                                                        ? updatedRoomReservation ||
                                                          roomReservation
                                                        : roomReservation
                                                  ),
                                              }
                                            : roomTypeReservation
                                      ),
                                  }
                                : target
                            ),
                          }
                        : group
                  ),
                }
              : sale
          )
        )
      })
      .catch(() => undefined)
  }

  const renderDates = () => (
    <Dates>
      {checkIn.type === CheckInTypes.EARLY && (
        <FontAwesomeIcon
          color={theme.palette.text.lighter}
          icon="sun"
          size="sm"
          style={{ marginRight: theme.spacing.gutterSmall }}
        />
      )}
      {moment(checkIn.date).format('dd, D.M.YY')} {' – '}
      {moment(checkOut.date).format('dd, D.M.YY')}
      {checkOut.type === CheckOutTypes.LATE && (
        <FontAwesomeIcon
          color={theme.palette.text.lighter}
          icon="moon"
          size="xs"
          style={{ marginLeft: theme.spacing.gutterSmall }}
        />
      )}
    </Dates>
  )

  const Content = ({ extraInfo }: ContentProps) =>
    extraInfo ? (
      <FlexColumn justifyContent="center" noPadding>
        <FlexRow justifyContent="flex-start">
          <Label>{room.number}</Label>
          <TextDivider>|</TextDivider>
          <span>{room.roomType.name}</span>
        </FlexRow>
        {renderDates()}
      </FlexColumn>
    ) : (
      <FlexColumn justifyContent="center" noPadding>
        <FlexRow alignItems="center" justifyContent="flex-start">
          <Label>{room.number}</Label>

          {room.roomType.name !== roomTypeName && (
            <>
              <TextDivider>|</TextDivider>
              <span>{room.roomType.name}</span>
            </>
          )}
        </FlexRow>

        {(checkIn.date !== roomTypeCheckIn ||
          checkOut.date !== roomTypeCheckOut) &&
          renderDates()}
      </FlexColumn>
    )

  return (
    <Wrapper
      dashedBorder
      nestedRoom
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FlexColumn alignItems="center" noPadding>
        <LineLeader fullHeight={!isLast} />
      </FlexColumn>
      <ContentWrapper flex={1} justifyContent="space-between">
        <Content />
      </ContentWrapper>

      <FlexColumn
        alignItems="flex-end"
        justifyContent={features.length ? 'space-between' : 'flex-end'}
        style={{ padding: `${theme.spacing.gu(1)}rem` }}
      >
        <RoomFeatures features={features} />
        {isHover && (
          <TrashIconWrapper onClick={handleDeleteRoomReservation}>
            <FontAwesomeIcon fixedWidth icon="trash" size="xs" />
          </TrashIconWrapper>
        )}
      </FlexColumn>

      <VerticalDivider />

      <FlexColumn
        justifyContent="space-around"
        style={{
          padding: `${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem`,
        }}
      >
        <TotalNumber justifyContent="space-between" alignItems="center">
          <FontAwesomeIcon size="sm" icon="bed" />
          <FlexRow alignItems="center">
            <NumberInput
              onBlur={handleSetBedQuantity}
              onChange={(e) => setBedQuantity(Number(e.target.value))}
              onFocus={(e) => e.currentTarget.select()}
              min="0"
              type="number"
              value={`${bedQuantity}`}
            />
            / {room.beds}
          </FlexRow>
        </TotalNumber>
        <TotalNumber justifyContent="space-between" alignItems="center">
          <FontAwesomeIcon size="sm" icon="couch" />
          <FlexRow alignItems="center">
            <NumberInput
              onBlur={handleSetBedQuantity}
              onChange={(e) => setExtraBedQuantity(Number(e.target.value))}
              onFocus={(e) => e.currentTarget.select()}
              min="0"
              type="number"
              value={`${extraBedQuantity}`}
            />
            / {room.extraBeds}
          </FlexRow>
        </TotalNumber>
      </FlexColumn>

      <VerticalDivider />

      <FlexColumn
        justifyContent="center"
        style={{
          background: theme.palette.primary.extraLighter,
          padding: theme.spacing.gutterSmall,
        }}
      >
        <TotalNumberBold />
        <TotalNumberBold />
      </FlexColumn>
    </Wrapper>
  )
}
