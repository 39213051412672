import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Placeholder } from './common'

type Props = {
  isInvalid?: boolean
  label: string
  placeholder?: string
}

export const ValueReadOnly = ({ isInvalid, label, placeholder }: Props) => (
  <Wrapper isInvalid={isInvalid}>
    {label ? (
      <Label>{label}</Label>
    ) : (
      <Placeholder>{placeholder || '–'}</Placeholder>
    )}

    {isInvalid && <Icon icon="triangle-exclamation" />}
  </Wrapper>
)

//////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Label = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

const Wrapper = styled.div<{ isInvalid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  border-radius: 4px;
  height: 30px;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ isInvalid, theme }) =>
    isInvalid
      ? `border: 1px solid ${theme.palette.danger.main};
      box-shadow: 0 0 0 1px ${theme.palette.danger.main};`
      : `border: 1px solid ${theme.palette.white}`}
`
