import { getCurrentHostnameBaseUrl } from '@/utils/urls'

import { KEYCLOAK_REALM } from '../env'

export const KEYCLOAK_ADMIN_URL = `https://auth.willba.app/admin/${KEYCLOAK_REALM}/console`

export const STRAPI_ADMIN_URL = `${
  window.location.hostname === 'localhost'
    ? `http://localhost:1337`
    : getCurrentHostnameBaseUrl()
}/cms/admin`

export const WILLBA_DOCS_URL = 'https://docs.willba.app'

export const WILLBA_RELEASES_URL = 'https://willba.app/releases'
