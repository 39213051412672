import { useState } from 'react'
import styled from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  Participant,
  ParticipantRoom,
} from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { ParticipantManager, ParticipantSection } from './components'

type Props = {
  onClose: () => void
  participantRooms: ParticipantRoom[]
}

type ParticipantWithRooms = {
  participant: Participant
  participantRooms: Omit<ParticipantRoom, 'participant'>[]
}

export const ParticipantsModal = ({ onClose, participantRooms }: Props) => {
  const { spacing } = useTheme()

  const [targetId, setTargetId] = useState<string | null>(null)

  const getTargetParticipantRoom = (id: string) =>
    participantRooms.find((p) => p.participant.id === id) as ParticipantRoom

  const renderContent = () => {
    if (targetId) {
      const { participant } = getTargetParticipantRoom(targetId)

      return <ParticipantManager participant={participant} />
    }

    const participantsWithRooms = participantRooms.reduce(
      (acc: ParticipantWithRooms[], { participant, ...participantRoom }) => {
        const existingParticipantWithRoom = acc.find(
          ({ participant: { id } }) => id === participant.id
        )

        if (existingParticipantWithRoom) {
          return acc.map((p) =>
            p.participant.id === participant.id
              ? {
                  participant,
                  participantRooms: [...p.participantRooms, participantRoom],
                }
              : p
          )
        }
        return [...acc, { participant, participantRooms: [participantRoom] }]
      },
      []
    )

    return [...participantsWithRooms]
      .sort(generateCompareFn('participant.id'))
      .map(({ participant, participantRooms }) => (
        <ParticipantSection
          key={participant.id}
          openParticipantManager={setTargetId}
          participant={participant}
          participantRooms={participantRooms}
          readOnly={participant.sales.locked}
        />
      ))
  }

  return (
    <Modal>
      <InlineModalHeader
        onClose={onClose}
        onBackNavigate={targetId ? () => setTargetId(null) : undefined}
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
        title={
          targetId ? (
            <T>FrontDesk:DailyRoomReservations.participants.manager</T>
          ) : (
            <T>FrontDesk:DailyRoomReservations.participants.title</T>
          )
        }
      />

      <InlineModalLine />

      <InlineModalContent>{renderContent()}</InlineModalContent>

      <InlineModalFooter justifyContent="flex-end">
        <InnocuousButton compact onClick={onClose}>
          <T>common:action.close</T>
        </InnocuousButton>
      </InlineModalFooter>
    </Modal>
  )
}

////////////

const Modal = styled(InlineModal)`
  height: ${({ theme }) =>
    `calc(100vh - ${theme.spacing.guPx(9) + 3}px - 10px)`};
  max-height: 100vh;
  width: 450px;

  margin-top: ${({ theme }) => `${theme.spacing.guPx(9) + 3}px`};
  margin-bottom: 10px;
  margin-right: 10px;
`
