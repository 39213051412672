import styled from 'styled-components/macro'

import { DangerColor, PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModalFooter } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { useCatalogProductManagerContext } from './CatalogProductManagerState'

type Props = {
  onClose: () => void
  productId: string
}

export const CatalogProductManagerFooter = ({ onClose, productId }: Props) => {
  const { deleteProductWithConfirm, readOnly } =
    useCatalogProductManagerContext()

  return (
    <InlineModalFooter>
      <FlexRow flex={1}>
        <InnocuousButton
          disabled={readOnly}
          onClick={() => deleteProductWithConfirm(productId).then(onClose)}
        >
          <DangerColor>
            <T>Products:ProductManager.action.deleteProduct</T>
          </DangerColor>
        </InnocuousButton>

        <Spacer />

        <InnocuousButton onClick={onClose}>
          <PrimaryColor>
            <T>common:action.ready</T>
          </PrimaryColor>
        </InnocuousButton>
      </FlexRow>
    </InlineModalFooter>
  )
}

/////////

const Spacer = styled.div`
  flex: 1;
`
