import { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { parse } from 'query-string'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import {
  ElasticError,
  ReactiveBase,
  ReactiveList,
  RenderProps,
  SortOption,
} from '@/components/Reactivesearch'
import { ELASTIC_URL } from '@/config/env'
import { useKeycloakContext } from '@/config/keycloak'
import { T, useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { salesQueries } from '@/modules/Sales/queries'
import { ElasticSales } from '@/modules/Sales/types'

import {
  SalesPaymentInfoQuery,
  SalesPaymentInfoQueryVariables,
} from '~generated-types'

import { Loader, Placeholder, Sheet } from '../common'
import { SalesListErrorBoundary } from './components/SalesListErrorBoundary'
import { SalesListFilters } from './components/SalesListFilters'
import { getSortOptions, SalesListSort } from './components/SalesListSort'
import { SalesList } from './SalesList'

const componentIds: { [key: string]: string } = Object.freeze({
  DATES: 'dates',
  DIMENSIONS: 'dimensions',
  LIST: 'page',
  PARTICIPANTS: 'participants',
  SEARCH: 'sales-search',
  SEARCH_PARTICIPANTS: 'participants-search',
  SELLER: 'seller',
  STATE: 'state',
  TYPE: 'type',
})

const PAGE_SIZE = 50
const SALES_INDEX = 'sales.sales'

type Query = {
  [key: string]: any
}

type ContentProps = {
  data: ElasticSales[]
  error?: ElasticError | null
  loading: boolean
  totalResults: number
}

export const SalesListContainer = () => {
  const { language } = useLanguageContext()
  const { openRefreshModal } = useKeycloakContext()

  const { salesStatesByKey } = salesHooks.useSalesStates()

  // TODO: Get from some config?
  const showPrices = false

  const allElasticDataIds = useRef<string[]>([])
  const scrollRef = useRef<HTMLDivElement>(null)

  const sortOptions = getSortOptions(language)

  const [currentFetch, setCurrentFetch] = useState<number>(1)
  const [sortProperty, setSortProperty] = useState<SortOption>(sortOptions[0])

  const [loadGQLSales, { data: gqlSalesData, loading: gqlSalesLoading }] =
    useLazyQuery<SalesPaymentInfoQuery, SalesPaymentInfoQueryVariables>(
      salesQueries.SALES_PAYMENT_INFO
    )

  const handleLoadGQLSales = (ids: string[]) =>
    loadGQLSales({ variables: { ids } })

  const onElasticData = (data: ElasticSales[]) => {
    if (gqlSalesLoading) {
      return
    }

    const gqlDataIds = gqlSalesData?.salesAll
      ? gqlSalesData.salesAll.map(({ id }) => id)
      : []
    const elasticDataIds = data.map(({ id }) => id)
    const prevElasticDataIds = allElasticDataIds.current

    const isElasticIdsEqual =
      JSON.stringify(prevElasticDataIds.sort()) ===
      JSON.stringify(elasticDataIds.sort())

    if (isElasticIdsEqual) {
      return
    }

    const isIdsEqual =
      JSON.stringify(gqlDataIds.sort()) ===
      JSON.stringify(elasticDataIds.sort())

    if (!isIdsEqual) {
      allElasticDataIds.current = elasticDataIds
      handleLoadGQLSales(elasticDataIds)
    }
  }

  const renderContent = ({
    data,
    error,
    loading,
    totalResults,
  }: ContentProps) => {
    if (loading && !data.length) {
      return <Loader />
    }

    if (!!error) {
      return (
        <Placeholder
          content={<T>ElasticFilterSearchList:error</T>}
          icon="circle-exclamation"
        />
      )
    }

    if (!data.length) {
      return (
        <Placeholder
          content={<T>ElasticFilterSearchList:empty</T>}
          icon="circle-info"
        />
      )
    }

    return (
      <SalesList
        data={data}
        gqlData={gqlSalesData?.salesAll ?? []}
        hasMoreData={totalResults > currentFetch * PAGE_SIZE}
        fetchMoreData={() => setCurrentFetch(currentFetch + 1)}
        scrollRef={scrollRef}
        showPrices={showPrices}
        totalResults={totalResults}
      />
    )
  }

  // Initialise sort order if in URL
  useEffect(() => {
    const { sort } = parse(window.location.search)

    if (typeof sort === 'string') {
      const [dataField, direction] = sort.split('__')
      const target = sortOptions.find(
        (x) => x.dataField === dataField && x.direction === direction
      )

      if (target) {
        setSortProperty(target)
      }
    }
  }, [])

  const defaultQuery = () => ({
    from: 0,
    size: currentFetch * PAGE_SIZE,
    sort: [{ [sortProperty.dataField]: sortProperty.direction }],
  })

  if (!salesStatesByKey) {
    return null
  }

  return (
    <Sheet>
      <ReactiveBase app={SALES_INDEX} url={ELASTIC_URL}>
        <ReactiveList
          componentId={componentIds.LIST}
          dataField="name"
          react={{
            and: Object.keys(componentIds).map((key) => componentIds[key]),
          }}
          onQueryChange={(prevQuery: Query, nextQuery: Query) => {
            const prevString = JSON.stringify(prevQuery?.query ?? '')
            const nextString = JSON.stringify(nextQuery?.query ?? '')

            const prev = prevString.replace(/"queryVersion":\d*/, '')
            const next = nextString.replace(/"queryVersion":\d*/, '')

            if (prev !== next) {
              setCurrentFetch(1)

              if (scrollRef.current) {
                scrollRef.current.scroll({
                  behavior: 'smooth',
                  top: 0,
                })
              }
            }

            return getExactQuery(nextQuery, {
              fields: 'orderNumber.text',
              key: componentIds.SEARCH,
            })
          }}
          defaultQuery={defaultQuery}
          pagination={false}
          render={({
            data,
            error,
            loading,
            resultStats,
          }: RenderProps<ElasticSales>) => {
            if (error?.status === 401) {
              openRefreshModal()
            }

            if (!loading && data.length && showPrices) {
              onElasticData(data)
            }

            const totalResults = resultStats?.numberOfResults ?? 0

            return (
              <SalesListErrorBoundary>
                <ListControls
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <ListTitle>
                    <T>SalesSearchList:title</T>
                  </ListTitle>

                  <FlexRow>
                    <SalesListFilters
                      componentIds={componentIds}
                      totalResults={totalResults}
                    />

                    <SalesListSort
                      scrollRef={scrollRef}
                      setSortProperty={setSortProperty}
                      sortProperty={sortProperty}
                    />
                  </FlexRow>
                </ListControls>

                {renderContent({ data, error, loading, totalResults })}
              </SalesListErrorBoundary>
            )
          }}
          renderError={() => null}
          renderNoResults={() => null}
          showLoader={false}
          showResultStats={false}
          size={PAGE_SIZE}
        />
      </ReactiveBase>
    </Sheet>
  )
}

////////////

type ExactQueryProps = {
  fields: string | string[]
  key: string
}

const getExactQuery = (nextQuery: Query, exactQueryProps: ExactQueryProps) => {
  if (nextQuery) {
    const { key, fields } = exactQueryProps

    const inputKey = `[aria-label="${key}"]`
    const query = (document.querySelector(inputKey) as HTMLInputElement).value

    if (query) {
      nextQuery.query.bool = {
        should: [
          ...nextQuery.query.bool.must,
          {
            bool: {
              must: [
                {
                  multi_match: {
                    fields,
                    query,
                    type: 'phrase',
                  },
                },
              ],
            },
          },
        ],
      }
    }
  }
}

const ListControls = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(9)}rem;
    padding: 0 ${theme.spacing.gu(3)}rem 0 ${theme.spacing.gu(4)}rem;
  `}
`

const ListTitle = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
