import { lighten } from 'polished'
import { css } from 'styled-components/macro'

export const NavbarButtonStyles = css<{ disabled?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  margin-right: 2px;
  position: relative;
  text-decoration: none;
  font-weight: 500;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem;
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: #bbb;
      cursor: not-allowed;
    `}

  &.is-active {
    ${({ disabled, theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${lighten(0.05, theme.palette.primary.extraLight)};
      opacity: ${disabled ? 0.6 : 1};
    `}
  }

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        color: ${theme.palette.primary.darker};
        background: ${theme.palette.primary.extraLight};
      `}
  }
`
