import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { useRoomReservationsContext } from '@/modules/FrontDesk/components/DailyRoomReservations/DailyRoomReservationsState'
import { useSetRoomReservationCleaningStatus } from '@/modules/FrontDesk/components/DailyRoomReservations/mutations'
import {
  CleaningStatus,
  Room,
  RoomStatus as RoomStatusType,
} from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { T } from '@/modules/Language'
import { Palette, useTheme } from '@/theme'
import { isToday } from '@/utils/time'

import { ActionButton } from '../../common'

const PATH = 'FrontDesk:DailyRoomReservations'

type Props = {
  room: Room
}

export const RoomStatus = ({ room }: Props) => {
  const { palette } = useTheme()
  const { date, isParticipantsCorrect, updateRoom } =
    useRoomReservationsContext()

  const {
    id,
    status: { cleaningStatus, reservationStatus: roomStatus },
  } = room

  const [delay, setDelay] = useState<number | undefined>(undefined)
  const [hover, setHover] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const [setCleaningStatus] = useSetRoomReservationCleaningStatus()

  const handleMouseEnter = () =>
    setDelay(
      window.setTimeout(() => {
        setHover(true)
      }, 200)
    )

  const handleMouseLeave = () => {
    window.clearTimeout(delay)
    setHover(false)
  }

  const handleSetCleaningStatus = () => {
    setHover(false)
    setProcessing(true)

    setCleaningStatus({
      variables: {
        input: {
          cleaningStatus:
            cleaningStatus === CleaningStatus.Clean
              ? CleaningStatus.Dirty
              : CleaningStatus.Clean,
          roomIds: [id],
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          const cleaningStatus = data.roomSetStatus[0].newCleaningStatus
          updateRoom({ ...room, status: { ...room.status, cleaningStatus } })
        }
      })
      .catch(() => undefined)
      .finally(() => setProcessing(false))
  }

  const renderCleaningStatusLabel = () => (
    <Label color={getCleaningStatusColor(cleaningStatus, palette)}>
      <T l10n={`${PATH}.cleaningStatus.${cleaningStatus}`} />
    </Label>
  )

  const renderRoomStatusLabel = () => (
    <>
      <Label color={getRoomStatusColor(roomStatus, palette)}>
        <T l10n={`${PATH}.roomStatus.${roomStatus}`} />
      </Label>
      {isToday(date) && !isParticipantsCorrect(id) && (
        <Warning>
          <Tooltip
            content={<T l10n={`${PATH}.warning.wrongParticipants`} />}
            maxWidth={200}
            trigger={(triggerProps) => (
              <span {...triggerProps}>
                <FontAwesomeIcon
                  color={palette.warning.dark}
                  icon="triangle-exclamation"
                />
              </span>
            )}
          />
        </Warning>
      )}
    </>
  )

  const renderCleaningStatusButton = () => (
    <ActionButton
      background={
        cleaningStatus === CleaningStatus.Clean
          ? palette.danger.light
          : palette.success.light
      }
      isHover={hover}
      onClick={handleSetCleaningStatus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {processing ? (
        <ReactLoading
          color={palette.smoke.dark}
          height={24}
          type="spin"
          width={24}
        />
      ) : hover ? (
        <Action noPadding>
          <T
            l10n={`${PATH}.markAs.${
              cleaningStatus === CleaningStatus.Clean
                ? CleaningStatus.Dirty
                : CleaningStatus.Clean
            }`}
          />
        </Action>
      ) : (
        <FlexColumn noPadding>
          {renderRoomStatusLabel()}
          {renderCleaningStatusLabel()}
        </FlexColumn>
      )}
    </ActionButton>
  )

  return (
    <Wrapper alignItems="center" justifyContent="center">
      {roomStatus !== RoomStatusType.Occupied
        ? renderCleaningStatusButton()
        : renderRoomStatusLabel()}
    </Wrapper>
  )
}

////////////

const getCleaningStatusColor = (
  status: CleaningStatus | null,
  palette: Palette
) => {
  switch (status) {
    case CleaningStatus.Clean:
      return palette.success.dark
    case CleaningStatus.Dirty:
      return palette.danger.dark
    default:
      return palette.text.lighter
  }
}

const getRoomStatusColor = (
  status: RoomStatusType | null,
  palette: Palette
) => {
  switch (status) {
    case RoomStatusType.Reserved:
      return palette.warning.darker
    case RoomStatusType.Vacant:
      return palette.primary.dark
    case RoomStatusType.Occupied:
    default:
      return palette.text.lighter
  }
}

const Action = styled(FlexColumn)`
  font-weight: 600;
  text-transform: uppercase;
`

const Label = styled.span<{ color: string }>`
  font-weight: 500;
  text-transform: uppercase;

  ${({ color }) => `
    color: ${color};
  `};
`

const Warning = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `};
`

const Wrapper = styled(FlexRow)`
  height: 100%;
  width: 110px;
`
