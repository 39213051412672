import { Route, RouteProps } from 'react-router-dom'

import { NavViewKey } from '@/config/types'

import { Page, PageProps } from './Page'
import { ProtectedRoute } from './ProtectedRoute'

interface Props extends RouteProps, Omit<PageProps, 'children'> {
  accessKey?: NavViewKey
}

export const PageRoute = ({
  accessKey,
  pageTitle,
  pageTitleKey,
  ...routeProps
}: Props) => (
  <Page pageTitle={pageTitle} pageTitleKey={pageTitleKey}>
    {accessKey ? (
      <ProtectedRoute accessKey={accessKey} {...routeProps} />
    ) : (
      <Route {...routeProps} />
    )}
  </Page>
)
