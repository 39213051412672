import ParticipantNote from '@/components/TextNoteModal'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'

type Props = {
  note: string
  readOnly?: boolean
  handleUpdateNote: (note: string) => Promise<FetchedParticipant | undefined>
}

const Note = ({ note, readOnly, handleUpdateNote }: Props) => {
  const { spacing } = useTheme()

  return (
    <ServiceItemSquared
      width={`${spacing.gu(4)}rem`}
      noBorder
      onClick={() => null}
      renderContent={() => (
        <ParticipantNote
          note={note}
          updateNote={handleUpdateNote}
          readOnly={readOnly}
        />
      )}
    />
  )
}

export default Note
