import { gql, useMutation } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

import {
  RoomTypeReservationSetDatesMutation,
  RoomTypeReservationSetDatesMutationVariables,
} from '~generated-types'

export type {
  RoomTypeReservationSetDatesMutation,
  RoomTypeReservationSetDatesMutationVariables,
}

export const ROOM_TYPE_RESERVATION_SET_DATES = gql`
  ${accommodationFragments.accommodationSettings}
  ${accommodationFragments.roomTypeReservation}

  mutation RoomTypeReservationSetDates(
    $input: AccommodationRoomTypeReservationSetDatesInput!
  ) {
    accommodationRoomTypeReservationSetDates(input: $input) {
      roomTypeReservation {
        ...RoomTypeReservation
      }
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export function useRoomTypeReservationSetDates() {
  return useMutation<
    RoomTypeReservationSetDatesMutation,
    RoomTypeReservationSetDatesMutationVariables
  >(ROOM_TYPE_RESERVATION_SET_DATES)
}

export default useRoomTypeReservationSetDates
