import { Route, RouteProps } from 'react-router-dom'

import { useKeycloakContext } from '@/config/keycloak'
import { NavViewKey } from '@/config/types'

import { AccessErrorPage } from './AccessErrorPage'

interface Props extends RouteProps {
  accessKey: NavViewKey
}

export const ProtectedRoute = ({ accessKey, ...routeProps }: Props) => {
  const { hasNavViewAccess } = useKeycloakContext()

  return hasNavViewAccess(accessKey) ? (
    <Route {...routeProps} />
  ) : (
    <AccessErrorPage />
  )
}
