import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ApolloError, useQuery, useSubscription } from '@apollo/client'
import moment, { Moment } from 'moment'
import { useHistory, useLocation } from 'react-router-dom'

import { ById } from '@/common/types'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { NotFoundPage } from '@/components/Page'
import { orderQueries } from '@/modules/Order/queries'
import { orderSubscriptions } from '@/modules/Order/subscriptions'
import {
  OrdersPayload,
  OrderSubscription,
  OrderSubscriptionVariables,
  OrdersVariables,
  OrderType as Order,
} from '@/modules/Order/types'

import {
  SalesAttributesInput,
  SalesCopyComponent as CopyComponents,
  SalesVstSetAttributesInput,
} from '~generated-types'

import * as salesMutations from '../../mutations'
import {
  RefreshVatEnabledPayload,
  RefreshVatEnabledVariables,
  SalesDetailsForPrintPayload,
  SalesDetailsForPrintVariables,
  SalesDetailsPayload,
  SalesDetailsVariables,
  SalesIssuesPayload,
  SalesIssuesVariables,
  salesQueries,
} from '../../queries'
import {
  SalesDetails as SalesDetailsType,
  SalesForPrint,
  SalesIssues,
  SalesPrintConfig,
  SalesPrintConfigKey,
  UpdateAttributesResponse,
} from './types'
import {
  getContentColors,
  getSalesPrintQueryConditions,
  getSelectedViews,
  modifySalesPrintConfig,
  SALES_PRINT_CONFIG,
  updateOrdersFromSubscription,
} from './utils'

type Props = {
  baseRoute: string
  children: ReactNode
  id: string
  viewType?: 'primary' | 'secondary'
  externalEmbeddedSections?: string[]
  onSectionsUpdate?: (sections: string[]) => void
}

type StateType<T> = T | ((data: T) => T)

type ContextType = {
  contentColors: {
    [key: string]: string
  }
  copySale: (
    copyComponents: CopyComponents[],
    newStartDate: string,
    salesId: string
  ) => Promise<string | null>
  data: SalesDetailsType
  embeddedActiveSections: string[]
  focusedOrderId: string | null
  getEstimatedEndDate: () => Moment | null | undefined
  getEstimatedStartDate: () => Moment | null | undefined
  isFetchingSalesForPrint: boolean
  navItemEmbeddedSelect: (navParam: string) => void
  navItemEmbeddedUnselect: (navParam: string) => void
  navItemSelect: (navParam: string) => void
  navItemUnselect: (navParam: string) => void
  ordersById: ById<Order>
  ordersError: ApolloError | undefined
  ordersLoading: boolean
  refresh: () => Promise<any>
  routeState: string
  saleReadOnly: boolean
  salesForPrint?: SalesForPrint
  salesForPrintLoading: boolean
  salesIssues: SalesIssues | null
  salesPrintConfig: SalesPrintConfig[]
  salesPrintShowPrice: boolean
  scrollToComponent: string | null
  refreshVatEnabled: boolean
  refreshVatEnabledError: ApolloError | undefined
  refreshingProductsVat: boolean
  setEmbeddedActiveSections: (sections: string[]) => void
  setFetchingSalesForPrint: (isFetching: boolean) => void
  setFocusedOrderId: (focusedOrderId: string | null) => void
  setOrder: (order: Order) => void
  setOrdersById: (orders: StateType<ById<Order>>) => void
  setSalesPrintShowPrice: (showPrice: boolean) => void
  setScrollToComponent: (scrollToComponent: string | null) => void
  setVstAttributes: (input: SalesVstSetAttributesInput) => Promise<any>
  updateAttributes: (
    salesId: string,
    attributes: SalesAttributesInput
  ) => Promise<UpdateAttributesResponse | null | undefined>
  updateSalesPrintConfig: (config: SalesPrintConfig[], path: string) => void
  updateSalesPrintConfigGrouping: (
    key: SalesPrintConfigKey,
    by: SalesPrintConfigKey
  ) => void
  viewType?: 'primary' | 'secondary'
  refreshProductsVat: (salesId: string) => Promise<any> | void
}

const SalesDetailsContext = createContext<ContextType>({
  contentColors: {},
  copySale: Promise.reject,
  // @ts-ignore – this does not happen in real life
  data: null,
  embeddedActiveSections: [],
  focusedOrderId: null,
  getEstimatedEndDate: () => null,
  getEstimatedStartDate: () => null,
  isFetchingSalesForPrint: false,
  navItemEmbeddedSelect: () => null,
  navItemEmbeddedUnselect: () => null,
  navItemSelect: () => null,
  navItemUnselect: () => null,
  ordersById: {},
  ordersError: undefined,
  ordersLoading: true,
  refresh: Promise.reject,
  refreshingProductsVat: false,
  refreshProductsVat: Promise.reject,
  refreshVatEnabled: false,
  refreshVatEnabledError: undefined,
  routeState: '',
  saleReadOnly: false,
  salesForPrint: undefined,
  salesForPrintLoading: false,
  salesIssues: null,
  salesPrintConfig: [],
  salesPrintShowPrice: false,
  scrollToComponent: null,
  setEmbeddedActiveSections: () => undefined,
  setFetchingSalesForPrint: () => undefined,
  setFocusedOrderId: () => undefined,
  setOrder: () => undefined,
  setOrdersById: () => undefined,
  setSalesPrintShowPrice: () => undefined,
  setScrollToComponent: () => undefined,
  setVstAttributes: Promise.reject,
  updateAttributes: Promise.reject,
  updateSalesPrintConfig: () => undefined,
  updateSalesPrintConfigGrouping: () => undefined,
  viewType: undefined,
})

export const SalesDetailsContextProvider = ({
  baseRoute,
  children,
  id,
  viewType = 'primary',
  externalEmbeddedSections,
  onSectionsUpdate,
}: Props) => {
  const contextValueRef = useRef<ContextType | null | undefined>(null)

  const history = useHistory()
  const { search } = useLocation()

  const [ordersById, setOrdersById] = useState<ById<Order>>({})
  const [ordersLoading, setOrdersLoading] = useState<boolean>(true)
  const [routeState, setRouteState] = useState(baseRoute)
  const [doubleClickTimer, setDoubleClickTimer] = useState<{
    navParam: string
  } | null>(null)
  const [focusedOrderId, setFocusedOrderId] = useState<string | null>(null)
  const [scrollToComponent, setScrollToComponent] = useState<string | null>(
    null
  )
  const [embeddedActiveSections, setEmbeddedActiveSections] = useState<
    string[]
  >(externalEmbeddedSections || [])
  const [isFetchingSalesForPrint, setFetchingSalesForPrint] =
    useState<boolean>(false)
  const [salesPrintConfig, setSalesPrintConfig] = useState<SalesPrintConfig[]>(
    JSON.parse(localStorage.getItem('salesPrintConfig') || 'null') ??
      SALES_PRINT_CONFIG
  )
  const [salesPrintShowPrice, setSalesPrintShowPrice] = useState<boolean>(
    JSON.parse(localStorage.getItem('salesPrintShowPrice') || 'true') ?? true
  )
  const [refreshingProductsVat, setRefreshingProductsVat] =
    useState<boolean>(false)

  useEffect(() => {
    externalEmbeddedSections &&
      setEmbeddedActiveSections(externalEmbeddedSections)
  }, [externalEmbeddedSections])

  useEffect(() => {
    if (!onSectionsUpdate) {
      return
    }

    const sections = getSelectedViews(search)

    viewType === 'primary'
      ? onSectionsUpdate(sections)
      : onSectionsUpdate(embeddedActiveSections)
  }, [embeddedActiveSections, search])

  const [handleUpdateAttributes] =
    salesMutations.useUpdateSalesAttributesMutation()
  const [handleSetVstAttributes] = salesMutations.useSaleVstSetAttributes()
  const [handleCopySale] = salesMutations.useCopySaleMutation()
  const [handleRefreshProductsVat] = salesMutations.useRefreshProductsVat()

  const { data, error, loading, refetch } = useQuery<
    SalesDetailsPayload,
    SalesDetailsVariables
  >(salesQueries.SALES_DETAILS, {
    fetchPolicy: 'network-only',
    variables: { id },
  })

  const { data: salesForPrintData, loading: salesForPrintLoading } = useQuery<
    SalesDetailsForPrintPayload,
    SalesDetailsForPrintVariables
  >(salesQueries.SALES_DETAILS_FOR_PRINT, {
    fetchPolicy: 'network-only',
    skip: !isFetchingSalesForPrint,
    variables: {
      id,
      ...getSalesPrintQueryConditions(salesPrintConfig),
    },
  })

  const { data: salesIssuesData, error: salesIssuesError } = useQuery<
    SalesIssuesPayload,
    SalesIssuesVariables
  >(salesQueries.SALES_ISSUES, {
    fetchPolicy: 'network-only',
    variables: { id },
  })

  const { data: ordersData, error: ordersError } = useQuery<
    OrdersPayload,
    OrdersVariables
  >(orderQueries.ORDERS, {
    fetchPolicy: 'no-cache',
    variables: { ownerId: id },
  })

  const {
    data: refreshVatEnabledData,
    error: refreshVatEnabledError,
    refetch: refreshVatEnabledRefetch,
  } = useQuery<RefreshVatEnabledPayload, RefreshVatEnabledVariables>(
    salesQueries.REFRESH_VAT_ENABLED,
    {
      fetchPolicy: 'no-cache',
      variables: { salesId: id },
    }
  )

  const salesIssues = useMemo(
    () =>
      !salesIssuesError && salesIssuesData ? salesIssuesData.salesIssues : null,
    [salesIssuesData, salesIssuesError]
  )

  const refreshVatEnabled: boolean =
    refreshVatEnabledData?.refreshVatEnabled ?? false

  useEffect(() => {
    if (ordersData?.ordersBySales && !Object.values(ordersById).length) {
      setOrdersLoading(false)
      setOrdersById(
        ordersData.ordersBySales.reduce(
          (acc, val) => ({ ...acc, [val.id]: val }),
          {}
        )
      )
    }

    if (ordersError) {
      setOrdersLoading(false)
    }
  }, [ordersError, ordersData])

  useEffect(() => {
    setOrdersById({})
  }, [id])

  useEffect(() => {
    doubleClickTimer !== null &&
      setTimeout(() => setDoubleClickTimer(null), 300)
  }, [doubleClickTimer])

  useSubscription<OrderSubscription, OrderSubscriptionVariables>(
    orderSubscriptions.ORDER,
    {
      onData({ data: { data: res } }) {
        updateOrdersFromSubscription({
          data: res,
          ordersById,
          setFocusedOrderId,
          setOrder,
          setOrdersById,
        })
      },

      variables: {
        filter: {
          salesId: id,
        },
      },
    }
  )

  if (loading) {
    return <FullScreenLoader />
  }

  if (error || !data) {
    return <NotFoundPage />
  }

  const sales: SalesDetailsType = data.sales

  const copySale = (
    components: CopyComponents[],
    newStartDate: string,
    salesId: string
  ) =>
    handleCopySale({
      variables: {
        input: { components, newStartDate, salesId },
      },
    }).then(({ data }) => data?.salesCopy.sales?.id ?? null)

  const getEstimatedEndDate = () =>
    sales.estimatedDates
      ? moment(sales.estimatedDates.end)
      : sales.reservationDates
        ? moment(sales.reservationDates.end)
        : null

  const getEstimatedStartDate = () =>
    sales.estimatedDates
      ? moment(sales.estimatedDates.start)
      : sales.reservationDates
        ? moment(sales.reservationDates.start)
        : null

  const refresh = () => refetch()

  const setOrder = (order: Order) =>
    setOrdersById({
      ...ordersById,
      [order.id]: order,
    })

  const updateAttributes = (
    salesId: string,
    attributes: SalesAttributesInput
  ) =>
    handleUpdateAttributes({
      variables: {
        input: {
          attributes,
          salesId,
        },
      },
    }).then(({ data }) => data?.salesUpdateAttributes)

  const updateSalesPrintConfig = (config: SalesPrintConfig[], path: string) => {
    const modifiedConfig = modifySalesPrintConfig(config, path)

    localStorage.setItem('salesPrintConfig', JSON.stringify(modifiedConfig))

    return setSalesPrintConfig(modifiedConfig)
  }

  const updateSalesPrintConfigGrouping = (
    key: SalesPrintConfigKey,
    by: SalesPrintConfigKey
  ) =>
    setSalesPrintConfig((current) => {
      const modifiedConfig = current.map((c) =>
        c.key === key && c.group ? { ...c, group: { ...c.group, by } } : c
      )

      localStorage.setItem('salesPrintConfig', JSON.stringify(modifiedConfig))

      return modifiedConfig
    })

  const setVstAttributes = (input: SalesVstSetAttributesInput) =>
    handleSetVstAttributes({
      variables: {
        input,
      },
    }).then(({ data }) => data?.salesVstSetAttributes)

  const navItemSelect = (navParam: string) => {
    const entries = []

    for (const entry of new URLSearchParams(search).entries()) {
      entries.push([entry[0], entry[1]])
    }

    const viewEntries = entries.filter((entry) => entry[0].startsWith('view'))
    const restEntries = entries.filter((entry) => !entry[0].startsWith('view'))
    const paramEntry = entries.find((entry) => entry[1] === navParam)

    setScrollToComponent(navParam)

    const doubleClicked = doubleClickTimer?.navParam === navParam

    const searchParams = new URLSearchParams(
      doubleClicked
        ? [...restEntries, ['view[0]', navParam]]
        : !paramEntry
          ? [
              ...restEntries,
              ...viewEntries,
              [`view[${viewEntries.length}]`, navParam],
            ]
          : [...restEntries, ...viewEntries]
    )

    searchParams.sort()
    history.replace(`${baseRoute}?${searchParams}`)
    setRouteState(`${baseRoute}?${searchParams}`)

    setDoubleClickTimer({
      navParam,
    })
  }

  const navItemUnselect = (navParam: string) => {
    const entries = []

    for (const entry of new URLSearchParams(search).entries()) {
      entries.push([entry[0], entry[1]])
    }

    const viewEntries = entries.filter((entry) => entry[0].startsWith('view'))
    const restEntries = entries.filter((entry) => !entry[0].startsWith('view'))

    setScrollToComponent(navParam)

    const searchParams = new URLSearchParams([
      ...restEntries,
      ...viewEntries
        .filter((entry) => entry[1] !== navParam)
        .map((entry, index) => [`view[${index}]`, entry[1]]),
    ])

    searchParams.sort()
    history.replace(`${baseRoute}?${searchParams}`)
    setRouteState(`${baseRoute}?${searchParams}`)
  }

  const navItemEmbeddedSelect = (navParam: string) => {
    const doubleClicked = doubleClickTimer?.navParam === navParam

    doubleClicked
      ? setEmbeddedActiveSections([navParam])
      : !embeddedActiveSections.includes(navParam) &&
        setEmbeddedActiveSections((current) => [...current, navParam])

    setScrollToComponent(navParam)

    setDoubleClickTimer({
      navParam,
    })
  }

  const navItemEmbeddedUnselect = (navParam: string) => {
    embeddedActiveSections.includes(navParam) &&
      setEmbeddedActiveSections((current) =>
        current.filter((key) => key !== navParam)
      )
  }

  const refreshProductsVat = (salesId: string) => {
    setRefreshingProductsVat(true)
    handleRefreshProductsVat({
      variables: { input: { salesId } },
    }).finally(() => {
      refreshVatEnabledRefetch(), setRefreshingProductsVat(false)
    })
  }

  contextValueRef.current = {
    contentColors: getContentColors(),
    copySale,
    data: sales,
    embeddedActiveSections,
    focusedOrderId,
    getEstimatedEndDate,
    getEstimatedStartDate,
    isFetchingSalesForPrint,
    navItemEmbeddedSelect,
    navItemEmbeddedUnselect,
    navItemSelect,
    navItemUnselect,
    ordersById,
    ordersError,
    ordersLoading,
    refresh,
    refreshingProductsVat,
    refreshProductsVat,
    refreshVatEnabled,
    refreshVatEnabledError,
    routeState,
    saleReadOnly: sales.locked,
    salesForPrint: salesForPrintData,
    salesForPrintLoading,
    salesIssues,
    salesPrintConfig,
    salesPrintShowPrice,
    scrollToComponent,
    setEmbeddedActiveSections,
    setFetchingSalesForPrint,
    setFocusedOrderId,
    setOrder,
    setOrdersById,
    setSalesPrintShowPrice,
    setScrollToComponent,
    setVstAttributes,
    updateAttributes,
    updateSalesPrintConfig,
    updateSalesPrintConfigGrouping,
    viewType,
  }

  return (
    <SalesDetailsContext.Provider value={contextValueRef.current}>
      {children}
    </SalesDetailsContext.Provider>
  )
}

export const useSalesDetailsContext = () => useContext(SalesDetailsContext)
