import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { alignItems, FlexColumn, FlexRow } from '@/components/Layout'
import { H3 } from '@/components/Typography'

type Props = {
  children: ReactNode
  flex?: number
  style?: CSSProperties
  title?: ReactNode
  headerExtra?: ReactNode
  titleAlign?: alignItems
}

export const ContentSection = ({
  children,
  style,
  title = '',
  headerExtra,
  ...props
}: Props) => (
  <Wrapper {...props}>
    <TitleWrapper
      alignItems={props.titleAlign || 'baseline'}
      justifyContent="space-between"
      isHidden={!title}
    >
      <Title>{title}</Title>
      {!!headerExtra && headerExtra}
    </TitleWrapper>
    <FlexColumn flex={1} style={style}>
      {children}
    </FlexColumn>
  </Wrapper>
)

////////////

const Title = styled(H3)`
  font-variant: all-small-caps;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    line-height: ${theme.spacing.gutterBig};
  `}
`

const TitleWrapper = styled(FlexRow)<{
  isHidden?: boolean
}>`
  ${({ theme, isHidden }) =>
    !isHidden &&
    css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
`

const Wrapper = styled.div<{ flex?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex ?? '1 1 auto'};
`
