import { FlexColumn } from '@/components/Layout'
import { DateRange } from '@/components/TimeControls'
import { useEnrollmentsContext } from '@/modules/Sales/components/SalesDetails/routes/Enrollments/EnrollmentsContext'
import { salesHooks } from '@/modules/Sales/hooks'
import { EventEnrollment } from '@/modules/Sales/types'

import { SalesReservationList } from './SalesReservationList'

type Props = {
  defaultRange: DateRange | null | undefined
  salesId: string
  eventId: string | null
  readOnly?: boolean
}

export const AccommodationReservations = ({
  defaultRange,
  salesId,
  eventId,
  readOnly,
}: Props) => {
  const { enrollments } = useEnrollmentsContext()
  const { data } = salesHooks.useSalesDetailsContext()

  const {
    customer,
    reservationDates,
    estimatedDates,
    estimatedParticipants,
    name,
    orderNumber,
  } = enrollments.find((e: EventEnrollment) => e.id === salesId) || data

  const customerData = customer?.customer

  const salesDetails = {
    customerName:
      customerData?.__typename === 'CustomerOrganization'
        ? customerData.organization.name
        : customerData?.__typename === 'CustomerPerson'
          ? `${customerData.person.firstName} ${customerData.person.lastName}`
          : '',
    dates: estimatedDates || reservationDates || null,
    participants: estimatedParticipants,
    saleName: name,
    saleNumber: orderNumber,
  }

  return (
    <FlexColumn
      noPadding
      style={{
        margin: 'auto',
        maxWidth: '1500px',
        width: '100%',
      }}
    >
      <SalesReservationList
        defaultRange={defaultRange}
        salesId={salesId}
        eventId={eventId}
        salesDetails={salesDetails}
        readOnly={readOnly}
      />
    </FlexColumn>
  )
}
