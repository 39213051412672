import { NavExternalViewType } from '@/config/types'

import { NavInternalViewType, NavItemType, NavSortBy } from '~generated-types'

export enum NavDropdownItemType {
  ExternalItem = 'EXTERNAL_ITEM',
  InternalItem = 'INTERNAL_ITEM',
}

type NavDropdownItemBase = {
  openInNewTab: boolean
  to: string
  type: NavDropdownItemType
}

export type NavDropdownExternalItem = NavDropdownItemBase & {
  isEmbedded: boolean
  key: NavExternalViewType
  name: string
  url: string
}

export type NavDropdownInternalItem = NavDropdownItemBase & {
  isExternalUrl?: boolean
  key: NavInternalViewType
}

export type NavDropdownItem = NavDropdownExternalItem | NavDropdownInternalItem

export type NavItem = {
  items: NavDropdownItem[]
  key: NavItemType
  sort: NavSortBy
}
