import { CSSProperties, useState } from 'react'
import { ControlProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import { EducationLevel } from '~generated-types'

import { isSameOption } from '../../utils'

type Props = {
  handleUpdateParticipan: (data: Partial<FetchedParticipant>) => Promise<void>
  selectedParticipants: FetchedParticipant[]
  readOnly: boolean
}

export const EducationSelector = ({
  handleUpdateParticipan,
  selectedParticipants,
  readOnly,
}: Props) => {
  const { spacing, palette } = useTheme()
  const { language } = useLanguageContext()

  const education = isSameOption(selectedParticipants, 'education')
    ? selectedParticipants[0]?.education || null
    : null

  const [isLoading, setLoading] = useState<boolean>(false)

  const extraStyles = {
    container: (styles: CSSProperties) => ({
      ...styles,
      fontWeight: 400,
      marginBottom: spacing.gutter,
      width: '100%',
    }),
    control: (
      styles: CSSProperties,
      { isDisabled, isFocused }: ControlProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      background: isDisabled ? palette.smoke.lighter : 'transparent',
      borderColor: isFocused ? palette.primary.main : palette.smoke.dark,
      cursor: 'pointer',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
  }

  const educationOptions = Object.values(EducationLevel).map(
    (education: EducationLevel) => ({
      label: translate(`enums:education.${education}`, language),
      value: education,
    })
  )

  const handleMassUpdateEducation = async (value: EducationLevel | null) => {
    setLoading(true)
    handleUpdateParticipan({ education: value }).then(() => setLoading(false))
  }

  return (
    <FlexColumn style={{ width: '100%' }}>
      <Title>
        {translate('ParticipantsList:PersonalFields.education', language)}
      </Title>
      <ThemedSelect
        extraStyles={extraStyles}
        isClearable
        isDisabled={readOnly}
        isLoading={isLoading}
        name="education-selector"
        onChange={(selected: Option | null | undefined) =>
          handleMassUpdateEducation(
            selected ? (selected.value as EducationLevel) : null
          )
        }
        options={educationOptions}
        placeholder=""
        value={
          education
            ? educationOptions.find(({ value }) => value === education)
            : null
        }
      />
    </FlexColumn>
  )
}

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
