import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import Media from 'react-media'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { useKeycloakContext } from '@/config/keycloak'
import { T } from '@/modules/Language'
import { useLastUsedSalesContext } from '@/modules/Sales/contexts'
import {
  LastUsedSalesPayload,
  LastUsedSalesVariables,
  salesQueries,
} from '@/modules/Sales/queries'
import { useTheme } from '@/theme'

import { NavInternalViewType } from '~generated-types'

import { NavbarButtonStyles } from '../common'
import { LastUsedSalesModal } from './LastUsedSalesModal'

export const LastUsedSalesButton = () => {
  const { lastUsedSalesIds: ids } = useLastUsedSalesContext()
  const { hasNavViewAccess } = useKeycloakContext()
  const { palette } = useTheme()

  const [isOpen, setOpen] = useState<boolean>(false)
  const [isShowAll, setShowAll] = useState<boolean>(false)

  const hasAccess = hasNavViewAccess(NavInternalViewType.SearchSales)

  const { data, loading } = useQuery<
    LastUsedSalesPayload,
    LastUsedSalesVariables
  >(salesQueries.LAST_USED_SALES, {
    fetchPolicy: 'no-cache',
    skip: !isOpen,
    variables: { ids },
  })

  const onClose = () => {
    setOpen(false)
    setShowAll(false)
  }

  const renderLabel = () => (
    <Media query={{ minWidth: 1150 }}>
      {(matches) =>
        matches && (
          <Label>
            <T>Sales:lastUsedSales.title</T>
          </Label>
        )
      }
    </Media>
  )

  return hasAccess ? (
    <ModalContainer
      isOpen={isOpen && !loading}
      modal={
        data && (
          <LastUsedSalesModal
            isShowAll={isShowAll}
            onClose={onClose}
            onShowAll={() => setShowAll(true)}
            sales={[...data.salesAll].sort(
              (a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)
            )}
          />
        )
      }
      onClose={onClose}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <ButtonWrapper ref={ref}>
          <Button
            to={'/'}
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }}
          >
            {loading ? (
              <ReactLoading
                color={palette.primary.dark}
                height={12}
                type="spin"
                width={12}
              />
            ) : (
              <Icon icon="clock-rotate-left" />
            )}

            {renderLabel()}
          </Button>
        </ButtonWrapper>
      )}
    />
  ) : (
    <Tooltip
      content={<T>common:error.access.title</T>}
      placement="bottom"
      trigger={(triggerProps) => (
        <ButtonWrapper {...triggerProps}>
          <Button disabled to="">
            <Icon icon="lock" size="sm" style={{ marginBottom: 2 }} />

            {renderLabel()}
          </Button>
        </ButtonWrapper>
      )}
    />
  )
}

////////

const Button = styled(NavLink)`
  ${NavbarButtonStyles}
`

const ButtonWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const Icon = styled(FontAwesomeIcon)`
  height: 12px;
  width: 12px;
`

const Label = styled.span`
  font-weight: 600;
  user-select: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
