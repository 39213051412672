import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { EditButton } from '@/components/ExtraButtons'
import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import {
  useSetParticipantCheckIn,
  useSetParticipantCheckOut,
} from '@/modules/FrontDesk/components/DailyRoomReservations/mutations'
import {
  Participant,
  ParticipantRoom,
} from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { T } from '@/modules/Language'
import { ParticipantCheck } from '@/modules/ParticipantsList/components'
import { useTheme } from '@/theme'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { Placeholder } from './common'

type Props = {
  openParticipantManager: (id: string | null) => void
  participantRooms: Omit<ParticipantRoom, 'participant'>[]
  participant: Participant
  readOnly: boolean
}

export const ParticipantSection = ({
  openParticipantManager,
  participantRooms,
  participant,
  readOnly,
}: Props) => {
  const { palette, typography, spacing } = useTheme()

  const { id, sales } = participant

  const [setCheckIn] = useSetParticipantCheckIn()
  const [setCheckOut] = useSetParticipantCheckOut()

  const handleSetCheckIn = (id: string, timestamp?: string | null) =>
    setCheckIn({
      variables: {
        input: {
          participantRoomReservationIds: [id],
          timestamp,
        },
      },
    }).catch(() => undefined)

  const handleSetCheckOut = (id: string, timestamp?: string | null) =>
    setCheckOut({
      variables: {
        input: {
          participantRoomReservationIds: [id],
          timestamp,
        },
      },
    }).catch(() => undefined)

  const openSalesInNewTab = (salesId: string) => {
    const newWindow = window.open(
      `/sales/details/${salesId}?view[0]=participants`,
      '_blank',
      'noopener,noreferrer'
    )

    if (newWindow) {
      newWindow.opener = null
    }
  }

  const style = {
    color: palette.text.light,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
  }

  return (
    <>
      <InlineModalIconSection icon="user">
        <EditButton onClick={() => openParticipantManager(id)} style={style}>
          {getParticipantName(participant)}
        </EditButton>
      </InlineModalIconSection>

      <InlineModalIconSection icon="euro">
        <EditButton
          customIcon={
            <FontAwesomeIcon
              color={palette.primary.main}
              icon="up-right-from-square"
              size="sm"
            />
          }
          onClick={() => openSalesInNewTab(sales.id)}
          style={style}
        >
          #{sales.orderNumber} {sales.name}
        </EditButton>
      </InlineModalIconSection>

      {participantRooms
        .sort(
          (a, b) =>
            moment(a.request.checkIn.date).valueOf() -
            moment(b.request.checkIn.date).valueOf()
        )
        .map(({ checkIn, checkOut, id, request }) => (
          <InlineModalIconSection icon={['far', 'calendar']} key={id}>
            <FlexRow alignItems="center" justifyContent="space-between">
              <span style={{ fontSize: typography.fontSizeSmall }}>
                {formatDateRangeWithoutRepetition(
                  request.checkIn.date,
                  request.checkOut.date,
                  'short'
                )}
              </span>
              <ParticipantCheck
                checkIn={checkIn}
                checkOut={checkOut}
                disabled={readOnly}
                handleSetCheckIn={(time) => handleSetCheckIn(id, time)}
                handleSetCheckOut={(time) => handleSetCheckOut(id, time)}
              />
            </FlexRow>
          </InlineModalIconSection>
        ))}

      <InlineModalLine dashed />
    </>
  )
}

////////////

const getParticipantName = ({ firstName, lastName }: Participant) =>
  !firstName && !lastName ? (
    <Placeholder>
      <T>FrontDesk:DailyRoomReservations.participants.lastName</T>{' '}
      <T>FrontDesk:DailyRoomReservations.participants.firstName</T>
    </Placeholder>
  ) : (
    `${lastName}${firstName && lastName ? ' ' : ''}${firstName}`
  )
