import { useRouteDate, useRouteValue } from '@/utils/hooks'

import RoomStatusListing from './RoomStatusListing'
import { RoomReservationFilter as Filter } from './types'
import useRoomStatuses from './useRoomStatuses'

export const RoomStatusListingContainer = () => {
  const { date, setDate } = useRouteDate()
  const { setValue: setFilters, value: filters } = useRouteValue({
    multi: true,
    routeKey: 'filter',
    whitelist: [Filter.HIDE_FREE, Filter.HIDE_LODGING],
  })
  const { error, loading, roomReservations } = useRoomStatuses({ date })

  return (
    <RoomStatusListing
      date={date}
      error={error}
      filters={(filters as Filter[]) || []}
      loading={loading}
      roomReservations={roomReservations}
      setDate={setDate}
      setFilters={setFilters}
    />
  )
}

export default RoomStatusListingContainer
