import { PartialResponse, Response as ParsedResponse } from './types'

type Options = Record<string, any>

const defaultMethod = 'GET'

export const apiCall = <T>(
  path: string,
  options?: Options
): Promise<ParsedResponse<T>> => {
  const { headers = {}, method, ...rest } = options || {}
  const optionsWithDefaults = {
    headers: {
      ...headers,
    },
    method: method || defaultMethod,
    ...rest,
  }

  // @ts-ignore
  return (
    fetch(`${path}`, optionsWithDefaults)
      .then((...args) => tryAndGetJSON<T>(...args))
      .then((...args) => handleErrors<T>(...args)) // Ignore the case where catch may return and error to save devs time from
      // having to check whether the returned value is an Error.
      .catch<Error>((...args) => handleConnectionErrors(...args))
  )
}

// Can't successfully complete a request with the target server. Application
// will be rendered useless in may cases.
const handleConnectionErrors = (error: Error): Error => {
  console.error("Request wasn't completed successfully", error)
  return error
}

// Communication with the server occurs without errors, but the target server
// responds with an error.
const handleErrors = <T>(response: ParsedResponse<T>): ParsedResponse<T> => {
  if (!response.ok) {
    console.error('Server responded with an error', response.data)
  }

  return response
}

// Attempts to transform the response body into JSON if possible.
const tryAndGetJSON = async <T>(
  response: Response
): Promise<ParsedResponse<T>> => {
  const contentType = response.headers.get('content-type')
  const isJson = contentType && contentType.includes('json')
  let content: any = null

  // If content is JSON, parse it
  if (isJson) {
    const json = await response.json()
    content = json
  }

  // Otherwise output text
  if (!content) {
    const text = await response.text()
    content = text
  }

  // We trust that the user of this helpers know what the API returns
  const castData: T = content as any

  return {
    ...getResponseAsObject(response),
    data: castData,
  }
}

// Response is an interface and hence doesn't conform with normal object
// features.
const getResponseAsObject = (res: Response): PartialResponse => ({
  headers: res.headers,
  ok: res.ok,
  status: res.status,
  statusText: res.statusText,
})
