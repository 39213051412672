import { gql } from '@apollo/client'

export default gql`
  query SaleAccommodation($input: SuitableRoomsInput!) {
    suitableRooms(input: $input) {
      roomReservation {
        id
        participantRooms {
          id
          participant {
            id
            firstName
            lastName
          }
        }
        target {
          id
          name
          sortOrder
          accommodationGroup {
            id
            name
            sortOrder
          }
        }
        request {
          beds
          extraBeds
          features {
            icon
            id
            name
            shortName
          }
          room {
            id
            number
            beds
            extraBeds
            status {
              cleaningStatus
            }
            roomType {
              id
              name
              accommodationLevel {
                id
                shortName
              }
              category {
                id
              }
            }
          }
          checkIn {
            date
            type
          }
          checkOut {
            date
            type
          }
          info
        }
      }
    }
  }
`
