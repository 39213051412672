import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import {
  CheckType,
  Reservation,
  ReservationType,
} from '@/modules/FrontDesk/components/DailyRoomReservations/types'

import { CheckInOut } from './CheckInOut'

type Props = {
  reservation: Reservation
  type: ReservationType
}

export const CheckStatus = ({ reservation, type }: Props) => {
  switch (type) {
    case ReservationType.Arrivinng:
      return <CheckInOut action={CheckType.CheckIn} reservation={reservation} />
    case ReservationType.Departing:
      return (
        <CheckInOut action={CheckType.CheckOut} reservation={reservation} />
      )
    case ReservationType.Ongoing:
    default:
      return (
        <FlexRow justifyContent="center" style={{ width: '100px' }}>
          <FontAwesomeIcon icon="bed" size="sm" />
        </FlexRow>
      )
  }
}
