import { gql } from '@apollo/client'

import capacityStatus from './capacityStatus'

export default gql`
  ${capacityStatus}

  fragment AccommodationTarget on AccommodationTarget {
    accommodationGroup {
      id
      name
      sortOrder
    }
    default
    floating
    id
    name
    nonBlockingCapacity
    private
    sharedTo {
      id
      orderNumber
    }
    sortOrder
    status {
      ...CapacityStatus
    }
  }
`
