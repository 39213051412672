import { NavInternalViewType } from '~generated-types'

export enum NavExternalViewType {
  BookingExternal = 'BOOKING_EXTERNAL',
  FrontDeskExternal = 'FRONT_DESK_EXTERNAL',
  ListingExternal = 'LISTING_EXTERNAL',
  RegistryExternal = 'REGISTRY_EXTERNAL',
  ReportExternal = 'REPORT_EXTERNAL',
  SearchExternal = 'SEARCH_EXTERNAL',
}

export { NavInternalViewType }

export type NavViewKey = NavInternalViewType | NavExternalViewType

export type ResourceAccess = {
  [key: string]: {
    roles: string[]
  }
}

export type UserInfoPayload = {
  sub?: string
  email_verified?: boolean
  realm_access?: {
    roles: string[]
  }
  resource_access?: ResourceAccess
  name?: string
  preferred_username?: string
  given_name?: string
  locale?: string
  family_name?: string
  picture?: string
  email?: string
}

export type UserInfo = {
  email: string | null
  name: string
  picture: string | null
  sub: string | null
}
