import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableSelect } from '@/components/DataTable'
import { translate, useLanguageContext } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import type { ProductRule } from '@/modules/Products/types'

import { ActionRuleType as ART } from '~generated-types'

type Props = {
  rule: ProductRule
}

export const ActionSelector = ({ rule: { action, id } }: Props) => {
  const { language } = useLanguageContext()
  const { readOnly, updateProductItemRule } = useCatalogProductManagerContext()

  const translationPath = 'Products:ProductManager.pricing.rule.action'

  const ACTIONS = [
    {
      label: translate(`${translationPath}.${ART.Exclude}`, language),
      value: ART.Exclude,
    },
    {
      label: translate(`${translationPath}.${ART.Amount}`, language),
      value: ART.Amount,
    },
    {
      label: translate(`${translationPath}.${ART.Only}`, language),
      value: ART.Only,
    },
  ]

  const handleUpdateRuleAction = (actionType: ART) =>
    updateProductItemRule({
      action: { actionType },
      productRuleId: id,
    })

  return (
    <Select
      disabled={readOnly}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        handleUpdateRuleAction(e.target.value as ART)
      }}
      value={action.type}
    >
      {ACTIONS.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

/////////

const Select = styled(DataTableSelect)`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      margin-right: ${theme.spacing.gu(1.5)}rem;
      padding: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(0.5)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
        opacity: 1;
      `}
    }
  }
`
