import { isMobile } from 'react-device-detect'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { MainNavbarDesktop, MainNavbarMobile } from '@/components/MainNavbar'
import { useKeycloakContext } from '@/config/keycloak'

import { AccessErrorPage } from '../../components/Page/AccessErrorPage'
import { FrameMobile } from './FrameMobile'
import { AppRoutesDesktop, AppRoutesMobile } from './routes'

export const App = () => {
  const { hasUserAccess } = useKeycloakContext()

  const desktopAccess = hasUserAccess('willba-office:access')
  const mobileAccess = hasUserAccess('willba-mobile:access')

  const showMobile =
    mobileAccess && (!desktopAccess || (desktopAccess && isMobile))
  const showDesktop =
    desktopAccess && (!mobileAccess || (mobileAccess && !isMobile))

  if (showMobile) {
    return (
      <FrameMobile>
        <MainNavbarMobile />
        <AppRoutesMobile />
      </FrameMobile>
    )
  }

  if (showDesktop) {
    return (
      <Frame>
        <MainNavbarDesktop />
        <AppRoutesDesktop />
      </Frame>
    )
  }

  return <AccessErrorPage />
}

////////////

const Frame = styled(FlexColumn)`
  position: relative;
  flex-shrink: 0;
  height: 100%;
  z-index: 1;
`
