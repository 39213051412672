import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { DropdownButton, LinkLikeButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { Catalog, catalogHooks } from '@/modules/Registry/Catalog'
import { generateCompareFn } from '@/utils/arrays'

type TargetCatalog = {
  id: string
  sellerId: string
}

type Props = {
  readOnly: boolean
  setTargetCatalog: (targetCatalog: TargetCatalog) => void
}

export const ListControls = ({ readOnly, setTargetCatalog }: Props) => {
  if (readOnly) {
    return (
      <FlexRow flex={1}>
        <Tooltip
          content={<T>common:error.access.helper</T>}
          trigger={(triggerProps) => (
            <AddButton {...triggerProps} disabled>
              + <T>Products:CatalogProductList.add</T>
            </AddButton>
          )}
        />
      </FlexRow>
    )
  }

  const { catalogs, loading } = catalogHooks.useCatalogs()

  const activeCatalogs = catalogs.filter(({ active }) => active)

  const handleSelectCatalog = ({ company, id }: Catalog) =>
    setTargetCatalog({ id, sellerId: company.id })

  const options = [...activeCatalogs]
    .sort(generateCompareFn('name'))
    .map((catalog) => ({
      label: (
        <>
          <Icon icon="list" size="sm" />
          {catalog.name}
        </>
      ),
      onClick: () => handleSelectCatalog(catalog),
    }))

  return (
    <FlexRow flex={1}>
      {activeCatalogs.length === 1 ? (
        <AddButton
          disabled={loading}
          onClick={() => handleSelectCatalog(activeCatalogs[0])}
        >
          + <T>Products:CatalogProductList.add</T>
        </AddButton>
      ) : (
        <DropdownButton
          options={options}
          dropdownPlacement="bottom-start"
          renderCustomButton={({ onClick }) => (
            <AddButton disabled={loading} onClick={onClick}>
              + <T>Products:CatalogProductList.add</T>
            </AddButton>
          )}
          renderCustomEmptyLabel={() => (
            <T>Products:CatalogProductList.catalog.empty</T>
          )}
          renderCustomTitle={() => (
            <Title>
              <T>Products:CatalogProductList.catalog.select</T>
            </Title>
          )}
          zIndex={10006}
        />
      )}
    </FlexRow>
  )
}

////////////

const AddButton = styled(LinkLikeButton)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gutter};
  `}

  &:disabled {
    background: transparent;
  }
`

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 12px;
    margin-right: 10px;
  }
`

const Title = styled(Label)`
  margin: 0;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gu(2)}rem;
  `}
`
