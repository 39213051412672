import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { participantQueries } from '@/modules/ParticipantsList/queries'
import { FetchedParticipant } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import { SpecialDietsQuery, SpecialDietsQueryVariables } from '~generated-types'

import DietValueWrapper from './DietValueWrapper'

type Props = {
  specialDiets: string[]
  handleUpdateDiets: (
    dietaryNeeds: string[]
  ) => Promise<FetchedParticipant | undefined>
}

const SpecialDiets = ({ specialDiets, handleUpdateDiets }: Props) => {
  const { spacing } = useTheme()

  const [diets, setDiets] = useState(specialDiets)

  const { data } = useQuery<SpecialDietsQuery, SpecialDietsQueryVariables>(
    participantQueries.SPECIAL_DIETS,
    {
      fetchPolicy: 'cache-first',
    }
  )

  const dietaryOptions =
    data?.registry.diets
      .map(({ name }) => name)
      .filter((diet) => !diets.find((d) => d === diet)) || []

  return (
    <FlexColumn style={{ marginTop: spacing.gutter }}>
      {diets.map((diet, index) => (
        <DietValueWrapper
          key={diet}
          value={diet}
          dietaryOptions={dietaryOptions}
          placeholder={<T>ParticipantsList:PersonalFields.addSpecialDiet</T>}
          onRemove={() => {
            const dietsList = diets.filter((d) => d !== diet)
            setDiets(dietsList)
            return handleUpdateDiets(dietsList)
          }}
          onAdd={(dietValue) => {
            const dietsList = diets.map((diet, i) =>
              i === index ? dietValue : diet
            )
            setDiets(dietsList)
            return handleUpdateDiets(dietsList)
          }}
        />
      ))}
      <DietValueWrapper
        value={null}
        dietaryOptions={dietaryOptions}
        placeholder={<T>ParticipantsList:PersonalFields.addSpecialDiet</T>}
        onRemove={() => Promise.reject()}
        onAdd={(dietValue) => {
          const dietsList = [...diets, dietValue]
          setDiets(dietsList)
          return handleUpdateDiets(dietsList)
        }}
      />
    </FlexColumn>
  )
}

export default SpecialDiets
