import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableSelect } from '@/components/DataTable'
import { useMealsConfig } from '@/modules/Meals'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import type { ProductMealLocation } from '@/modules/Products/types'

import { LocationType } from '~generated-types'

type Props = {
  location: ProductMealLocation | null
  mealId: string
}

export const RestaurantSelect = ({ location, mealId }: Props) => {
  const { restaurants } = useMealsConfig()
  const { readOnly, updateProductItemMeal } = useCatalogProductManagerContext()

  const handleUpdateRestaurant = (locationId: string) =>
    updateProductItemMeal({
      id: mealId,
      location: { id: locationId, type: LocationType.Restaurant },
    })

  return (
    <Select
      disabled={readOnly}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        handleUpdateRestaurant(e.target.value)
      }
      showBorder
      value={restaurants && location ? location.id : '–'}
    >
      {restaurants.map((r) => (
        <option key={r.id} value={r.id}>
          {r.name}
        </option>
      ))}
    </Select>
  )
}

/////////

const Select = styled(DataTableSelect)`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(0.5)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
        opacity: 1;
      `}
    }
  }
`
