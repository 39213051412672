import { ColDef, ColGroupDef } from 'ag-grid-community'

import { AgGridSearchList } from '@/components/AgGrid'
import { naturalSortComparator } from '@/components/AgGrid/utils'
import { translate, useLanguageContext } from '@/modules/Language'

import buildingHooks from '../hooks'

export const BuildingList = () => {
  const { language } = useLanguageContext()

  const { buildings, error, loading } = buildingHooks.useBuildings()

  const columns: (ColDef | ColGroupDef)[] = [
    {
      comparator: naturalSortComparator,
      field: 'id',
      flex: 1,
      headerName: translate('Building:field.id', language),
    },
    {
      field: 'name',
      flex: 3,
      headerName: translate('Building:field.name', language),
      sort: 'asc',
    },
  ]

  return (
    <AgGridSearchList
      columnDefs={columns}
      error={!!error}
      loading={loading}
      rowData={buildings}
      title={translate('Building:title', language)}
    />
  )
}
