import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { useTheme } from '@/theme'

export const FullScreenLoader = () => {
  const { palette } = useTheme()

  return (
    <Wrapper>
      <ReactLoading type="bubbles" color={palette.smoke.dark} />
    </Wrapper>
  )
}

//////

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
