import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { translate, useLanguageContext } from '@/modules/Language'
import { T } from '@/modules/Language'
import { EventEnrollment } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import { SalesType, ServiceParticipantBedFragment } from '~generated-types'

import Label from '../../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemRounded from '../../../ParticipantRow/common/ServiceItemRounded'
import TargetModalContent from './components/TargetSelector/TargetModalContent'

type SetTargetInput = {
  targetId: string
  serviceId: string | null
}

type Props = {
  salesId: string
  participantSaleCustomer:
    | NonNullable<EventEnrollment['customer']>['customer']
    | null
  target: ServiceParticipantBedFragment['accommodationTarget'] | null
  hasRoom: boolean
  hasPurchase: boolean
  isDisabled: boolean
  serviceId: string | null
  saleType: SalesType | null
  isEventParticipant?: boolean
  handleSetTarget: (input: SetTargetInput) => Promise<any>
  handleDeleteService: (id: string) => Promise<any>
}

export const ServiceTarget = ({
  salesId,
  participantSaleCustomer,
  target,
  hasRoom,
  hasPurchase,
  isDisabled,
  serviceId,
  saleType,
  isEventParticipant,
  handleSetTarget,
  handleDeleteService,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const setTarget = ({ targetId, serviceId }: SetTargetInput) => {
    setProcessing(true)
    setModalOpen(false)

    return handleSetTarget({ serviceId, targetId }).finally(() =>
      setProcessing(false)
    )
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal style={{ minHeight: '170px', minWidth: '485px' }}>
          <TargetModalContent
            salesId={salesId}
            newTargetNamePreset={
              saleType === SalesType.Event && !isEventParticipant
                ? participantSaleCustomer?.__typename === 'CustomerOrganization'
                  ? participantSaleCustomer?.organization.name || ''
                  : participantSaleCustomer?.__typename === 'CustomerPerson'
                    ? `${participantSaleCustomer?.person.firstName} ${participantSaleCustomer?.person.lastName}`
                    : ''
                : ''
            }
            serviceId={serviceId}
            saleType={saleType}
            handleSetTarget={setTarget}
            onClose={() => setModalOpen(false)}
          />
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <ServiceItemRounded
          ref={ref}
          width={'8rem'}
          isRemovable={!hasRoom && !hasPurchase && !!target}
          disabled={isDisabled || !!hasRoom}
          contentTooltip={
            target
              ? target.accommodationGroup.name
                ? target.accommodationGroup.name
                : `${translate(
                    `ParticipantsList:ParticipantFormFields.group`,
                    language
                  )} #${target.accommodationGroup.sortOrder}`
              : ''
          }
          onClick={() =>
            isDisabled || hasRoom || processing ? null : setModalOpen(true)
          }
          renderContent={
            processing ? (
              <FlexRow justifyContent="center">
                <ReactLoading
                  color={palette.smoke.main}
                  height={20}
                  type="spin"
                  width={20}
                />
              </FlexRow>
            ) : target ? (
              <ContentWrapper>
                <FontAwesomeIcon
                  color={palette.primary.main}
                  icon="user-group"
                  style={{ marginRight: `${spacing.gu(1)}rem` }}
                />
                <TargetName>
                  {target.default
                    ? translate('Accommodation:TargetGroup.default', language)
                    : target.name
                      ? target.name
                      : `${translate(
                          `Accommodation:TargetGroup.target`,
                          language
                        )} #${target.sortOrder}`}
                </TargetName>
              </ContentWrapper>
            ) : (
              <>
                <FontAwesomeIcon
                  color={palette.primary.main}
                  fixedWidth
                  size="xs"
                  icon="plus"
                />
                <Label>
                  <T>Accommodation:TargetGroup.target</T>
                </Label>
              </>
            )
          }
          handleDelete={() =>
            serviceId ? handleDeleteService(serviceId) : Promise.reject()
          }
        />
      )}
    />
  )
}

const TargetName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0;
  width: 100%;
  text-align: center;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`
