import { gql } from '@apollo/client'

export default gql`
  query LanguagesRegistry($locale: Language) {
    registry {
      registryLanguages(locale: $locale) {
        code
        id
        name
      }
    }
  }
`
