import { gql } from '@apollo/client'

export default gql`
  query CommunicationTemplates {
    registry {
      communicationTemplates {
        description
        id
        name
      }
    }
  }
`
