import { gql } from '@apollo/client'

import { accommodationFragments } from '@/modules/Accommodation/fragments'

export default gql`
  ${accommodationFragments.accommodationTarget}

  query ServiceSuitableTargets($input: SuitableTargetInput!) {
    serviceSuitableTargets(input: $input) {
      labels
      target {
        ...AccommodationTarget
      }
    }
  }
`
