import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { DataField } from '@/components/DataField'
import { useDialogService } from '@/components/DialogService'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { AccommodationQueries } from '@/modules/Accommodation/SalesReservationList/ReservationList.queries'
import {
  SalesAccommodationAvailability,
  SalesAccommodationGroup,
} from '@/modules/Accommodation/SalesReservationList/SalesReservationList.types'
import { T } from '@/modules/Language'
import {
  AccommodationGroup,
  RoomLayoutMutations,
  RoomReservation as GQLRoomReservation,
  Sale,
  Target,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'

import {
  ContentWrapper,
  Dates,
  Label,
  ModalContentWrapper,
  NoteWrapper,
  NumberInput,
  TextDivider,
  TotalNumber,
  TotalNumberBold,
  TrashIconWrapper,
  VerticalDivider,
  Wrapper,
} from './common'
import { RoomFeatures } from './RoomFeatures'

type Props = {
  groupId: string
  reservation: GQLRoomReservation
  saleId: string
  targetId: string
}

export const RoomReservation = ({
  groupId,
  reservation,
  saleId,
  targetId,
}: Props) => {
  const theme = useTheme()

  const {
    id,
    request: { beds, checkIn, checkOut, extraBeds, features, info, room },
  } = reservation

  const { confirm } = useDialogService()
  const { onSetSales, refreshCalendar, sales, targetSalesId } =
    useRoomLayoutContext()

  const [isHover, setHover] = useState<boolean>(false)
  const [bedQuantity, setBedQuantity] = useState<number>(beds)
  const [extraBedQuantity, setExtraBedQuantity] = useState<number>(extraBeds)

  const [deleteRoomReservation] = useMutation(
    RoomLayoutMutations.DELETE_ROOM_RESERVATION,
    {
      // Manually update GraphQL cache after removing
      update(cache, { data }) {
        if (targetSalesId === saleId) {
          const salesCache: SalesAccommodationAvailability | null =
            cache.readQuery({
              query: AccommodationQueries.SALE_RESERVATIONS,
              variables: { id: saleId },
            })
          if (salesCache?.sales) {
            const newSales = {
              ...salesCache,
              sales: {
                ...salesCache.sales,
                accommodation: {
                  ...salesCache.sales.accommodation,
                  accommodationGroups:
                    salesCache.sales.accommodation.accommodationGroups.map(
                      (group: SalesAccommodationGroup) =>
                        group.id ===
                        data?.accommodationRoomReservationDelete
                          .accommodationGroup.id
                          ? data?.accommodationRoomReservationDelete
                              .accommodationGroup
                          : group
                    ),
                },
              },
            }

            cache.writeQuery({
              data: { ...newSales },
              query: AccommodationQueries.SALE_RESERVATIONS,
            })
          }
        }
      },
    }
  )
  const [setBedQuatity] = useMutation(
    RoomLayoutMutations.SET_ROOM_RESERVATION_BED_QUANTITY
  )
  const [setNote] = useMutation(RoomLayoutMutations.SET_ROOM_RESERVATION_NEEDS)

  const handleDeleteRoomReservation = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.remove</T>,
      description: (
        <>
          <T>RoomLayout:sidebar.confirmRoomReservationRemoval.description</T>
          <ModalContentWrapper>{renderContent()}</ModalContentWrapper>
        </>
      ),
    })
      .then(() =>
        deleteRoomReservation({
          variables: {
            input: {
              id,
            },
          },
        })
      )
      .then(() => {
        refreshCalendar()
        onSetSales(
          sales.map((sale: Sale) =>
            sale.id === saleId
              ? {
                  ...sale,
                  accommodationGroups: sale.accommodationGroups.map(
                    (group: AccommodationGroup) =>
                      group.id === groupId
                        ? {
                            ...group,
                            targets: group.targets.map((target: Target) =>
                              target.id === targetId
                                ? {
                                    ...target,
                                    gqlRoomReservations:
                                      target.gqlRoomReservations?.filter(
                                        (reservation: GQLRoomReservation) =>
                                          reservation.id !== id
                                      ),
                                  }
                                : target
                            ),
                          }
                        : group
                  ),
                }
              : sale
          )
        )
      })
      .catch(() => undefined)

  const handleSetBedQuantity = () => {
    setBedQuatity({
      variables: {
        input: {
          beds: bedQuantity,
          extraBeds: extraBedQuantity,
          id,
        },
      },
    })
      .then((res) => {
        const updatedRoomReservation =
          res.data?.accommodationRoomReservationSetBedQuantity.roomReservation

        onSetSales(
          sales.map((sale: Sale) =>
            sale.id === saleId
              ? {
                  ...sale,
                  accommodationGroups: sale.accommodationGroups.map(
                    (group: AccommodationGroup) =>
                      group.id === groupId
                        ? {
                            ...group,
                            targets: group.targets.map((target: Target) =>
                              target.id === targetId
                                ? {
                                    ...target,
                                    gqlRoomReservations:
                                      target.gqlRoomReservations?.map(
                                        (
                                          roomReservation: GQLRoomReservation
                                        ) =>
                                          roomReservation.id === id
                                            ? updatedRoomReservation ||
                                              roomReservation
                                            : roomReservation
                                      ),
                                  }
                                : target
                            ),
                          }
                        : group
                  ),
                }
              : sale
          )
        )
      })
      .catch(() => undefined)
  }

  const handleSetNote = (note: string | null | undefined) =>
    setNote({
      variables: {
        input: {
          featureIds: features,
          id,
          info: note || '',
        },
      },
    })

  const renderContent = () => (
    <FlexColumn noPadding>
      <FlexRow justifyContent="flex-start">
        <Label>{room.number}</Label>

        <TextDivider>|</TextDivider>

        <span>{room.roomType.name}</span>
      </FlexRow>

      <Dates>
        {checkIn.type === CheckInTypes.EARLY && (
          <FontAwesomeIcon
            color={theme.palette.text.lighter}
            icon="sun"
            size="sm"
            style={{ marginRight: theme.spacing.gutterSmall }}
          />
        )}
        {moment(checkIn.date).format('dd, D.M.YY')} {' – '}
        {moment(checkOut.date).format('dd, D.M.YY')}
        {checkOut.type === CheckOutTypes.LATE && (
          <FontAwesomeIcon
            color={theme.palette.text.lighter}
            icon="moon"
            size="xs"
            style={{ marginLeft: theme.spacing.gutterSmall }}
          />
        )}
      </Dates>
    </FlexColumn>
  )

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ContentWrapper flex={1} justifyContent="space-between">
        <FlexColumn noPadding>
          {renderContent()}
          <NoteWrapper>
            <DataField
              handleSubmit={(value) => handleSetNote(value)}
              style={{ maxWidth: `${theme.spacing.gu(35)}rem` }}
              inputType="textarea"
              placeholder={<T>RoomLayout:sidebar.addNote</T>}
              value={info}
              variant="primary"
            />
          </NoteWrapper>
        </FlexColumn>
      </ContentWrapper>

      <FlexColumn
        alignItems="flex-end"
        justifyContent={features.length ? 'space-between' : 'flex-end'}
        style={{ padding: `${theme.spacing.gu(1)}rem` }}
      >
        <RoomFeatures features={features} />
        {isHover && (
          <TrashIconWrapper onClick={handleDeleteRoomReservation}>
            <FontAwesomeIcon fixedWidth icon="trash" size="xs" />
          </TrashIconWrapper>
        )}
      </FlexColumn>

      <VerticalDivider />

      <FlexColumn
        justifyContent="center"
        style={{ padding: `${theme.spacing.gu(1)}rem` }}
      >
        <TotalNumber justifyContent="space-between" alignItems="center">
          <FontAwesomeIcon icon="bed" size="sm" />
          <FlexRow alignItems="center">
            <NumberInput
              onBlur={handleSetBedQuantity}
              onChange={(e) => setBedQuantity(Number(e.target.value))}
              onFocus={(e) => e.currentTarget.select()}
              min="0"
              type="number"
              value={`${bedQuantity}`}
            />
            / {room.beds}
          </FlexRow>
        </TotalNumber>
        <TotalNumber justifyContent="space-between" alignItems="center">
          <FontAwesomeIcon icon="couch" size="sm" />
          <FlexRow alignItems="center">
            <NumberInput
              onBlur={handleSetBedQuantity}
              onChange={(e) => setExtraBedQuantity(Number(e.target.value))}
              onFocus={(e) => e.currentTarget.select()}
              min="0"
              type="number"
              value={`${extraBedQuantity}`}
            />
            / {room.extraBeds}
          </FlexRow>
        </TotalNumber>
      </FlexColumn>

      <VerticalDivider />

      <FlexColumn
        justifyContent="center"
        style={{
          background: theme.palette.primary.extraLighter,
          padding: theme.spacing.gutterSmall,
        }}
      >
        <TotalNumberBold justifyContent="center" alignItems="center">
          {room.beds}
        </TotalNumberBold>
        <TotalNumberBold justifyContent="center" alignItems="center">
          {room.extraBeds}
        </TotalNumberBold>
      </FlexColumn>
    </Wrapper>
  )
}
