import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

type Props = {
  children: ReactNode
}

export const Placeholder = ({ children }: Props) => (
  <FlexRow alignItems="center" style={{ height: '100%' }}>
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </FlexRow>
)

const PlaceholderLabel = styled.div`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
