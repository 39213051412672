import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { DropdownButton } from '@/components/ExtraButtons'
import { Label, Toggle } from '@/components/FormControls'
import { InlineModalContent, InlineModalLine } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { CatalogProductPicker, ProductTitlePicker } from '@/modules/Products'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { IconButton } from '@/modules/Products/components/common'
import { CatalogProduct, ElasticProductTitle } from '@/modules/Products/types'

import { ProductItemSection, ProductSection } from './components'

type Props = {
  product: CatalogProduct
}

export const PricingSection = ({ product }: Props) => {
  const {
    addProductItemFromCatalog,
    addProductItemFromProductTitle,
    readOnly,
    sellerId,
  } = useCatalogProductManagerContext()

  const [isAddingFromCatalog, setAddingFromCatalog] = useState<boolean>(false)
  const [isAddingFromProductTitle, setAddingFromProductTitle] =
    useState<boolean>(false)
  const [showCosting, setShowCosting] = useState<boolean>(true)

  const handleAddProductItemFromCatalog = (catalogProductId: string) =>
    addProductItemFromCatalog(product.id, catalogProductId).then(() =>
      setAddingFromCatalog(false)
    )

  const handleAddProductItemFromProductTitle = (item: ElasticProductTitle) =>
    addProductItemFromProductTitle(product.id, item.id).then(() =>
      setAddingFromProductTitle(false)
    )

  const options = [
    {
      label: (
        <>
          <Icon icon="plus" size="sm" />
          <T>Products:ProductManager.action.addCatalogProduct</T>
        </>
      ),
      onClick: () => setAddingFromCatalog(true),
    },
    {
      label: (
        <>
          <Icon icon="plus" size="sm" />
          <T>Products:ProductManager.action.addProductTitle</T>
        </>
      ),
      onClick: () => setAddingFromProductTitle(true),
    },
  ]

  return (
    <>
      <FlexColumn flex={1}>
        <ProductSection product={product} />

        <InlineModalLine style={{ margin: 0 }} />

        <InlineModalContent style={{ padding: 0 }}>
          <TitleWrapper alignItems="center" justifyContent="space-between">
            <Title>
              <T>Products:ProductManager.pricing.productLines</T>
            </Title>

            <ToggleWrapper flex={1} justifyContent="flex-end">
              <ToggleLabel>
                <T>Products:ProductManager.action.showCosting</T>
              </ToggleLabel>

              <Toggle
                checked={showCosting}
                hideLabels
                noMargin
                onChange={() => setShowCosting(!showCosting)}
              />
            </ToggleWrapper>

            <DropdownButton
              options={options}
              renderCustomButton={({ onClick }) => (
                <Button disabled={readOnly} onClick={onClick}>
                  <PlusIcon icon="plus" size="sm" />
                  <T>Products:ProductManager.action.add</T>
                </Button>
              )}
              zIndex={10006}
            />
          </TitleWrapper>

          {product.items.map((i, idx) => (
            <ProductItemSection
              key={`product-item-${i.id}`}
              orderNumber={idx + 1}
              productItem={i}
              showCosting={showCosting}
            />
          ))}
        </InlineModalContent>
      </FlexColumn>

      {isAddingFromCatalog && (
        <CatalogProductPicker
          onItemSelect={handleAddProductItemFromCatalog}
          onClose={() => setAddingFromCatalog(false)}
          sellerId={sellerId}
        />
      )}

      {isAddingFromProductTitle && (
        <ProductTitlePicker
          onItemSelect={handleAddProductItemFromProductTitle}
          onClose={() => setAddingFromProductTitle(false)}
          sellerId={product.catalog.company.id}
        />
      )}
    </>
  )
}

/////////

const Button = styled(IconButton)`
  width: fit-content;
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    letter-spacing: ${theme.spacing.gu(0.05)}rem;
  `};
`

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 12px;
    margin-right: 10px;
  }
`

const PlusIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(0.75)}rem;
  `}
`

const ToggleLabel = styled(Label)`
  font-weight: 500;
  margin-bottom: 0;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const ToggleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(7)}rem;
    padding-left: ${theme.spacing.gu(4)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
  `}
`
