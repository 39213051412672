import { FlexRow } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { PaymentOtherDetails as OtherDetailsType } from '@/modules/Order/types'

import { Cell, Section, SectionWithTooltip } from '../../common'

type Props = {
  details: OtherDetailsType
}

export const OtherDetails = ({
  details: { author, invoiceReference, notes, salesReference },
}: Props) => (
  <>
    <FlexRow flex={4}>
      <FlexRow flex={3}>
        <SectionWithTooltip
          content={author || <FontColor lighter>–</FontColor>}
          flex={1}
          icon="user"
          title={<T>Orders:Payments.field.author</T>}
        />
      </FlexRow>
      <FlexRow flex={2}>
        <SectionWithTooltip
          content={invoiceReference || <FontColor lighter>–</FontColor>}
          flex={1}
          sign="#"
          title={<T>Orders:Payments.field.invoiceReference</T>}
        />
      </FlexRow>
      <FlexRow flex={2}>
        <SectionWithTooltip
          content={salesReference || <FontColor lighter>–</FontColor>}
          flex={1}
          sign="#"
          title={<T>Orders:Payments.field.salesReference</T>}
        />
      </FlexRow>
    </FlexRow>

    <FlexRow flex={3}>
      <Cell flex={1}>
        <Section icon={['far', 'note-sticky']}>
          {notes || <FontColor lighter>–</FontColor>}
        </Section>
      </Cell>
    </FlexRow>
  </>
)
