import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Tooltip } from '@/components/Tooltip'
import { useGlobalIconsContext, useTheme } from '@/theme'

import { RoomFeature } from '../types'

type Props = {
  feature: RoomFeature
}

export const Feature = ({ feature: { icon, id, name, shortName } }: Props) => {
  const { checkIconValidity } = useGlobalIconsContext()

  const { palette, spacing } = useTheme()

  return (
    <Tooltip
      content={name}
      key={id}
      trigger={(triggerProps) => (
        <span {...triggerProps} style={{ marginRight: spacing.gutterSmall }}>
          {checkIconValidity(icon) ? (
            <FontAwesomeIcon
              color={palette.text.light}
              fixedWidth
              icon={icon as IconProp}
            />
          ) : (
            <span style={{ fontWeight: 500 }}>{shortName}</span>
          )}
        </span>
      )}
    />
  )
}
