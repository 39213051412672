import { useEffect, useMemo, useState } from 'react'
import { useSubscription } from '@apollo/client'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ContentSection } from '@/modules/Sales/components/SalesDetails/components/ContentSection'
import { salesHooks } from '@/modules/Sales/hooks'
import { salesSubscriptions } from '@/modules/Sales/subscriptions'
import { useTheme } from '@/theme'

import { useSalesDimensions } from '../../hooks'
import {
  Dimension,
  DimensionModeSwitcher,
  DimensionPreview,
  DimensionShortcut,
} from './components'

export const Dimensions = () => {
  const { palette } = useTheme()

  const {
    data: { id: salesId },
    saleReadOnly,
  } = salesHooks.useSalesDetailsContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const {
    dimensions: dimensionsArr,
    error,
    loading,
  } = useSalesDimensions({ salesId })

  useSubscription(salesSubscriptions.SALES, {
    variables: { filter: { salesId } },
  })

  useEffect(() => {
    saleReadOnly && setEditMode(false)
  }, [saleReadOnly])

  const dimensions = useMemo(
    () =>
      [...dimensionsArr].sort((a, b) => {
        const aKey = a.dimension.sortOrder
        const bKey = b.dimension.sortOrder

        if (aKey < bKey) {
          return -1
        }

        if (aKey > bKey) {
          return 1
        }

        return 0
      }),
    [dimensionsArr]
  )

  const isEditModeByDefault = useMemo(
    () =>
      !!dimensions.length &&
      (dimensions.some((d) => d.dimension.required && !d.selectedLabel) ||
        dimensions.every((d) => !d.selectedLabel)),
    [dimensions]
  )

  useEffect(() => {
    isEditModeByDefault && !saleReadOnly && setEditMode(true)
  }, [isEditModeByDefault, saleReadOnly])

  const readOnly = saleReadOnly || loading

  const dimensionsForPreview = useMemo(
    () => dimensions.filter((d) => !!d.selectedLabel || !!d.dimension.required),
    [dimensions]
  )

  const renderContent = () => {
    if (loading) {
      return (
        <LoaderWrapper>
          <ReactLoading
            color={palette.smoke.main}
            height={24}
            type="spin"
            width={24}
          />
        </LoaderWrapper>
      )
    }

    if (error) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.errorDimensions</T>
        </Placeholder>
      )
    }

    if (!dimensions.length) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.emptyDimensions</T>
        </Placeholder>
      )
    }

    return isEditMode
      ? dimensions.map((dimension) => (
          <Dimension
            dimension={dimension}
            dimensions={dimensions}
            key={dimension.dimension.id}
            salesId={salesId}
          />
        ))
      : dimensionsForPreview.map((dimension) => (
          <DimensionPreview
            dimension={dimension}
            key={dimension.dimension.id}
          />
        ))
  }

  return (
    <ContentSection
      title={<T>SalesDetails:dimensions.dimensions</T>}
      headerExtra={
        <FlexRow alignItems="center">
          <DimensionShortcut readOnly={readOnly} salesId={salesId} />

          <DimensionModeSwitcher
            isEditMode={isEditMode}
            readOnly={readOnly}
            setEditMode={setEditMode}
          />
        </FlexRow>
      }
      flex={3}
    >
      <Wrapper wrap="wrap">{renderContent()}</Wrapper>
    </ContentSection>
  )
}

//////

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(2)}rem;
  `}
`

const Placeholder = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin: ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
