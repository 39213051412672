import { ELASTIC_URL } from '@/config/env'

import { apiCall } from '../apiCall'
import { Response } from '../apiCall/types'
import { ElasticAggregations, ElasticSearchResult } from './types'

export const elasticApiAggregations = <T>(
  index: string,
  queryObject: {
    [key: string]: any
  }
): Promise<Response<ElasticAggregations | null | undefined>> =>
  apiCall<ElasticSearchResult<T>>(`${ELASTIC_URL}/${index}/_search/`, {
    body: JSON.stringify(queryObject),
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(({ data, ok, ...rest }) => ({
    ...rest,
    data: ok ? data?.aggregations || {} : null,
    ok,
  }))
