import { FetchedParticipant } from '@/modules/ParticipantsList/types'

const removeUnimportantFields = (obj: any): any => {
  if (typeof obj === 'object' && obj?.length >= 0) {
    return obj.map((item: any) => removeUnimportantFields(item))
  } else if (
    typeof obj === 'object' &&
    obj !== null &&
    !obj.hasOwnProperty('length')
  ) {
    const {
      id,
      fixedCheckIn,
      fixedCheckOut,
      participant,
      __typename,
      ...objWithNoId
    } = obj
    const newObj = {}
    for (const key in objWithNoId) {
      // @ts-ignore
      newObj[key] = removeUnimportantFields(objWithNoId[key])
    }
    return newObj
  } else {
    return obj
  }
}

const getStringifiedOptions = (
  participants: FetchedParticipant[],
  option: string
) =>
  participants.map((participant) => {
    //@ts-ignore
    const result = removeUnimportantFields(participant[option])

    return typeof result === 'object' && result?.length >= 0
      ? result.map((item: any) => JSON.stringify(item))
      : JSON.stringify(result)
  })

export const isSameOption = (
  participants: FetchedParticipant[],
  option: string
) => {
  const options = getStringifiedOptions(participants, option)

  return options.every((option) => option.includes(options[0]))
}
