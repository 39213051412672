import { ColDef, ColGroupDef } from 'ag-grid-community'

import { AgGridSearchList } from '@/components/AgGrid'
import { naturalSortComparator } from '@/components/AgGrid/utils'
import { translate, useLanguageContext } from '@/modules/Language'

import { roomTypeHooks } from '../hooks'

export const RoomTypeList = () => {
  const { language } = useLanguageContext()

  const { error, loading, roomTypes } = roomTypeHooks.useRoomTypes()

  const columns: (ColDef | ColGroupDef)[] = [
    {
      comparator: naturalSortComparator,
      field: 'id',
      flex: 1,
      headerName: translate('RoomType:field.id', language),
    },
    {
      field: 'accommodationLevel',
      flex: 1,
      headerName: translate('RoomType:field.accommodationLevel', language),
      valueGetter: (params) => params.data.accommodationLevel.name,
    },
    {
      field: 'name',
      flex: 1,
      headerName: translate('RoomType:field.name', language),
      sort: 'asc',
    },
    {
      field: 'rooms',
      flex: 1,
      headerName: translate('RoomType:field.rooms', language),
      valueGetter: (params) => params.data.rooms.length,
    },
  ]

  return (
    <AgGridSearchList
      columnDefs={columns}
      error={!!error}
      loading={loading}
      rowData={roomTypes}
      title={translate('RoomType:title', language)}
    />
  )
}
