import { API_URL } from '@/config/env'

type AttachmnetsServiceType = {
  download: (
    fileName: string,
    folder: string,
    ownerId: string,
    ownerType: string
  ) => Promise<any>
}

const AttachmentsService: AttachmnetsServiceType = Object.freeze({
  download: (
    fileName: string,
    folder: string,
    ownerId: string,
    ownerType: string
  ) =>
    fetch(
      `${API_URL}/${
        ownerType === 'DOCUMENT' ? 'documents' : 'sales'
      }/${ownerId}/files/${folder}/${fileName}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to download file [
            ownerId=${ownerId},
            ownerType=${ownerType},
            fileName=${fileName}
          ]`)
        }

        return res.blob()
      })
      .then((res) => URL.createObjectURL(res)),
})

export default AttachmentsService
