import ACCOMMODATION_GROUP_TARGETS from './accommodationGroupTargets'
import ACCOMMODATION_TARGETS from './accommodationTargets'
import LANGUAGES_REGISTRY from './languagesRegistry'
import NATIONALITIES_REGISTRY from './nationalitiesRegistry'
import PARTICIPANT_COUNTS from './participantCounts'
import PARTICIPANT_DEFAULT_VST_DATES from './participantDefaultVstDates'
import ROOM_SUITABLE_PARTICIPANTS from './roomSuitableParticipants'
import SALES_AND_ENROLLMENTS_BASE from './salesAndEnrollmentsBase'
import SALES_GROUPS from './salesGroups'
import SERVICE_SUITABLE_TARGETS from './serviceSuitableTargets'
import SPECIAL_DIETARIES from './specialDietaries'
import SPECIAL_DIETS from './specialDiets'
import SUITABLE_ROOMS from './suitableRooms'
import SUITABLE_ROOMS_BASE from './suitableRoomsBase'
import TARGET_BY_ID from './targetById'

export const participantQueries = Object.freeze({
  ACCOMMODATION_GROUP_TARGETS,
  ACCOMMODATION_TARGETS,
  LANGUAGES_REGISTRY,
  NATIONALITIES_REGISTRY,
  PARTICIPANT_COUNTS,
  PARTICIPANT_DEFAULT_VST_DATES,
  ROOM_SUITABLE_PARTICIPANTS,
  SALES_AND_ENROLLMENTS_BASE,
  SALES_GROUPS,
  SERVICE_SUITABLE_TARGETS,
  SPECIAL_DIETARIES,
  SPECIAL_DIETS,
  SUITABLE_ROOMS,
  SUITABLE_ROOMS_BASE,
  TARGET_BY_ID,
})
