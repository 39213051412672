import { useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { ThemedMultiSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { SalesDetails } from '@/modules/Sales/components/SalesDetails/types'

import { GuestSource } from '~generated-types'

import { HelperText } from '../../common'
import { useGuestCommunicationContext } from '../GuestCommunicationState'

type Props = {
  salesId: string
  saleCommunicationSettings: SalesDetails['communicationSettings']
  saleReadOnly: boolean
}

export const SourceSelect = ({
  saleCommunicationSettings,
  salesId,
  saleReadOnly,
}: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { setGuestCommunication } = useGuestCommunicationContext()

  const selectedOptions =
    saleCommunicationSettings?.source === GuestSource.CustomerAndParticipant
      ? [GuestSource.Customer, GuestSource.Participant]
      : saleCommunicationSettings?.source
        ? [saleCommunicationSettings.source]
        : []

  const saleHasTemplate = saleCommunicationSettings?.template?.id

  const sourceOptions = Object.values(GuestSource)
    .filter((source) => source !== GuestSource.CustomerAndParticipant)
    .map((source: GuestSource) => ({
      isDisabled: !saleHasTemplate || saleReadOnly,
      label: translate(`GuestCommunication:source.options.${source}`, language),
      value: source,
    }))

  const handleSetGuestCommunicationSource = (values: string[]) => {
    const selectedValues =
      values.includes(GuestSource.Customer) &&
      values.includes(GuestSource.Participant)
        ? GuestSource.CustomerAndParticipant
        : values.length === 1
          ? (values[0] as GuestSource)
          : null

    if (
      (!selectedValues && !saleCommunicationSettings?.source) ||
      selectedValues === saleCommunicationSettings?.source
    )
      return

    setIsLoading(true)
    setGuestCommunication({
      salesId,
      source: selectedValues,
    }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <ThemedMultiSelect
        isCompact
        loading={isLoading}
        placeholder={<T>GuestCommunication:source.placeholder</T>}
        labelsAmount={2}
        allSelectedPlaceholder={
          <Label>{sourceOptions.map((item) => item.label).join(', ')}</Label>
        }
        options={sourceOptions}
        selectedValues={selectedOptions}
        setSelectedValues={(val) => handleSetGuestCommunicationSource(val)}
        modalWidth={`${spacing.gu(53)}rem`}
      />

      <HelperText>
        {saleHasTemplate ? (
          <T>GuestCommunication:source.hint</T>
        ) : (
          <T>GuestCommunication:source.noTemplate</T>
        )}
      </HelperText>
    </>
  )
}

/////////

const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
  `}
`
