import { Switch, useRouteMatch } from 'react-router-dom'

import { NotFoundPage, Page, PageRoute } from '@/components/Page'

import { NavInternalViewType } from '~generated-types'

import { SalesDetails } from './components/SalesDetails'

export const SalesRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PageRoute
        // TODO: Add separate access role for one sale
        accessKey={NavInternalViewType.SearchSales}
        component={WrappedSalesDetails}
        path={`${path}/details/:salesId`}
      />

      <PageRoute component={NotFoundPage} pageTitle="404" />
    </Switch>
  )
}

////////////

const WrappedSalesDetails = () => {
  const {
    params: { salesId },
    url,
  } = useRouteMatch<{ salesId: string | undefined }>()

  if (!salesId) {
    return (
      <Page pageTitle="404">
        <NotFoundPage />
      </Page>
    )
  }

  return <SalesDetails baseRoute={url} id={salesId} />
}
