import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { useSetRoomReservationCheck } from '@/modules/FrontDesk/components/DailyRoomReservations/mutations'
import {
  CheckType,
  Reservation,
} from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { CheckInOutButton } from './CheckInOutButton'

type Props = {
  action: CheckType
  reservation: Reservation | null
}

export const CheckInOut = ({ action, reservation }: Props) => {
  const { palette } = useTheme()

  const [processing, setProcessing] = useState<boolean>(false)

  const [setCheck] = useSetRoomReservationCheck()

  if (!reservation) {
    return (
      <FlexRow alignItems="center" justifyContent="center" flex={1}>
        {null}
      </FlexRow>
    )
  }

  const {
    checkIn,
    checkOut,
    estimatedCheckIn,
    estimatedCheckOut,
    id,
    participantRooms,
    sales: { locked: isSalesLocked },
  } = reservation

  const isCheckLocked = action === CheckType.CheckOut && !checkIn
  const isUndoCheckLocked = action === CheckType.CheckIn && !!checkOut

  const checkDate = action === CheckType.CheckIn ? checkIn : checkOut
  const estimatedCheckDate =
    action === CheckType.CheckIn ? estimatedCheckIn : estimatedCheckOut

  const handleSetCheck = (checkDate?: null) => {
    setProcessing(true)

    return setCheck({ variables: { input: { action, checkDate, ids: [id] } } })
      .catch(() => undefined)
      .finally(() => setProcessing(false))
  }

  const getCheckStatusColor = () => {
    if (action === CheckType.CheckIn) {
      return participantRooms.length ===
        participantRooms.filter(({ checkIn }) => checkIn).length
        ? palette.success.main
        : palette.warning.dark
    }

    return palette.success.main
  }

  const renderUndoCheckButton = () => (
    <CheckInOutButton
      background={palette.smoke.dark}
      disabled={isUndoCheckLocked || isSalesLocked}
      onSetCheck={() => handleSetCheck(null)}
      processing={processing}
      renderAction={(triggerProps) => (
        <Action flex={1} noPadding {...triggerProps}>
          <span>
            <T>FrontDesk:DailyRoomReservations.undo</T>
          </span>
          <span>
            <T>{`FrontDesk:DailyRoomReservations.action.${action}`}</T>
          </span>
        </Action>
      )}
      renderLabel={() => (
        <FontAwesomeIcon
          color={getCheckStatusColor()}
          icon={['far', 'circle-check']}
          size="lg"
        />
      )}
      warningKey={getUndoCheckWarningKey(isUndoCheckLocked, isSalesLocked)}
    />
  )

  const renderCheckButton = (date: string) => (
    <CheckInOutButton
      background={palette.primary.lighter}
      disabled={isCheckLocked || isSalesLocked}
      onSetCheck={handleSetCheck}
      processing={processing}
      renderAction={(triggerProps) => (
        <Action flex={1} noPadding {...triggerProps}>
          <T>{`FrontDesk:DailyRoomReservations.action.${action}`}</T>
        </Action>
      )}
      renderLabel={() => (
        <FlexColumn noPadding>
          <span>{moment(date).format('HH:mm')}</span>
          <LightLabel>
            <T>FrontDesk:DailyRoomReservations.field.estimated</T>
          </LightLabel>
        </FlexColumn>
      )}
      warningKey={getCheckWarningKey(isCheckLocked, isSalesLocked)}
    />
  )

  const renderNoParticipantsWarning = () => (
    <Tooltip
      content={<T>FrontDesk:DailyRoomReservations.warning.noParticipants</T>}
      maxWidth={200}
      trigger={(triggerProps) => (
        <span {...triggerProps}>
          <FontAwesomeIcon
            color={palette.warning.dark}
            icon="triangle-exclamation"
          />
        </span>
      )}
    />
  )

  return (
    <Wrapper alignItems="center" justifyContent="center">
      {checkDate
        ? renderUndoCheckButton()
        : !!participantRooms.length
          ? renderCheckButton(estimatedCheckDate)
          : renderNoParticipantsWarning()}
    </Wrapper>
  )
}

////////////

const getCheckWarningKey = (isCheckLocked: boolean, isSalesLocked: boolean) =>
  isSalesLocked ? 'salesLocked' : isCheckLocked ? 'checkOutValidation' : null

const getUndoCheckWarningKey = (
  isUndoCheckLocked: boolean,
  isSalesLocked: boolean
) =>
  isSalesLocked
    ? 'salesLocked'
    : isUndoCheckLocked
      ? 'checkInUndoValidation'
      : null

const Action = styled(FlexColumn)`
  height: 100%;
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `};
`

const LightLabel = styled.span`
  font-weight: 400;
  margin-top: 2px;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `};
`

const Wrapper = styled(FlexRow)`
  height: 100%;
  width: 100px;
`
