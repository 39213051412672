import { Fragment } from 'react'

import {
  AvailabilityByDate,
  CellStatus,
  EmphasisByDate,
  Mode,
} from '../../AvailabilityCalendar.types'
import { Cell, ScrollTableProps } from './ScrollTable'

type Props = ScrollTableProps & {
  emphasis?: EmphasisByDate | null | undefined
  property: 'beds' | 'extraBeds' | 'rooms'
  status: AvailabilityByDate[]
  mode: Mode
}

export const TotalsStatusRow = ({
  emphasis,
  property,
  status,
  mode,
  ...tableProps
}: Props) => {
  const { columnGroups } = tableProps

  return (
    <>
      {columnGroups.map(({ items, key: weekKey }, weekIdx) => (
        <Fragment key={`data-${weekKey}`}>
          {items.map(({ key: dateKey }, dateIdx) => {
            const capacity = status.reduce(
              (amount, availability) =>
                amount +
                (availability?.[dateKey]?.totalCapacity?.[property] || 0),
              0
            )
            const value = status.reduce(
              (amount, availability) =>
                amount + (availability?.[dateKey]?.[mode]?.[property] || 0),
              0
            )
            const valueStr = `${value ?? '–'}`
            const cellStatus = getCellStatus(capacity, value)
            const isLastCell =
              weekIdx === columnGroups.length - 1 &&
              dateIdx === items.length - 1

            return (
              <Cell
                emphasis={emphasis?.[dateKey]}
                depth={0}
                isBold={property === 'rooms'}
                isLast={isLastCell}
                key={`data-${dateKey}-${property}`}
                status={cellStatus}
                {...tableProps}
              >
                {valueStr}
              </Cell>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

////////////

const getCellStatus = (capacity: number, value: number): CellStatus => {
  if (value / capacity <= 0) {
    return CellStatus.OCCUPIED
  }

  if (value / capacity >= 1) {
    return CellStatus.VACANT
  }

  if (capacity === 0) {
    return CellStatus.UNAVAILABLE
  }

  return CellStatus.PARTIAL
}
