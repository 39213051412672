import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableSelect } from '@/components/DataTable'
import { FlexColumn } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'

import { CommissionMode } from '~generated-types'

import { Title } from './common'

type Props = {
  commissionMode: CommissionMode
  id: string
}

export const CommissionModeSection = ({ commissionMode, id }: Props) => {
  const { language } = useLanguageContext()
  const { readOnly, updateProductItem } = useCatalogProductManagerContext()

  return (
    <FlexColumn>
      <Title>
        <T>Products:ProductManager.pricing.commissionMode</T>
      </Title>

      <Select
        disabled={readOnly}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          updateProductItem({
            commissionMode: e.target.value as CommissionMode,
            id,
          })
        }
        showBorder
        value={commissionMode}
      >
        {Object.values(CommissionMode).map((m) => (
          <option key={m} value={m}>
            {translate(`enums:commissionMode.${m}`, language)}
          </option>
        ))}
      </Select>
    </FlexColumn>
  )
}

/////////

const Select = styled(DataTableSelect)`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(0.5)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
        opacity: 1;
      `}
    }
  }
`
