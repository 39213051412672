import accommodationGroup from './accommodationGroup'
import accommodationIssue from './accommodationIssue'
import accommodationSettings from './accommodationSettings'
import accommodationTarget from './accommodationTarget'
import capacityStatus from './capacityStatus'
import reservationStatus from './reservationStatus'
import roomReservation from './roomReservation'
import roomTypeReservation from './roomTypeReservation'

export const accommodationFragments = Object.freeze({
  accommodationGroup,
  accommodationIssue,
  accommodationSettings,
  accommodationTarget,
  capacityStatus,
  reservationStatus,
  roomReservation,
  roomTypeReservation,
})
