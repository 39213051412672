import { gql } from '@apollo/client'

import accommodationSettings from './accommodationSettings'
import accommodationTarget from './accommodationTarget'
import roomReservation from './roomReservation'
import roomTypeReservation from './roomTypeReservation'

export default gql`
  ${accommodationSettings}
  ${accommodationTarget}
  ${roomReservation}
  ${roomTypeReservation}

  fragment AccommodationGroup on AccommodationGroup {
    id
    name
    sortOrder
    sales {
      id
      type
    }
    ...AccommodationSettings
    targets {
      ...AccommodationTarget
    }
    roomReservations {
      ...RoomReservation
    }
    roomTypeReservations {
      ...RoomTypeReservation
    }
  }
`
