import { ParticipantsListContainer } from './ParticipantsListContainer'
import { ParticipantsListContextProvider } from './ParticipantsListState'

type Props = {
  ownerId: string
  readOnly?: boolean
}

export const ParticipantsList = ({ ownerId, readOnly }: Props) => (
  <ParticipantsListContextProvider salesId={ownerId}>
    <ParticipantsListContainer readOnly={readOnly} />
  </ParticipantsListContextProvider>
)
