import { Ref, useState } from 'react'
import moment from 'moment'

import { EditButton } from '@/components/ExtraButtons'
import { InlineModalIconSection } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { Participant } from '@/modules/FrontDesk/components/DailyRoomReservations/types'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ParticipantAgeModal } from '@/modules/ParticipantsList/components/ParticipantRow'
import { AgeReference } from '@/modules/ParticipantsList/types'
import { useTheme } from '@/theme'

import { SalesParticipantUpdateInput as UpdateInput } from '~generated-types'

import { Placeholder } from '../../common'

type Props = {
  participant: Participant
  updateParticipant: (input: UpdateInput) => Promise<any>
}

export const Age = ({
  participant: {
    age,
    ageCategory,
    ageSource,
    birthday,
    id,
    sales: { locked },
  },
  updateParticipant,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const handleSubmit = ({ age, ageCategoryKey, birthday }: AgeReference) =>
    updateParticipant({ age, ageCategoryKey, birthday, id })

  const renderLabel = () => {
    if (birthday) {
      return moment()
        .year(birthday.year)
        .month((birthday.month ?? 1) - 1)
        .date(birthday.date ?? 1)
        .format('DD.MM.YYYY')
    }

    if (age) {
      return `${age} ${translate('common:time.years', language)}`
    }

    if (ageCategory) {
      return ageCategory.name
    }

    return (
      <Placeholder>
        <T>FrontDesk:DailyRoomReservations.participants.setAge</T>
      </Placeholder>
    )
  }

  return (
    <InlineModalIconSection icon={['far', 'calendar']}>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <ParticipantAgeModal
            age={age}
            ageCategory={ageCategory}
            ageSource={ageSource}
            birthday={birthday}
            handleSubmit={handleSubmit}
          />
        }
        onClose={() => setModalOpen(false)}
        placement="bottom-start"
        referenceElement={({ ref }) => (
          <EditButton
            disabled={locked}
            onClick={() => setModalOpen(true)}
            innerRef={ref as Ref<HTMLButtonElement> | undefined}
            style={{
              color: palette.text.light,
              width: `calc(100% + ${spacing.gu(1)}rem)`,
            }}
          >
            {renderLabel()}
          </EditButton>
        )}
        zIndex={10003}
      />
    </InlineModalIconSection>
  )
}
