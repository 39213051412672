import { gql } from '@apollo/client'

export default gql`
  query NationalitiesRegistry {
    registry {
      countries {
        id
        name
        nationality
        frequentlyUsed
      }
    }
  }
`
