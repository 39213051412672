import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

import type { SalesFacetsQuery } from '~generated-types'

type Facet = SalesFacetsQuery['registry']['salesFacets'][0]

type ColorSchema = {
  background: string
  text: string
}

type Props = {
  facet: Facet
  colorSchema: ColorSchema
  busy: boolean
  onSelect: () => void
}

export const NewSaleModalFacet = ({
  facet,
  colorSchema,
  busy,
  onSelect,
}: Props) => (
  <Wrapper disabled={busy} onClick={busy ? undefined : onSelect}>
    {facet.name}

    <Abbreviation colorSchema={colorSchema}>
      {busy ? (
        <FlexColumn alignItems="center" flex={1} noPadding>
          <ReactLoading
            color={colorSchema.text}
            height={16}
            type="spin"
            width={16}
          />
        </FlexColumn>
      ) : (
        facet.abbreviation
      )}
    </Abbreviation>
  </Wrapper>
)

////////////

const Abbreviation = styled.span<{ colorSchema: ColorSchema }>`
  font-weight: 600;
  text-align: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall};
    width: ${theme.spacing.gu(7)}rem;
    font-size: ${theme.typography.fontSizeBase};
  `}

  ${({ colorSchema }) => css`
    background-color: ${colorSchema.background};
    color: ${colorSchema.text};
  `}
`

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-weight: 500;

  ${({ disabled, theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBase2};
    background-color: ${disabled
      ? theme.palette.smoke.main
      : theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
