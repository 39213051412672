import { gql } from '@apollo/client'

export default gql`
  query SalesGroups($id: ID!) {
    sales(id: $id) {
      id
      groups {
        label
        values
      }
    }
  }
`
