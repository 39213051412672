import { T } from '@/modules/Language'
import { ActionButton } from '@/modules/Products/components'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { Catalog } from '@/modules/Registry/Catalog'
import { useTheme } from '@/theme'

type Props = {
  catalog: Catalog
  productId: string
}

export const Actions = ({ catalog, productId }: Props) => {
  const { palette } = useTheme()

  const { copyProduct, readOnly } = useCatalogProductManagerContext()

  return (
    <ActionButton
      disabled={readOnly}
      content={<T>Products:ProductManager.action.copyProduct</T>}
      icon="copy"
      iconColor={palette.text.light}
      onClick={() => copyProduct(productId, catalog.id)}
    />
  )
}
